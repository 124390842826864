import { RootState } from 'app/store'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { passwordValidation } from 'utils/validations'
import { signupActions } from '../../signupSlice'
import { ControlButtons } from './ControlButtons'

export const PersonalEmailForm: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { registration } = useSelector((state: RootState) => state.signup)
  const { register, handleSubmit, errors } = useForm({
    defaultValues: registration,
  })

  const onSubmit = (data: any) => {
    dispatch(signupActions.nextStep({ ...registration, ...data }))
  }

  return (
    <>
      <div className="mb-8">
        {t('signup.personal.desc')}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          name="email"
          type="email"
          required
          maxLength={255}
          placeholder={t('signup.personal.email.placeholder')}
          ref={register()}
        />

        <input
          name="password"
          type="password"
          required
          minLength={8}
          placeholder={t('signup.personal.password.placeholder')}
          ref={register({
            validate: {
              password: passwordValidation
            }
          })}
        />

        <div className="hint">
          {t('common.hint.password')}
        </div>

        { errors.password?.type === 'password' && (
          <p className="ml-4 pt-0 text-sm text-red">{t('validate.errors.password')}</p>
        )}

        <input
          name="pinCode"
          type="password"
          required
          pattern="^\d+$"
          minLength={6}
          maxLength={6}
          placeholder={t('signup.personal.pinCode.placeholder')}
          ref={register()}
        />

        <div className="hint">
          {t('common.hint.pinCode')}
        </div>

        <ControlButtons />
      </form>
    </>
  )
}
