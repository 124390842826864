import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from 'api/api'
import { CheckoutDto, PaymentStatus } from 'api/generated/models'
import { AppThunk } from 'app/store'
import { FeatureState } from 'models/FeatureState'

interface State {
  featureState: FeatureState
  paymentState?: PaymentStatus
  checkout?: CheckoutDto,
  error?: any
}

const initialState: State = {
  featureState: FeatureState.Init
}

const slice = createSlice({
  name: 'checkout',
  initialState: initialState,
  reducers: {
    setFeatureState(state: State, action: PayloadAction<FeatureState>) {
      state.featureState = action.payload
    },
    startPaymentSuccess(state: State, action: PayloadAction<CheckoutDto>) {
      state.checkout = action.payload
      state.featureState = FeatureState.Success
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    getPaymentResultSuccess(state, action: PayloadAction<any>) {
      state.paymentState = action.payload
    },
    clear(state) {
      state.featureState = FeatureState.Init
      state.checkout = undefined
      state.error = undefined
      state.paymentState = undefined
    }
  },
})

const {
  setFeatureState,
  setError,
  startPaymentSuccess,
  getPaymentResultSuccess,
  clear
} = slice.actions

const startPayment = (referenceCode: string): AppThunk => async (dispatch: Function) => {
  dispatch(setFeatureState(FeatureState.Loading))

  try {
    const result = await api.payment.paymentStartPayment(referenceCode)
    dispatch(startPaymentSuccess(result.data))

  } catch (error) {
    dispatch(setError(error))
    dispatch(setFeatureState(FeatureState.Error))
  }
}

const getPaymentResult = (checkoutId: string): AppThunk => async (dispatch: Function) => {
  dispatch(setFeatureState(FeatureState.Loading))

  try {
    const result = await api.payment.paymentGetResult(checkoutId)
    dispatch(getPaymentResultSuccess(result.data?.status))

    return result.data
  } catch (error) {
    dispatch(setFeatureState(FeatureState.Error))
  }
}

export const paymentActions = {
  startPayment,
  getPaymentResult,
  clear
}

export const paymentReducer = slice.reducer
