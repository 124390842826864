import React, { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { createBrowserHistory } from "history";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { PublicRoute } from "./PublicRoute";
import ScrollToTop from "components/ScrollToTop";
import { PrivateRoute } from "./PrivateRoute";
import { Role } from "api/generated/models";
import { RootState } from "app/store";
import { ReactComponent as PendingProcess } from "assets/img/pending_process.svg";
import ErrorBoundary from "components/ErrorBoundary";
import LandingPage from "features/landing/LandingPage";
import { SignupSuccess } from "features/signup/components/SignupSuccess";

const PricingPage = lazy(() => import("features/pricing/PricingPage"));
const ContactPage = lazy(() => import("features/contact/ContactPage"));
const FaqPage = lazy(() => import("features/faq/FaqPage"));
const LoginPage = lazy(() => import("features/auth/LoginPage"));
const SignupPage = lazy(() => import("features/signup/SignupPage"));
const ForgotPasswordPage = lazy(
  () => import("features/forgotPassword/ForgotPasswordPage")
);
const NewPasswordPage = lazy(
  () => import("features/forgotPassword/NewPasswordPage")
);
const ClientsPage = lazy(() => import("features/clients/ClientsPage"));
const MerchantPage = lazy(() => import("features/merchant/MerchantPage"));
const CheckoutPage = lazy(() => import("features/payment/CheckoutPage"));
const PaymentPage = lazy(() => import("features/payment/PaymentPage"));

const PrivacyPolicyPage = lazy(
  () => import("features/privacyPolicy/PrivacyPolicyPage")
);

export const history = createBrowserHistory();

const Routes = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { location } = useSelector((state: RootState) => state.router);

  // force redirect user to merchant routes
  useEffect(() => {
    if (
      user?.role === Role.Basic &&
      !location.pathname.startsWith("/merchant")
    ) {
      dispatch(push(`/merchant/${user.merchantId}/statistics`));
    }
  }, [dispatch, location, user]);

  return (
    <>
      <ScrollToTop />

      <ErrorBoundary>
        <Suspense
          fallback={
            <div className="fixed inset-0 z-10 flex items-center justify-center bg-white bg-opacity-90">
              <PendingProcess className="animate-spin-slow w-12 h-12 mx-auto" />
            </div>
          }
        >
          <Switch>
            <PublicRoute exact path="/" component={LandingPage} />

            <PublicRoute exact path="/pricing" component={PricingPage} />

            <PublicRoute exact path="/contact" component={ContactPage} />

            <PublicRoute
              exact
              path="/signup"
              component={SignupPage}
              onlyForUnauthenticated
            />

            <PublicRoute
              exact
              path="/signup/success"
              component={SignupSuccess}
              onlyForUnauthenticated
            />

            <PublicRoute
              exact
              path="/checkout/:referenceCode/payment"
              component={PaymentPage}
            />

            <PublicRoute
              exact
              path="/checkout/:referenceCode"
              component={CheckoutPage}
            />

            <PublicRoute exact path="/faq" component={FaqPage} />

            <PublicRoute
              exact
              path="/privacyPolicy"
              component={PrivacyPolicyPage}
            />

            <PublicRoute
              exact
              path="/forgot-password"
              component={ForgotPasswordPage}
              onlyForUnauthenticated
            />

            <PublicRoute
              exact
              path="/new-password/:token"
              component={NewPasswordPage}
              onlyForUnauthenticated
            />

            <PublicRoute
              exact
              path="/login"
              component={LoginPage}
              onlyForUnauthenticated
            />

            <PrivateRoute
              path="/merchant/:merchantId/:tab?"
              component={MerchantPage}
              roles={[Role.Admin, Role.Basic]}
            />

            <PrivateRoute
              exact
              path="/clients"
              component={ClientsPage}
              roles={[Role.Admin]}
            />

            <Route path="*" render={() => <Redirect to="/"></Redirect>} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export const RouterView = withRouter(Routes);
