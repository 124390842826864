import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from 'api/api'
import { AppThunk } from 'app/store'
import { FeatureState } from 'models/FeatureState'
import { UserDetails } from 'models/UserDetails'

interface State {
  updatePasswordState: FeatureState
  updatePinState: FeatureState
  pictureUploadState: FeatureState
  pictureGetState: FeatureState
  error?: any
  profilePicture?: Blob | null
  userDetails?: UserDetails
}

const initialState: State = {
  updatePasswordState: FeatureState.Init,
  updatePinState: FeatureState.Init,
  pictureUploadState: FeatureState.Init,
  pictureGetState: FeatureState.Init,
}

const slice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setUpdatePasswordState(state, action: PayloadAction<FeatureState>) {
      state.updatePasswordState = action.payload
    },
    setUpdatePinState(state, action: PayloadAction<FeatureState>) {
      state.updatePinState = action.payload
    },
    setPictureUploadState(state, action: PayloadAction<FeatureState>) {
      state.updatePasswordState = action.payload
    },
    setPictureGetState(state, action: PayloadAction<FeatureState>) {
      state.pictureGetState = action.payload
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    pictureUploadSuccess(state, action: PayloadAction<Blob>) {
      state.profilePicture = action.payload
      state.pictureUploadState = FeatureState.Success
    },
    pictureGetSuccess(state, action: PayloadAction<Blob>) {
      state.profilePicture = action.payload
      state.pictureGetState = FeatureState.Success
    },
    pictureGetError(state) {
      state.profilePicture = null
      state.pictureGetState = FeatureState.Error
    }
  },
})

const {
  reset,
  setPictureUploadState,
  setError,
  setUpdatePasswordState,
  setUpdatePinState,
  pictureUploadSuccess,
  pictureGetSuccess,
  pictureGetError,
  setPictureGetState,
} = slice.actions

const updatePassword = (
  currentPassword: string,
  newPassword: string
): AppThunk => async (dispatch) => {
  dispatch(setUpdatePasswordState(FeatureState.Loading))

  try {
    await api.user.userUpdatePassword({ currentPassword, newPassword })

    dispatch(setUpdatePasswordState(FeatureState.Success))
  } catch (error) {
    dispatch(setError(error))
    dispatch(setUpdatePasswordState(FeatureState.Error))
  }
}

const updatePin = (pinCode: string): AppThunk => async (dispatch) => {
  dispatch(setUpdatePinState(FeatureState.Loading))

  try {
    await api.user.userModifyPin({ pinCode })

    dispatch(setUpdatePinState(FeatureState.Success))
  } catch (error) {
    dispatch(setError(error))
    dispatch(setUpdatePinState(FeatureState.Error))
  }
}

const pictureGet = (): AppThunk => async (dispatch, getState) => {
  if (getState().settings.pictureGetState === FeatureState.Loading) return

  dispatch(setPictureGetState(FeatureState.Loading))

  try {
    const result = await api.picture.pictureGet({ responseType: 'blob' })

    dispatch(pictureGetSuccess(result.data))
  } catch (error) {
    dispatch(pictureGetError())
  }
}

const pictureUpload = (file: Blob): AppThunk => async (dispatch) => {
  dispatch(setPictureUploadState(FeatureState.Loading))

  try {
    const result = await api.picture.pictureUpload(file, { responseType: 'blob' })
    dispatch(pictureUploadSuccess(result.data))
  } catch (error) {
    dispatch(setError(error))
    dispatch(setPictureUploadState(FeatureState.Error))
  }
}


export const settingsActions = {
  reset,
  updatePassword,
  updatePin,
  pictureGet,
  pictureUpload
}

export const settingsReducer = slice.reducer
