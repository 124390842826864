import { api } from 'api/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import { AddTerminalCommand, TerminalDto, UpdateUserCommand, UpdateTerminalCommand, UserDetailsDto } from 'api/generated/models'
import { FeatureState } from 'models/FeatureState'
import { Bank } from 'models/Bank'
import { Pagination } from 'models/Pagination'
import { authActions } from 'features/auth/authSlice'
import { ModalState } from 'components/modal/Modal'

interface State {
  error?: any
  userDetailsState: FeatureState
  userDetails?: UserDetailsDto
  updateUserDetailsState: FeatureState
  updateMarketingState: FeatureState
  userDeleteState: FeatureState
  banksState: FeatureState
  banks?: Bank[]
  terminalsState: FeatureState
  terminals?: TerminalDto[] | null
  terminalPager: Pagination
  terminalModalState: ModalState
  updateTerminalState: FeatureState
}

const initialState: State = {
  userDetailsState: FeatureState.Init,
  updateMarketingState: FeatureState.Init,
  updateUserDetailsState: FeatureState.Init,
  userDeleteState: FeatureState.Init,
  banksState: FeatureState.Init,
  terminalsState: FeatureState.Init,
  updateTerminalState: FeatureState.Init,
  terminalModalState: ModalState.Hidden,
  terminalPager: {
    pageNumber: 1,
    pageSize: 10,
  }
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setUserDetailsState(state, action: PayloadAction<FeatureState>) {
      state.userDetailsState = action.payload
    },
    getUserDetailsSuccess(state, action: PayloadAction<UserDetailsDto>) {
      state.userDetails = action.payload
      state.userDetailsState = FeatureState.Success
    },
    setMarketingState(state, action: PayloadAction<FeatureState>) {
      state.updateMarketingState = action.payload
    },
    setUpdateUserDetailsState(state, action: PayloadAction<FeatureState>) {
      state.updateUserDetailsState = action.payload
    },
    setUserDeleteState(state, action: PayloadAction<FeatureState>) {
      state.userDeleteState = action.payload
    },
    setBanksState(state, action: PayloadAction<FeatureState>) {
      state.banksState = action.payload
    },
    getBanksSuccess(state, action: PayloadAction<Bank[]>) {
      state.banks = action.payload
      state.banksState = FeatureState.Success
    },
    setTerminalsState(state, action: PayloadAction<FeatureState>) {
      state.terminalsState = action.payload
    },
    setTerminalPager(state, action: PayloadAction<Pagination>) {
      state.terminalPager = action.payload
    },
    getTerminalsSuccess(state, action: PayloadAction<{
      terminals?: TerminalDto[] | null,
      pagination: Pagination
    }>) {
      state.terminals = action.payload.terminals
      state.terminalPager = action.payload.pagination
      state.terminalsState = FeatureState.Success
    },
    setTerminalModalState(state, action: PayloadAction<ModalState>) {
      state.terminalModalState = action.payload
    },
    setUpdateTerminalState(state, action: PayloadAction<FeatureState>) {
      state.updateTerminalState = action.payload
    },
    clear(state) {
      state.error = undefined
      state.userDetailsState = FeatureState.Init
      state.userDetails = undefined
      state.updateUserDetailsState = FeatureState.Init
      state.userDeleteState = FeatureState.Init
      state.banksState = FeatureState.Init
      state.banks = undefined
      state.terminalsState = FeatureState.Init
      state.terminals = undefined
      state.terminalPager = { pageNumber: 1, pageSize: 10 }
      state.terminalModalState = ModalState.Hidden
      state.updateTerminalState = FeatureState.Init
    }
  }
})

const {
  setError,
  setUserDetailsState,
  getUserDetailsSuccess,
  setUpdateUserDetailsState,
  setUserDeleteState,
  setMarketingState,
  setBanksState,
  getBanksSuccess,
  setTerminalsState,
  setTerminalPager,
  getTerminalsSuccess,
  setTerminalModalState,
  setUpdateTerminalState,
  clear
} = slice.actions

const getUserDetails = (userId?: string): AppThunk => async (dispatch, getState) => {
  dispatch(setUserDetailsState(FeatureState.Loading))

  try {
    const { user } = getState().auth
    const { merchantDetails } = getState().merchant
    const id = userId || user?.userId || merchantDetails?.userId || ''
    const resp = await api.user.userGet(id)

    dispatch(getUserDetailsSuccess(resp.data))
  } catch (error) {
    dispatch(setUserDetailsState(FeatureState.Error))
  }
}

const updateUserDetails = (userUpdate: UpdateUserCommand): AppThunk => async dispatch => {
  dispatch(setUpdateUserDetailsState(FeatureState.Loading))
  try {
    await api.user.userUpdateUserDetails(userUpdate)
    dispatch(setUpdateUserDetailsState(FeatureState.Success))
  } catch (error) {
    dispatch(setUpdateUserDetailsState(FeatureState.Error))
    dispatch(setError(error))
  }
}

const updateMarketing = (isMarketingAllowed: boolean): AppThunk => async (dispatch) => {
  dispatch(setMarketingState(FeatureState.Loading))

  try {
    await api.user.userUpdatePreferences({ isMarketingAllowed })
    dispatch(setMarketingState(FeatureState.Success))
  } catch (error) {
    dispatch(setError(error))
    dispatch(setMarketingState(FeatureState.Error))
  }
}

const getBanks = (): AppThunk => async (dispatch) => {
  dispatch(setBanksState(FeatureState.Loading))
  try {
    const resp = await api.bank.bankGetAll()

    dispatch(getBanksSuccess(resp.data))
  } catch (error) {
    dispatch(setBanksState(FeatureState.Error))
  }
}

const getTerminals = (merchantId?: number): AppThunk => async (dispatch, getState) => {
  dispatch(setTerminalsState(FeatureState.Loading))

  try {
    const { merchantDetails } = getState().merchant
    const { pageNumber, pageSize } = getState().user.terminalPager
    const id = merchantId ?? merchantDetails?.id
    const result = await api.terminal.terminalGetTerminals(id, pageNumber, pageSize)
    const { data, ...pagination } = result.data

    dispatch(getTerminalsSuccess({ terminals: data, pagination }))
  } catch (error) {
    dispatch(setError(error))
    dispatch(setTerminalsState(FeatureState.Error))
  }
}


const addTerminal = (addTerminalCommand: AddTerminalCommand): AppThunk => async dispatch => {
  dispatch(setUpdateTerminalState(FeatureState.Loading))

  try {
    await api.terminal.terminalAddTerminal(addTerminalCommand)
    dispatch(setTerminalModalState(ModalState.Hidden))
    dispatch(setUpdateTerminalState(FeatureState.Success))
    dispatch(getTerminals())
  } catch (error) {
    dispatch(setError(error))
    dispatch(setUpdateTerminalState(FeatureState.Error))
  }
}

const updateTerminal = (id: number, params: UpdateTerminalCommand): AppThunk => async (dispatch) => {
  dispatch(setUpdateTerminalState(FeatureState.Loading))

  try {
    await api.terminal.terminalUpdateTerminal(id, params)
    dispatch(setTerminalModalState(ModalState.Hidden))
    dispatch(setUpdateTerminalState(FeatureState.Success))
    dispatch(getTerminals())
  } catch (error) {
    dispatch(setError(error))
    dispatch(setUpdateTerminalState(FeatureState.Error))
  }
}

const deleteUser = (): AppThunk => async (dispatch, getState) => {
  const userId = getState().auth.user?.userId
  if (!userId) return

  dispatch(setUserDeleteState(FeatureState.Loading))

  try {
    await api.user.userDeactivateUser()

    dispatch(authActions.logout())
  } catch (error) {
    dispatch(setUserDeleteState(FeatureState.Error))
  }
}


export const userActions = {
  setTerminalPager,
  setTerminalModalState,
  getUserDetails,
  updateUserDetails,
  updateMarketing,
  deleteUser,
  getBanks,
  getTerminals,
  addTerminal,
  updateTerminal,
  clear
}

export const userReducer = slice.reducer