import { RootState } from 'app/store'
import AddressSchemeDisplay from 'components/AddressSchemeDisplay'
import { ListDropdown } from 'components/form/ListDropdown'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { countries } from 'services/countryService'
import { signupActions } from '../../signupSlice'
import { ControlButtons } from './ControlButtons'

export const PersonalAddressForm: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { registration } = useSelector((state: RootState) => state.signup)
  const { register, handleSubmit } = useForm({
    defaultValues: registration,
  })

  const onSubmit = (data: any) => {
    dispatch(
      signupActions.nextStep({
        ...registration,
        ...data,
        person: { ...registration?.person, ...data.person },
      })
    )
  }

  return (
    <>
      <div className="mb-8">{t('Kérjük add meg nekünk a lakcímedet!')}</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ListDropdown
          name="person.address.countryCode"
          list={countries}
          placeholder={t('Ország')}
          register={register}
        />

        <AddressSchemeDisplay
          context="person.address"
          register={register}
        />

        <ControlButtons />
      </form>
    </>
  )
}
