import { RootState } from 'app/store'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { signupActions } from '../../signupSlice'
import { ControlButtons } from './ControlButtons'
import { CompanyPersonFields } from './CompanyPersonFields'
import { useTranslation } from 'react-i18next'

export const CompanyPersonsForm: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { registration } = useSelector((state: RootState) => state.signup)
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    errors
  } = useForm({
    defaultValues: registration,
  })
  const [showSecondPerson, setShowSecondPerson] = useState(
    registration?.persons?.length === 2
  )

  const onSubmit = (data: any) => {
    dispatch(
      signupActions.nextStep({
        ...registration,
        ...data,
      })
    )
  }

  return (
    <>
      <div className="mb-8">
        {t('A vállalkozás vezető tisztségviselőire vonatkozó kérdések:')}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <CompanyPersonFields
          number={0}
          registration={registration}
          register={register}
          control={control}
          setValue={setValue}
          watch={watch}
          getValues={getValues}
          errors={errors}
        />
        {showSecondPerson ? (
          <CompanyPersonFields
            number={1}
            registration={registration}
            register={register}
            control={control}
            setValue={setValue}
            watch={watch}
            getValues={getValues}
            errors={errors}
          />
        ) : (
          <div className="flex mt-8">
            <div className="w-1/3">
              <button
                onClick={() => setShowSecondPerson(true)}
                className="p-2 rounded-full border-black border-2"
              >
                <img src="/img/plus.svg" alt="Add" />
              </button>
            </div>
            <div>
              {t('Másik vezető tisztségviselő hozzáadása, amennyiben együttes aláírásra van szükség')}
            </div>
          </div>
        )}
        <ControlButtons />
      </form>
    </>
  )
}
