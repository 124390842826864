import {
  configureStore,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit'
import { history } from 'router'
import { LocationState } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { authReducer } from 'features/auth/authSlice'
import { localeReducer } from 'features/locale/localeSlice'
import { paymentReducer } from 'features/payment/paymentSlice'
import { clientsReducer } from 'features/clients/clientsSlice'
import { forgotPasswordReducer } from 'features/forgotPassword/forgotPasswordSlice'
import { menuReducer } from 'features/menu/menuSlice'
import { signupReducer } from 'features/signup/signupSlice'
import { merchantReducer } from 'features/merchant/merchantSlice'
import { userReducer } from 'features/merchant/userSlice'
import { settingsReducer } from 'features/merchant/components/settings/settingsSlice'
import { transactionsReducer } from 'features/merchant/components/transactions/transactionsSlice'
import { reportsReducer } from 'features/merchant/components/reports/reportsSlice'
import { statisticsReducer } from 'features/merchant/components/statistics/statisticsSlice'
import { onlineTransactionsReducer } from 'features/merchant/components/transactions/onlineTransactionsSlice'

const store = configureStore({
  reducer: {
    router: connectRouter<LocationState>(history),
    locale: localeReducer,
    auth: authReducer,
    menu: menuReducer,
    payment: paymentReducer,
    signup: signupReducer,
    forgotPassword: forgotPasswordReducer,
    settings: settingsReducer,
    clients: clientsReducer,
    merchant: merchantReducer,
    user: userReducer,
    transactions: transactionsReducer,
    onlineTransactions: onlineTransactionsReducer,
    statistics: statisticsReducer,
    reports: reportsReducer,
  },
  middleware: getDefaultMiddleware => {
    return [...getDefaultMiddleware({ serializableCheck: false }), routerMiddleware(history)]
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export default store
