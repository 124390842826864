import React, { FC } from 'react'

interface ErrorDisplayProps {
  error?: any
  show: boolean
}

export const ErrorDisplay: FC<ErrorDisplayProps> = ({ error, show }) => {
  return (
    <>
      {error && show && (
        <div className="my-4">
          {error.detail ? (
            <div className="mb-2 text-red">{error.detail}</div>
          ) : (
            Object.values(error.errors || {}).map((x: any) =>
              x.map((y: string) => (
                <div className="mb-2 text-red" key={y}>
                  {y}
                </div>
              ))
            )
          )}
        </div>
      )}
    </>
  )
}
