import React, { FC, useLayoutEffect } from "react";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { NavigationLink } from "components/NavigationLink";
import { Trans, useTranslation } from "react-i18next";
import { Seo } from "components/Seo";
import { gtagSendEvent } from "services/analyticsService";
import QuoteCard from "./components/QuoteCard";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LandingPage: FC = () => {
  const { t } = useTranslation();
  const [currentUseCasesSlide, setCurrentUseCasesSlide] = React.useState(0);
  const [useCasesSliderRef, useCasesSlider] = useKeenSlider<HTMLDivElement>({
    vertical: true,
    initial: 0,
    slidesPerView: 1.2,
    slideChanged(s) {
      setCurrentUseCasesSlide(s.details().relativeSlide);
    },
  });

  const [currentUseCasesMobileSlide, setCurrentUseCasesMobileSlide] =
    React.useState(0);
  const [useCasesMobileSliderRef, useCasesMobileSlider] =
    useKeenSlider<HTMLDivElement>({
      initial: 0,
      slideChanged(s) {
        setCurrentUseCasesMobileSlide(s.details().relativeSlide);
      },
    });

  const [currentSmartSlide, setCurrentSmartSlide] = React.useState(0);
  const [smartSliderRef, smartSlider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(s) {
      setCurrentSmartSlide(s.details().relativeSlide);
    },
  });

  const handlePricingDetailsClick = () => {
    gtagSendEvent("DC-8549231/btnclick/hu_ma002+standard");
  };

  useLayoutEffect(() => {
    gtagSendEvent("DC-8549231/pageview/hu_ma000+standard");
  }, []);

  return (
    <>
      <Seo title={t("seo.landing")} description={t("seoDescription.landing")} />

      <div
        className="flex flex-wrap items-center justify-center"
        style={{
          backgroundImage: "linear-gradient(10deg, white, white, #f2f3f5)",
        }}
      >
        <div className="w-full sm:w-1/2 z-10 pt-8">
          <div className="mx-auto max-w-xs pl-6 sm:max-w-sm sm:mr-0 lg:max-w-lg sm:px-8 md:pr-16 lg:pr-0 md:pl-0 text-secondary">
            <div className="text-2xl md:text-4xl lg:text-4xl 2xl:text-5xl font-medium leading-none">
              {t("landing.mainTitle")}
            </div>
            <div className="mt-4">{t("landing.mainDesc")}</div>
            <div className="mt-10">
              <NavigationLink to="/signup" className="btn-primary">
                {t("landing.cta.order")}
              </NavigationLink>
            </div>
          </div>
        </div>
        <div className="smart-solution-images">
          <picture>
            <img
              className="h-full w-full object-scale-down object-left -z-10"
              sizes="(max-width: 500px) 475w, (max-width: 639px) 677w, 50vw"
              srcSet="
              /img/smart-solution_sofie5_c_scale,w_200.webp 200w,
/img/smart-solution_sofie5_c_scale,w_475.webp 475w,
/img/smart-solution_sofie5_c_scale,w_677.webp 677w,
/img/smart-solution_sofie5_c_scale,w_901.webp 901w,
/img/smart-solution_sofie5_c_scale,w_1087.webp 1087w,
/img/smart-solution_sofie5_c_scale,w_1200.webp 1200w"
              src="/img/smart-solution_sofie5_c_scale,w_1200.webp"
              alt="Mobil app, terminál, bankkártya"
            />
          </picture>
        </div>
      </div>

      <section id="solution" className="mt-16">
        <div className="mx-auto max-w-xl text-center sm:pl-8 sm:text-left">
          <h2 className="text-lg sm:text-xl mb-4">
            {t("landing.welcome.title")}
          </h2>
          <ol className="list-decimal list-inside leading-8">
            <li>{t("landing.welcome.list1")}</li>
            <li>{t("landing.welcome.list2")}</li>
            <li>
              <Trans i18nKey="landing.welcome.list3">
                Kapcsold össze okostelefonod a terminál készülékkel.
              </Trans>
            </li>
            <li>{t("landing.welcome.list4")}</li>
          </ol>
        </div>
        <div className="mx-auto max-w-lg">
          <div className="flex flex-wrap justify-center items-center">
            <div className="pr-4 my-4 sm:my-8">
              {t("A bankkártyás fizetés támogatója:")}
            </div>
            <a href="https://www.mastercard.hu/hu-hu/mastercard-uzleti-megoldasok/kis-es-kozepvallalkozasok/mastercard-elfogadas/legy-kartyaelfogado.html" target="_blank" rel="noreferrer">
              <img
                className="h-10"
                src="/img/mc-logo.svg"
                alt="Mastercard logo"
              />
            </a>
          </div>
        </div>
      </section>

      <section className="why-us relative text-center mt-32">
        <div className="bg-navy text-secondary">
          <h2 className="pt-24 pb-12 sm:pb-32 text-2xl sm:text-3xl lg:text-4xl font-medium">
            <div id="why-us" className="pt-24 -mt-20">
              {t("landing.product.title")}
            </div>
          </h2>
          <div className="pb-12 flex flex-wrap">
            <div className="w-full md:w-1/2 text-right">
              <ul className="md:max-w-lg ml-auto">
                <li className="flex">
                  <div className="w-3/4 md:w-1/2 pl-4">
                    <Trans i18nKey="landing.product.feature1">
                      Nincs regisztrációs, számlanyitási és havidíj, ráadásul
                      hűségidőt sem kell vállalnod!
                    </Trans>
                  </div>
                  <div className="w-1/4 md:w-1/2">
                    <div className="line-container-left">
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="flex md:hidden">
                  <div className="w-1/3 md:w-1/2">
                    <div className="line-container-right">
                      <div></div>
                    </div>
                  </div>
                  <div className="w-2/3 md:w-1/2 pr-4">
                    <Trans i18nKey="landing.product.feature2">
                      Az applikációt interneten keresztül szinte bárhol
                      használhatod!
                    </Trans>
                  </div>
                </li>
                <li className="flex">
                  <div className="w-3/4 md:w-1/2 pl-4">
                    <Trans i18nKey="landing.product.feature3">
                      Gyerekjáték a használata. Időt és pénzt spórolsz!
                    </Trans>
                  </div>
                  <div className="w-1/4 md:w-1/2">
                    <div className="line-container-left">
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="flex md:hidden">
                  <div className="w-1/4 md:w-1/2">
                    <div className="line-container-right">
                      <div></div>
                    </div>
                  </div>
                  <div className="w-3/4 md:w-1/2 pr-4">
                    <Trans i18nKey="landing.product.feature4">
                      Összeköthető könyvelés: Felejtsd el az adminisztrációs
                      bonyodalmakat és irányítsd a bizonylatokat egyből a
                      könyvelődhöz.
                    </Trans>
                  </div>
                </li>
                <li className="flex">
                  <div className="w-3/4 md:w-1/2 pl-4">
                    <Trans i18nKey="landing.product.feature5">
                      2-3 munkanap nap alatt kiszállítjuk és Te azonnal üzembe
                      helyezheted.
                    </Trans>
                  </div>
                  <div className="w-1/4 md:w-1/2">
                    <div className="line-container-left">
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="flex md:hidden">
                  <div className="w-1/3 md:w-1/2">
                    <div className="line-container-right">
                      <div></div>
                    </div>
                  </div>
                  <div className="w-2/3 md:w-1/2 pr-4">
                    <Trans i18nKey="landing.product.feature6">
                      Bankfüggetlen, a pénzt az általad megadott számlaszámra
                      kapod.
                    </Trans>
                  </div>
                </li>
                <li className="flex">
                  <div className="w-2/3 md:w-1/2 pl-4">
                    <Trans i18nKey="landing.product.feature7">
                      Magyar fejlesztőktől, magyar vállalkozók igényeire szabva.
                    </Trans>
                  </div>
                  <div className="w-1/3 md:w-1/2">
                    <div className="line-container-left">
                      <div></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="w-1/2 hidden md:block text-left">
              <ul className="max-w-lg">
                <li className="flex">
                  <div className="w-1/2">
                    <div className="line-container-right">
                      <div></div>
                    </div>
                  </div>
                  <div className="w-1/2 pr-2">
                    <Trans i18nKey="landing.product.feature2">
                      Az applikációt interneten keresztül szinte bárhol
                      használhatod! Nincs többé térerő probléma!
                    </Trans>
                  </div>
                </li>
                <li className="flex">
                  <div className="w-1/2">
                    <div className="line-container-right">
                      <div></div>
                    </div>
                  </div>
                  <div className="w-1/2 pr-2">
                    <Trans i18nKey="landing.product.feature4">
                      Összeköthető könyvelés: Felejtsd el az adminisztrációs
                      bonyodalmakat és irányítsd a bizonylatokat egyből a
                      könyvelődhöz.
                    </Trans>
                  </div>
                </li>
                <li className="flex">
                  <div className="w-1/2 pr-2">
                    <div className="line-container-right">
                      <div></div>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <Trans i18nKey="landing.product.feature6">
                      Bankfüggetlen, a pénzt az általad megadott számlaszámra
                      kapod
                    </Trans>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="why-us-image-container top-0 left-1/2 transform -translate-x-1/2"
            style={{ maxWidth: "835px" }}
          >
            <LazyLoadImage
              srcSet="
              /img/why_phone_okmtdw_c_scale,w_200.webp 200w,
              /img/why_phone_okmtdw_c_scale,w_561.webp 561w,
              /img/why_phone_okmtdw_c_scale,w_835.webp 835w"
              className="object-scale-down object-bottom"
              alt="Agora Pay mobil app inditó képernyő"
            />
          </div>
        </div>
        <div className="-mt-3 sm:mt-24 md:mt-48">
          <div className="relative">
            <NavigationLink to="/signup" className="btn-primary">
              {t("landing.product.order")}
            </NavigationLink>
          </div>
        </div>
      </section>

      <section className="smart mt-24 max-w-6xl mx-auto text-center">
        <div>
          <div className="circles-container py-24 mx-auto">
            <LazyLoadImage
              srcSet="
              /img/terminal_x2kslc_c_scale,w_200.webp 200w,
              /img/terminal_x2kslc_c_scale,w_754.webp 754w,
              /img/terminal_x2kslc_c_scale,w_1040.webp 1040w"
              sizes="(max-width: 800px) 754w, 1040w"
              className="terminal mx-auto"
              alt="Agora Pay mobil POS terminál"
            />
          </div>
        </div>

        <div className="hidden sm:flex -mt-12">
          <div>
            <Trans i18nKey="landing.terminal.feature1">
              <h3>Hazai fejlesztés</h3>
              <p>
                A magyar vállalkozói igényekre szabva, a magyar adó és
                számviteli szabályok mentén
              </p>
            </Trans>
          </div>
          <div className="mt-16 w-1/4">
            <Trans i18nKey="landing.terminal.feature2">
              <h3>Környezettudatos:</h3>
              <p>
                Hőpapír nélkül használható, azonnali elektronikus számla érkezik
                a vevő e-mail címére!
              </p>
            </Trans>
          </div>
          <div className="mt-16">
            <Trans i18nKey="landing.terminal.feature3">
              <h3>Kompakt kialakítás:</h3>
              <p>Kisméretű, strapabíró, könnyű</p>
            </Trans>
          </div>
          <div>
            <Trans i18nKey="landing.terminal.feature4">
              <h3>Folyamatos fejlesztés</h3>
              <p>
                További extra funkciók a jövőben - egyéni bizonylat,
                statisztikák, infografikák, költségkimutatás
              </p>
            </Trans>
          </div>
        </div>

        <div className="block sm:hidden mt-8 h-64">
          <div className="h-full w-64 mx-auto">
            <div ref={smartSliderRef} className="keen-slider h-full">
              <div className="mx-auto keen-slider__slide w-64">
                <Trans i18nKey="landing.terminal.feature1">
                  <h3>Hazai fejlesztés</h3>
                  <p>
                    A magyar vállalkozói igényekre szabva, a magyar adó és
                    számviteli szabályok mentén
                  </p>
                </Trans>
              </div>

              <div className="w-64 mx-auto keen-slider__slide">
                <Trans i18nKey="landing.terminal.feature2">
                  <h3>Környezettudatos:</h3>
                  <p>
                    Hőpapír nélkül használható, azonnali elektronikus számla
                    érkezik a vevő e-mail címére!
                  </p>
                </Trans>
              </div>

              <div className="w-64 mx-auto keen-slider__slide">
                <Trans i18nKey="landing.terminal.feature3">
                  <h3>Kompakt kialakítás:</h3>
                  <p>Kisméretű, strapabíró, könnyű</p>
                </Trans>
              </div>

              <div className="w-64 mx-auto keen-slider__slide">
                <Trans i18nKey="landing.terminal.feature4">
                  <h3>Folyamatos fejlesztés</h3>
                  <p>
                    További extra funkciók a jövőben - egyéni bizonylat,
                    statisztikák, infografikák, költségkimutatás
                  </p>
                </Trans>
              </div>
            </div>

            <div className="swipe-indicator">
              {[0, 1, 2, 3].map((x) => (
                <span
                  key={x}
                  onClick={() => {
                    smartSlider.moveToSlideRelative(x);
                  }}
                  className={`dot ${
                    currentSmartSlide === x ? "is-active" : ""
                  }`}
                ></span>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="usecases mt-40 flex flex-wrap">
        <div className="bg-secondary w-full md:w-3/5 pb-64 pt-24 md:pb-24 px-6 md:pr-48">
          <div className="max-w-sm mx-auto text-center md:text-left">
            <h2 className="text-2xl md:text-3xl xl:text-4xl font-medium">
              <div id="usecases" className="pt-24 -mt-20">
                {t("landing.usecases.recommend")}
              </div>
            </h2>
            <ul className="mt-4 list-none">
              <li>{t("landing.usecases.1")}</li>
              <li>{t("landing.usecases.2")}</li>
              <li>{t("landing.usecases.3")}</li>
              <li>{t("landing.usecases.4")}</li>
              <li>{t("landing.usecases.5")}</li>
              <li>{t("landing.usecases.6")}</li>
            </ul>
            <p>{t("landing.usecases.joinus")}</p>
            <div className="my-10">
              <NavigationLink to="/signup" className="btn-primary">
                {t("Engem is érdekel")}
              </NavigationLink>
            </div>
          </div>
        </div>

        <div className="desktop-swipe-holder">
          <div className="mb-12" style={{ marginLeft: "-20px", width: "40px" }}>
            <button type="button" onClick={() => useCasesSlider.prev()}>
              <img src="/img/arrow_up.svg" alt="Fel" />
            </button>
          </div>

          <div
            ref={useCasesSliderRef}
            className="keen-slider"
            style={{ height: "30rem", marginLeft: "-240px" }}
          >
            <div className="keen-slider__slide">
              <QuoteCard img="/img/usecases/shutterstock_620003576.webp">
                <ul>
                  <li>{t("landing.usecases.case1.1")}</li>
                  <li>{t("landing.usecases.case1.2")}</li>
                  <li>{t("landing.usecases.case1.3")}</li>
                  <li>{t("landing.usecases.case1.4")}</li>
                </ul>
              </QuoteCard>
            </div>

            <div
              className={`keen-slider__slide ${
                currentUseCasesSlide === 0 ? "opacity-50" : ""
              }`}
            >
              <QuoteCard img="/img/usecases/shutterstock_342527414.webp">
                <ul>
                  <li>{t("landing.usecases.case2.1")}</li>
                  <li>{t("landing.usecases.case2.2")}</li>
                  <li>{t("landing.usecases.case2.3")}</li>
                  <li>{t("landing.usecases.case2.4")}</li>
                </ul>
              </QuoteCard>
            </div>

            <div
              className={`keen-slider__slide ${
                currentUseCasesSlide === 1 ? "opacity-50" : ""
              }`}
            >
              <QuoteCard img="/img/usecases/shutterstock_532051300.webp">
                <ul>
                  <li>{t("landing.usecases.case3.1")}</li>
                  <li>{t("landing.usecases.case3.2")}</li>
                  <li>{t("landing.usecases.case3.3")}</li>
                  <li>{t("landing.usecases.case3.4")}</li>
                </ul>
              </QuoteCard>
            </div>

            <div
              className={`keen-slider__slide ${
                currentUseCasesSlide === 2 ? "opacity-50" : ""
              }`}
            >
              <QuoteCard img="/img/usecases/shutterstock_1167639373.webp">
                <ul>
                  <li>{t("landing.usecases.case4.1")}</li>
                  <li>{t("landing.usecases.case4.2")}</li>
                  <li>{t("landing.usecases.case4.3")}</li>
                </ul>
              </QuoteCard>
            </div>

            <div
              className={`keen-slider__slide ${
                currentUseCasesSlide === 3 ? "opacity-50" : ""
              }`}
            >
              <QuoteCard img="/img/usecases/shutterstock_1725076066.webp">
                <ul>
                  <li>{t("landing.usecases.case5.1")}</li>
                  <li>{t("landing.usecases.case5.2")}</li>
                  <li>{t("landing.usecases.case5.3")}</li>
                </ul>
              </QuoteCard>
            </div>

            <div
              className={`keen-slider__slide ${
                currentUseCasesSlide === 4 ? "opacity-50" : ""
              }`}
            >
              <QuoteCard img="/img/usecases/shutterstock_1065652877.webp">
                <ul>
                  <li>{t("landing.usecases.case6.1")}</li>
                  <li>{t("landing.usecases.case6.2")}</li>
                  <li>{t("landing.usecases.case6.3")}</li>
                </ul>
              </QuoteCard>
            </div>

            <div
              className={`keen-slider__slide ${
                currentUseCasesSlide === 5 ? "opacity-50" : ""
              }`}
            >
              <QuoteCard img="/img/usecases/shutterstock_1688653729.webp">
                <ul>
                  <li>{t("landing.usecases.case7.1")}</li>
                  <li>{t("landing.usecases.case7.2")}</li>
                  <li>{t("landing.usecases.case7.3")}</li>
                  <li>{t("landing.usecases.case7.4")}</li>
                </ul>
              </QuoteCard>
            </div>
          </div>

          <div className="mt-12" style={{ marginLeft: "-20px", width: "40px" }}>
            <button type="button" onClick={() => useCasesSlider.next()}>
              <img src="/img/arrow_down.svg" alt="Le" />
            </button>
          </div>
        </div>

        <div className="mobile-swipe-holder">
          <div ref={useCasesMobileSliderRef} className="keen-slider h-full">
            <div className="keen-slider__slide">
              <QuoteCard img="/img/usecases/shutterstock_620003576.webp">
                <ul>
                  <li>{t("landing.usecases.case1.1")}</li>
                  <li>{t("landing.usecases.case1.2")}</li>
                  <li>{t("landing.usecases.case1.3")}</li>
                  <li>{t("landing.usecases.case1.4")}</li>
                </ul>
              </QuoteCard>
            </div>

            <div className="keen-slider__slide">
              <QuoteCard img="/img/usecases/shutterstock_342527414.webp">
                <ul>
                  <li>{t("landing.usecases.case2.1")}</li>
                  <li>{t("landing.usecases.case2.2")}</li>
                  <li>{t("landing.usecases.case2.3")}</li>
                  <li>{t("landing.usecases.case2.4")}</li>
                </ul>
              </QuoteCard>
            </div>

            <div className="keen-slider__slide">
              <QuoteCard img="/img/usecases/shutterstock_532051300.webp">
                <ul>
                  <li>{t("landing.usecases.case3.1")}</li>
                  <li>{t("landing.usecases.case3.2")}</li>
                  <li>{t("landing.usecases.case3.3")}</li>
                  <li>{t("landing.usecases.case3.4")}</li>
                </ul>
              </QuoteCard>
            </div>

            <div className="keen-slider__slide">
              <QuoteCard img="/img/usecases/shutterstock_1167639373.webp">
                <ul>
                  <li>{t("landing.usecases.case4.1")}</li>
                  <li>{t("landing.usecases.case4.2")}</li>
                  <li>{t("landing.usecases.case4.3")}</li>
                </ul>
              </QuoteCard>
            </div>

            <div className="keen-slider__slide">
              <QuoteCard img="/img/usecases/shutterstock_1725076066.webp">
                <ul>
                  <li>{t("landing.usecases.case5.1")}</li>
                  <li>{t("landing.usecases.case5.2")}</li>
                  <li>{t("landing.usecases.case5.3")}</li>
                </ul>
              </QuoteCard>
            </div>

            <div className="keen-slider__slide">
              <QuoteCard img="/img/usecases/shutterstock_1065652877.webp">
                <ul>
                  <li>{t("landing.usecases.case6.1")}</li>
                  <li>{t("landing.usecases.case6.2")}</li>
                  <li>{t("landing.usecases.case6.3")}</li>
                </ul>
              </QuoteCard>
            </div>

            <div className="keen-slider__slide">
              <QuoteCard img="/img/usecases/shutterstock_1688653729.webp">
                <ul>
                  <li>{t("landing.usecases.case7.1")}</li>
                  <li>{t("landing.usecases.case7.2")}</li>
                  <li>{t("landing.usecases.case7.3")}</li>
                  <li>{t("landing.usecases.case7.4")}</li>
                </ul>
              </QuoteCard>
            </div>
          </div>

          <div className="swipe-indicator">
            {[0, 1, 2, 3, 4, 5, 6].map((x) => (
              <span
                key={x}
                onClick={() => {
                  useCasesMobileSlider.moveToSlideRelative(x);
                }}
                className={`dot ${
                  currentUseCasesMobileSlide === x ? "is-active" : ""
                }`}
              ></span>
            ))}
          </div>
        </div>
      </section>

      <section className="pricing mt-16 sm:mt-32 text-center">
        <h2>
          <div id="pricing" className="pt-24 pb-4 -mt-20">
            {t("Áraink")}
          </div>
        </h2>
        <p>
          {t(
            "Transzparens, könnyen áttekinthető árazás, hogy Te a lényeges dolgokra tudj koncentrálni."
          )}
        </p>
        <div className="item-container mt-8">
          <div className="line-container">
            <div></div>
          </div>
          <div className="text-container-left bg-contain">
            <div>
              <Trans i18nKey="landing.prices.fees">
                <div>
                  <strong>0 Ft</strong> regisztrációs díj
                </div>
                <div>
                  <strong>0 Ft</strong> telepítési díj
                </div>
              </Trans>
            </div>
          </div>
        </div>
        <div className="item-container justify-end">
          <div className="text-container-right bg-contain">
            <div>
              <Trans i18nKey="landing.prices.monthlyFee">
                <strong>0 Ft</strong> havidíj <br />
                csak akkor fizetsz, ha valóban használod
              </Trans>
            </div>
          </div>
          <div className="line-container">
            <div></div>
          </div>
        </div>
        <div className="item-container">
          <div className="line-container">
            <div></div>
          </div>
          <div className="text-container-left bg-contain">
            <div>
              <Trans i18nKey="landing.prices.transactionFee">
                <strong>1,7%*</strong> tranzakciós díj
              </Trans>
            </div>
          </div>
        </div>
        <div className="item-container justify-end">
          <div className="text-container-right bg-contain">
            <Trans i18nKey="landing.prices.replace">
              Kártyaolvasó egyszeri
              <br />
              díja: 19 990 + ÁFA
            </Trans>
          </div>
          <div className="line-container">
            <div></div>
          </div>
        </div>
        <div className="mt-20">
          <NavigationLink
            to="/pricing#enter"
            className="btn-primary"
            onClick={handlePricingDetailsClick}
          >
            {t("Részletek")}
          </NavigationLink>
        </div>
        <p className="pt-6 text-sm">{t("landing.prices.astrix")}</p>
      </section>

      <section className="my-32 text-center md:text-left">
        <div className="flex items-center justify-between">
          <div className="w-full md:w-2/5">
            <div className="px-6 max-w-md mx-auto">
              <h2 className="mb-6">
                <div id="about-us" className="pt-24 -mt-20">
                  {t("Rólunk")}
                </div>
              </h2>
              <Trans i18nKey="landing.aboutus.desc">
                <p>
                  Az Agora Pay egy olyan innovatív magyar fizetéstechnológiai
                  szolgáltató, amely a hazai vállalkozások életét könnyíti meg a
                  bankkártyás fizetés zökkenőmentes bevezetésével.
                </p>
                <p>
                  Az általunk fejlesztett megoldás ötvözi a magyar fejlesztői
                  szaktudást, a hazai fizetési piac ismeretét és a naprakész,
                  modern technológiák iránti rajongást.
                </p>
                <p>
                  Hiszünk a folyamatos fejlesztésekben, az egyszerűségben, az
                  átlátható árazásban; de leginkább azt tekintjük személyes
                  küldetésünkek, hogy az Agora Pay megoldás segítségével az okos
                  bankkártya elfogadást bárki számára elérhetővé tegyük
                  korosztálytól függetlenül.
                </p>
              </Trans>
            </div>
          </div>
          <div className="w-0 md:w-3/5 max-w-3xl pt-8">
            <img src="/img/aboutus_logo.svg" alt="Agora Pay logo" />
          </div>
        </div>
      </section>
    </>
  );
};

export default LandingPage;
