import React, { FC, useMemo } from "react";
import SignupFinished from "./SignupFinished";
import SignupSuccessAndPay from "./SignupSuccessAndPay";
import { useLocation } from "react-router-dom";
import { PaymentMethod } from "api/generated/models";
import { Seo } from "components/Seo";

interface SignupSuccessProps {}

export const SignupSuccess: FC<SignupSuccessProps> = () => {
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  return (
    <>
      <Seo />

      {searchParams.get("paymentMethod") === PaymentMethod.BankCard ? (
        <SignupSuccessAndPay />
      ) : (
        <SignupFinished />
      )}
    </>
  );
};
