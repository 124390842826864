import { DropdownData } from 'models/DropdownData'


export const countries: DropdownData[] = [
  { value: 'HU', text: 'Magyarország', i18nKey: 'countries.HU' },
  { value: 'AF', text: 'Afganisztán', i18nKey: 'countries.AF' },
  { value: 'AX', text: 'Åland', i18nKey: 'countries.AX' },
  { value: 'AL', text: 'Albánia', i18nKey: 'countries.AL' },
  { value: 'DZ', text: 'Algéria', i18nKey: 'countries.DZ' },
  { value: 'US', text: 'Amerikai Egyesült Államok', i18nKey: 'countries.US' },
  { value: 'AS', text: 'Amerikai Szamoa', i18nKey: 'countries.AS' },
  { value: 'VI', text: 'Amerikai Virgin-szigetek', i18nKey: 'countries.VI' },
  { value: 'AD', text: 'Andorra', i18nKey: 'countries.AD' },
  { value: 'AO', text: 'Angola', i18nKey: 'countries.AO' },
  { value: 'AI', text: 'Anguilla', i18nKey: 'countries.AI' },
  { value: 'AQ', text: 'Antarktisz', i18nKey: 'countries.AQ' },
  { value: 'AG', text: 'Antigua és Barbuda', i18nKey: 'countries.AG' },
  { value: 'AR', text: 'Argentína', i18nKey: 'countries.AR' },
  { value: 'AW', text: 'Aruba', i18nKey: 'countries.AW' },
  { value: 'AU', text: 'Ausztrália', i18nKey: 'countries.AU' },
  { value: 'AT', text: 'Ausztria', i18nKey: 'countries.AT' },
  { value: 'AZ', text: 'Azerbajdzsán', i18nKey: 'countries.AZ' },
  { value: 'BS', text: 'Bahama-szigetek', i18nKey: 'countries.BS' },
  { value: 'BH', text: 'Bahrein', i18nKey: 'countries.BH' },
  { value: 'BD', text: 'Banglades', i18nKey: 'countries.BD' },
  { value: 'BB', text: 'Barbados', i18nKey: 'countries.BB' },
  { value: 'BE', text: 'Belgium', i18nKey: 'countries.BE' },
  { value: 'BZ', text: 'Belize', i18nKey: 'countries.BZ' },
  { value: 'BJ', text: 'Benin', i18nKey: 'countries.BJ' },
  { value: 'BM', text: 'Bermuda', i18nKey: 'countries.BM' },
  { value: 'BT', text: 'Bhután', i18nKey: 'countries.BT' },
  { value: 'GW', text: 'Bissau-Guinea', i18nKey: 'countries.GW' },
  { value: 'BO', text: 'Bolívia', i18nKey: 'countries.BO' },
  { value: 'BA', text: 'Bosznia-Hercegovina', i18nKey: 'countries.BA' },
  { value: 'BW', text: 'Botswana', i18nKey: 'countries.BW' },
  { value: 'BR', text: 'Brazília', i18nKey: 'countries.BR' },
  { value: 'IO', text: 'Brit Indiai-óceáni Terület', i18nKey: 'countries.IO' },
  { value: 'VG', text: 'Brit Virgin-szigetek', i18nKey: 'countries.VG' },
  { value: 'BN', text: 'Brunei', i18nKey: 'countries.BN' },
  { value: 'BG', text: 'Bulgária', i18nKey: 'countries.BG' },
  { value: 'BF', text: 'Burkina Faso', i18nKey: 'countries.BF' },
  { value: 'BI', text: 'Burundi', i18nKey: 'countries.BI' },
  { value: 'CL', text: 'Chile', i18nKey: 'countries.CL' },
  { value: 'CY', text: 'Ciprus', i18nKey: 'countries.CY' },
  { value: 'KM', text: 'Comore-szigetek', i18nKey: 'countries.KM' },
  { value: 'CK', text: 'Cook-szigetek', i18nKey: 'countries.CK' },
  { value: 'CR', text: 'Costa Rica', i18nKey: 'countries.CR' },
  { value: 'TD', text: 'Csád', i18nKey: 'countries.TD' },
  { value: 'CZ', text: 'Csehország', i18nKey: 'countries.CZ' },
  { value: 'CW', text: 'Curaçao', i18nKey: 'countries.CW' },
  { value: 'DK', text: 'Dánia', i18nKey: 'countries.DK' },
  { value: 'ZA', text: 'Dél-afrikai Köztársaság', i18nKey: 'countries.ZA' },
  { value: 'GS', text: 'Déli-Georgia és Déli-Sandwich-szigetek', i18nKey: 'countries.GS' },
  { value: 'KR', text: 'Dél-Korea (Koreai Köztársaság)', i18nKey: 'countries.KR' },
  { value: 'SS', text: 'Dél-Szudán', i18nKey: 'countries.SS' },
  { value: 'DM', text: 'Dominikai Közösség', i18nKey: 'countries.DM' },
  { value: 'DO', text: 'Dominikai Köztársaság', i18nKey: 'countries.DO' },
  { value: 'DJ', text: 'Dzsibuti', i18nKey: 'countries.DJ' },
  { value: 'EC', text: 'Ecuador', i18nKey: 'countries.EC' },
  { value: 'GQ', text: 'Egyenlítői-Guinea', i18nKey: 'countries.GQ' },
  { value: 'AE', text: 'Egyesült Arab Emírségek', i18nKey: 'countries.AE' },
  { value: 'GB', text: 'Egyesült Királyság', i18nKey: 'countries.GB' },
  { value: 'EG', text: 'Egyiptom', i18nKey: 'countries.EG' },
  { value: 'CI', text: 'Elefántcsontpart', i18nKey: 'countries.CI' },
  { value: 'ER', text: 'Eritrea', i18nKey: 'countries.ER' },
  { value: 'MP', text: 'Északi-Mariana-szigetek', i18nKey: 'countries.MP' },
  { value: 'KP', text: 'Észak-Korea (Koreai NDK)', i18nKey: 'countries.KP' },
  { value: 'MK', text: 'Észak-Macedónia', i18nKey: 'countries.MK' },
  { value: 'EE', text: 'Észtország', i18nKey: 'countries.EE' },
  { value: 'ET', text: 'Etiópia', i18nKey: 'countries.ET' },
  { value: 'FK', text: 'Falkland-szigetek', i18nKey: 'countries.FK' },
  { value: 'BY', text: 'Fehéroroszország', i18nKey: 'countries.BY' },
  { value: 'FO', text: 'Feröer', i18nKey: 'countries.FO' },
  { value: 'FJ', text: 'Fidzsi', i18nKey: 'countries.FJ' },
  { value: 'FI', text: 'Finnország', i18nKey: 'countries.FI' },
  { value: 'ES', text: 'Flag of Spain.svg Spanyolország', i18nKey: 'countries.ES' },
  { value: 'TF', text: 'Francia déli és antarktiszi területek', i18nKey: 'countries.TF' },
  { value: 'GF', text: 'Francia Guyana Francia Guyana', i18nKey: 'countries.GF' },
  { value: 'PF', text: 'Francia Polinézia', i18nKey: 'countries.PF' },
  { value: 'FR', text: 'Franciaország', i18nKey: 'countries.FR' },
  { value: 'PH', text: 'Fülöp-szigetek', i18nKey: 'countries.PH' },
  { value: 'GA', text: 'Gabon', i18nKey: 'countries.GA' },
  { value: 'GM', text: 'Gambia', i18nKey: 'countries.GM' },
  { value: 'GH', text: 'Ghána', i18nKey: 'countries.GH' },
  { value: 'GI', text: 'Gibraltár', i18nKey: 'countries.GI' },
  { value: 'GR', text: 'Görögország', i18nKey: 'countries.GR' },
  { value: 'GD', text: 'Grenada', i18nKey: 'countries.GD' },
  { value: 'GL', text: 'Grönland', i18nKey: 'countries.GL' },
  { value: 'GE', text: 'Grúzia', i18nKey: 'countries.GE' },
  { value: 'GP', text: 'Guadeloupe Guadeloupe', i18nKey: 'countries.GP' },
  { value: 'GU', text: 'Guam', i18nKey: 'countries.GU' },
  { value: 'GT', text: 'Guatemala', i18nKey: 'countries.GT' },
  { value: 'GG', text: 'Guernsey Bailiffség', i18nKey: 'countries.GG' },
  { value: 'GN', text: 'Guinea', i18nKey: 'countries.GN' },
  { value: 'GY', text: 'Guyana', i18nKey: 'countries.GY' },
  { value: 'HT', text: 'Haiti', i18nKey: 'countries.HT' },
  { value: 'HM', text: 'Heard-sziget és McDonald-szigetek', i18nKey: 'countries.HM' },
  { value: 'NL', text: 'Hollandia', i18nKey: 'countries.NL' },
  { value: 'HN', text: 'Honduras', i18nKey: 'countries.HN' },
  { value: 'HK', text: 'Hongkong', i18nKey: 'countries.HK' },
  { value: 'HR', text: 'Horvátország', i18nKey: 'countries.HR' },
  { value: 'IN', text: 'India', i18nKey: 'countries.IN' },
  { value: 'ID', text: 'Indonézia', i18nKey: 'countries.ID' },
  { value: 'IQ', text: 'Irak', i18nKey: 'countries.IQ' },
  { value: 'IR', text: 'Irán', i18nKey: 'countries.IR' },
  { value: 'IE', text: 'Írország', i18nKey: 'countries.IE' },
  { value: 'IS', text: 'Izland', i18nKey: 'countries.IS' },
  { value: 'IL', text: 'Izrael', i18nKey: 'countries.IL' },
  { value: 'JM', text: 'Jamaica', i18nKey: 'countries.JM' },
  { value: 'JP', text: 'Japán', i18nKey: 'countries.JP' },
  { value: 'YE', text: 'Jemen', i18nKey: 'countries.YE' },
  { value: 'JE', text: 'Jersey Bailiffség', i18nKey: 'countries.JE' },
  { value: 'JO', text: 'Jordánia', i18nKey: 'countries.JO' },
  { value: 'KY', text: 'Kajmán-szigetek', i18nKey: 'countries.KY' },
  { value: 'KH', text: 'Kambodzsa', i18nKey: 'countries.KH' },
  { value: 'CM', text: 'Kamerun', i18nKey: 'countries.CM' },
  { value: 'CA', text: 'Kanada', i18nKey: 'countries.CA' },
  { value: 'CX', text: 'Karácsony-sziget', i18nKey: 'countries.CX' },
  { value: 'BQ', text: 'Karibi Hollandia ( Bonaire,  Saba,  Sint Eustatius)', i18nKey: 'countries.BQ' },
  { value: 'QA', text: 'Katar', i18nKey: 'countries.QA' },
  { value: 'KZ', text: 'Kazahsztán', i18nKey: 'countries.KZ' },
  { value: 'TL', text: 'Kelet-Timor', i18nKey: 'countries.TL' },
  { value: 'KE', text: 'Kenya', i18nKey: 'countries.KE' },
  { value: 'CN', text: 'Kína', i18nKey: 'countries.CN' },
  { value: 'KG', text: 'Kirgizisztán', i18nKey: 'countries.KG' },
  { value: 'KI', text: 'Kiribati', i18nKey: 'countries.KI' },
  { value: 'CC', text: 'Kókusz (Keeling)-szigetek', i18nKey: 'countries.CC' },
  { value: 'CO', text: 'Kolumbia', i18nKey: 'countries.CO' },
  { value: 'CD', text: 'Kongói Demokratikus Köztársaság (Zaire)', i18nKey: 'countries.CD' },
  { value: 'CG', text: 'Kongói Köztársaság (Kongó)', i18nKey: 'countries.CG' },
  { value: 'CF', text: 'Közép-Afrika', i18nKey: 'countries.CF' },
  { value: 'CU', text: 'Kuba', i18nKey: 'countries.CU' },
  { value: 'KW', text: 'Kuvait', i18nKey: 'countries.KW' },
  { value: 'LA', text: 'Laosz', i18nKey: 'countries.LA' },
  { value: 'PL', text: 'Lengyelország', i18nKey: 'countries.PL' },
  { value: 'LS', text: 'Lesotho', i18nKey: 'countries.LS' },
  { value: 'LV', text: 'Lettország', i18nKey: 'countries.LV' },
  { value: 'LB', text: 'Libanon', i18nKey: 'countries.LB' },
  { value: 'LR', text: 'Libéria', i18nKey: 'countries.LR' },
  { value: 'LY', text: 'Líbia', i18nKey: 'countries.LY' },
  { value: 'LI', text: 'Liechtenstein', i18nKey: 'countries.LI' },
  { value: 'LT', text: 'Litvánia', i18nKey: 'countries.LT' },
  { value: 'LU', text: 'Luxemburg', i18nKey: 'countries.LU' },
  { value: 'MG', text: 'Madagaszkár', i18nKey: 'countries.MG' },
  { value: 'MO', text: 'Makaó', i18nKey: 'countries.MO' },
  { value: 'MY', text: 'Malajzia', i18nKey: 'countries.MY' },
  { value: 'MW', text: 'Malawi', i18nKey: 'countries.MW' },
  { value: 'MV', text: 'Maldív-szigetek', i18nKey: 'countries.MV' },
  { value: 'ML', text: 'Mali', i18nKey: 'countries.ML' },
  { value: 'MT', text: 'Málta', i18nKey: 'countries.MT' },
  { value: 'IM', text: 'Man', i18nKey: 'countries.IM' },
  { value: 'MA', text: 'Marokkó', i18nKey: 'countries.MA' },
  { value: 'MH', text: 'Marshall-szigetek', i18nKey: 'countries.MH' },
  { value: 'MQ', text: 'Martinique Martinique', i18nKey: 'countries.MQ' },
  { value: 'MR', text: 'Mauritánia', i18nKey: 'countries.MR' },
  { value: 'MU', text: 'Mauritius', i18nKey: 'countries.MU' },
  { value: 'YT', text: 'Mayotte Mayotte', i18nKey: 'countries.YT' },
  { value: 'MX', text: 'Mexikó', i18nKey: 'countries.MX' },
  { value: 'MM', text: 'Mianmar', i18nKey: 'countries.MM' },
  { value: 'FM', text: 'Mikronézia', i18nKey: 'countries.FM' },
  { value: 'MD', text: 'Moldova', i18nKey: 'countries.MD' },
  { value: 'MC', text: 'Monaco', i18nKey: 'countries.MC' },
  { value: 'MN', text: 'Mongólia', i18nKey: 'countries.MN' },
  { value: 'ME', text: 'Montenegró', i18nKey: 'countries.ME' },
  { value: 'MS', text: 'Montserrat', i18nKey: 'countries.MS' },
  { value: 'MZ', text: 'Mozambik', i18nKey: 'countries.MZ' },
  { value: 'NA', text: 'Namíbia', i18nKey: 'countries.NA' },
  { value: 'NR', text: 'Nauru', i18nKey: 'countries.NR' },
  { value: 'DE', text: 'Németország', i18nKey: 'countries.DE' },
  { value: 'NP', text: 'Nepál', i18nKey: 'countries.NP' },
  { value: 'NI', text: 'Nicaragua', i18nKey: 'countries.NI' },
  { value: 'NE', text: 'Niger', i18nKey: 'countries.NE' },
  { value: 'NG', text: 'Nigéria', i18nKey: 'countries.NG' },
  { value: 'NU', text: 'Niue', i18nKey: 'countries.NU' },
  { value: 'NF', text: 'Norfolk-sziget', i18nKey: 'countries.NF' },
  { value: 'NO', text: 'Norvégia', i18nKey: 'countries.NO' },
  { value: 'BV', text: 'Norvégia Bouvet-sziget', i18nKey: 'countries.BV' },
  { value: 'SJ', text: 'Norvégia Spitzbergák és Jan Mayen-sziget', i18nKey: 'countries.SJ' },
  { value: 'EH', text: 'Nyugat-Szahara', i18nKey: 'countries.EH' },
  { value: 'IT', text: 'Olaszország', i18nKey: 'countries.IT' },
  { value: 'OM', text: 'Omán', i18nKey: 'countries.OM' },
  { value: 'AM', text: 'Örményország', i18nKey: 'countries.AM' },
  { value: 'RU', text: 'Oroszország', i18nKey: 'countries.RU' },
  { value: 'PK', text: 'Pakisztán', i18nKey: 'countries.PK' },
  { value: 'PW', text: 'Palau', i18nKey: 'countries.PW' },
  { value: 'PS', text: 'Palesztina', i18nKey: 'countries.PS' },
  { value: 'PA', text: 'Panama', i18nKey: 'countries.PA' },
  { value: 'PG', text: 'Pápua Új-Guinea', i18nKey: 'countries.PG' },
  { value: 'PY', text: 'Paraguay', i18nKey: 'countries.PY' },
  { value: 'PE', text: 'Peru', i18nKey: 'countries.PE' },
  { value: 'PN', text: 'Pitcairn-szigetek', i18nKey: 'countries.PN' },
  { value: 'PT', text: 'Portugália', i18nKey: 'countries.PT' },
  { value: 'PR', text: 'Puerto Rico', i18nKey: 'countries.PR' },
  { value: 'RE', text: 'Réunion', i18nKey: 'countries.RE' },
  { value: 'RO', text: 'Románia', i18nKey: 'countries.RO' },
  { value: 'RW', text: 'Ruanda', i18nKey: 'countries.RW' },
  { value: 'KN', text: 'Saint Kitts és Nevis', i18nKey: 'countries.KN' },
  { value: 'LC', text: 'Saint Lucia', i18nKey: 'countries.LC' },
  { value: 'VC', text: 'Saint Vincent és a Grenadine-szigetek', i18nKey: 'countries.VC' },
  { value: 'BL', text: 'Saint-Barthélemy', i18nKey: 'countries.BL' },
  { value: 'MF', text: 'Saint-Martin', i18nKey: 'countries.MF' },
  { value: 'PM', text: 'Saint-Pierre és Miquelon Saint-Pierre és Miquelon', i18nKey: 'countries.PM' },
  { value: 'SB', text: 'Salamon-szigetek', i18nKey: 'countries.SB' },
  { value: 'SV', text: 'Salvador', i18nKey: 'countries.SV' },
  { value: 'SM', text: 'San Marino', i18nKey: 'countries.SM' },
  { value: 'ST', text: 'São Tomé és Príncipe', i18nKey: 'countries.ST' },
  { value: 'SC', text: 'Seychelle-szigetek', i18nKey: 'countries.SC' },
  { value: 'SL', text: 'Sierra Leone', i18nKey: 'countries.SL' },
  { value: 'SX', text: 'Sint Maarten', i18nKey: 'countries.SX' },
  { value: 'LK', text: 'Srí Lanka', i18nKey: 'countries.LK' },
  { value: 'SR', text: 'Suriname', i18nKey: 'countries.SR' },
  { value: 'CH', text: 'Svájc', i18nKey: 'countries.CH' },
  { value: 'SE', text: 'Svédország', i18nKey: 'countries.SE' },
  { value: 'WS', text: 'Szamoa', i18nKey: 'countries.WS' },
  { value: 'SA', text: 'Szaúd-Arábia', i18nKey: 'countries.SA' },
  { value: 'SN', text: 'Szenegál', i18nKey: 'countries.SN' },
  { value: 'SH', text: 'Szent Ilona', i18nKey: 'countries.SH' },
  { value: 'RS', text: 'Szerbia', i18nKey: 'countries.RS' },
  { value: 'SG', text: 'Szingapúr', i18nKey: 'countries.SG' },
  { value: 'SY', text: 'Szíria', i18nKey: 'countries.SY' },
  { value: 'SK', text: 'Szlovákia', i18nKey: 'countries.SK' },
  { value: 'SI', text: 'Szlovénia', i18nKey: 'countries.SI' },
  { value: 'SO', text: 'Szomália', i18nKey: 'countries.SO' },
  { value: 'SD', text: 'Szudán', i18nKey: 'countries.SD' },
  { value: 'SZ', text: 'Szváziföld', i18nKey: 'countries.SZ' },
  { value: 'TJ', text: 'Tádzsikisztán', i18nKey: 'countries.TJ' },
  { value: 'TW', text: 'Tajvan', i18nKey: 'countries.TW' },
  { value: 'TZ', text: 'Tanzánia', i18nKey: 'countries.TZ' },
  { value: 'TH', text: 'Thaiföld', i18nKey: 'countries.TH' },
  { value: 'TG', text: 'Togo', i18nKey: 'countries.TG' },
  { value: 'TK', text: 'Tokelau-szigetek', i18nKey: 'countries.TK' },
  { value: 'TO', text: 'Tonga', i18nKey: 'countries.TO' },
  { value: 'TR', text: 'Törökország', i18nKey: 'countries.TR' },
  { value: 'TT', text: 'Trinidad és Tobago', i18nKey: 'countries.TT' },
  { value: 'TN', text: 'Tunézia', i18nKey: 'countries.TN' },
  { value: 'TM', text: 'Türkmenisztán', i18nKey: 'countries.TM' },
  { value: 'TC', text: 'Turks- és Caicos-szigetek', i18nKey: 'countries.TC' },
  { value: 'TV', text: 'Tuvalu', i18nKey: 'countries.TV' },
  { value: 'UG', text: 'Uganda', i18nKey: 'countries.UG' },
  { value: 'NC', text: 'Új-Kaledónia', i18nKey: 'countries.NC' },
  { value: 'NZ', text: 'Új-Zéland', i18nKey: 'countries.NZ' },
  { value: 'UA', text: 'Ukrajna', i18nKey: 'countries.UA' },
  { value: 'UY', text: 'Uruguay', i18nKey: 'countries.UY' },
  { value: 'UM', text: 'USA Amerikai Csendes-óceáni szigetek', i18nKey: 'countries.UM' },
  { value: 'UZ', text: 'Üzbegisztán', i18nKey: 'countries.UZ' },
  { value: 'VU', text: 'Vanuatu', i18nKey: 'countries.VU' },
  { value: 'VA', text: 'Vatikán', i18nKey: 'countries.VA' },
  { value: 'VE', text: 'Venezuela', i18nKey: 'countries.VE' },
  { value: 'VN', text: 'Vietnám', i18nKey: 'countries.VN' },
  { value: 'WF', text: 'Wallis és Futuna Wallis és Futuna', i18nKey: 'countries.WF' },
  { value: 'ZM', text: 'Zambia', i18nKey: 'countries.ZM' },
  { value: 'ZW', text: 'Zimbabwe', i18nKey: 'countries.ZW' },
  { value: 'CV', text: 'Zöld-foki Köztársaság', i18nKey: 'countries.CV' },
]
