import { authActions } from 'features/auth/authSlice'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { PrivateOnlyDisplay } from './PrivateOnlyDisplay'

export const Logout: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <PrivateOnlyDisplay>
      <button
        onClick={() => dispatch(authActions.logout())}
        className="btn-login"
      >
        {t('common.logout', 'Kijelentkezés')}
      </button>
    </PrivateOnlyDisplay>
  )
}
