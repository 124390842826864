// tslint:disable
/**
 * AgoraPay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CalculateResultDto } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * TerminalOrderApi - axios parameter creator
 * @export
 */
export const TerminalOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [terminalCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalOrderCalculateTotalPrice: async (terminalCount?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/TerminalOrder/Calculate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (terminalCount !== undefined) {
                localVarQueryParameter['TerminalCount'] = terminalCount;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TerminalOrderApi - functional programming interface
 * @export
 */
export const TerminalOrderApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [terminalCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalOrderCalculateTotalPrice(terminalCount?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculateResultDto>> {
            const localVarAxiosArgs = await TerminalOrderApiAxiosParamCreator(configuration).terminalOrderCalculateTotalPrice(terminalCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TerminalOrderApi - factory interface
 * @export
 */
export const TerminalOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} [terminalCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalOrderCalculateTotalPrice(terminalCount?: number, options?: any): AxiosPromise<CalculateResultDto> {
            return TerminalOrderApiFp(configuration).terminalOrderCalculateTotalPrice(terminalCount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TerminalOrderApi - object-oriented interface
 * @export
 * @class TerminalOrderApi
 * @extends {BaseAPI}
 */
export class TerminalOrderApi extends BaseAPI {
    /**
     * 
     * @param {number} [terminalCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalOrderApi
     */
    public terminalOrderCalculateTotalPrice(terminalCount?: number, options?: any) {
        return TerminalOrderApiFp(this.configuration).terminalOrderCalculateTotalPrice(terminalCount, options).then((request) => request(this.axios, this.basePath));
    }

}
