import { RootState } from 'app/store'
import AddressSchemeDisplay from 'components/AddressSchemeDisplay'
import { ActionButton } from 'components/buttons/ActionButton'
import { ListDropdown } from 'components/form/ListDropdown'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { countries } from 'services/countryService'
import { signupActions } from '../../signupSlice'
import { ControlButtons } from './ControlButtons'

export const CompanyAddressForm: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { registration } = useSelector((state: RootState) => state.signup)
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: registration,
  })
  const [registrationAddress, setRegistrationAddress] = useState(
    registration?.merchant?.registrationAddress
  )

  const onSubmit = (data: any) => {
    dispatch(
      signupActions.nextStep({
        ...registration,
        ...data,
        merchant: { ...registration?.merchant, ...data.merchant },
        terminal: { ...registration?.terminal, ...data.terminal },
      })
    )
  }

  return (
    <>
      <div className="mb-8">{t('Mi a vállalkozás hivatalos címe?')}</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ActionButton
          onClick={() => {
            setValue(
              'merchant.registrationAddress.countryCode',
              registration?.person?.address?.countryCode
            )
            setValue(
              'merchant.registrationAddress.zipCode',
              registration?.person?.address?.zipCode
            )
            setValue(
              'merchant.registrationAddress.city',
              registration?.person?.address?.city
            )
            setValue(
              'merchant.registrationAddress.street',
              registration?.person?.address?.street
            )

            setRegistrationAddress(registration?.person?.address)
          }}
        >
          {t('Ugyanaz, mint a lakcímem')}
        </ActionButton>

        <ListDropdown
          name="merchant.registrationAddress.countryCode"
          list={countries}
          placeholder={t('Ország')}
          register={register}
          onChange={(e) =>
            setRegistrationAddress({
              ...registrationAddress,
              countryCode: e.target.value,
            })
          }
        />

        <div className="flex">
          <div className="w-1/3">
            <input
              name="merchant.registrationAddress.zipCode"
              type="text"
              required
              maxLength={20}
              placeholder={t('Irsz.')}
              ref={register()}
              onChange={(e) =>
                setRegistrationAddress({
                  ...registrationAddress,
                  zipCode: e.target.value,
                })
              }
            />
          </div>

          <div className="w-2/3 ml-4">
            <input
              name="merchant.registrationAddress.city"
              type="text"
              required
              maxLength={50}
              placeholder={t('Város')}
              ref={register()}
              className="w-2/3"
              onChange={(e) =>
                setRegistrationAddress({
                  ...registrationAddress,
                  city: e.target.value,
                })
              }
            />
          </div>
        </div>

        <input
          name="merchant.registrationAddress.street"
          type="text"
          required
          maxLength={50}
          placeholder={t('Utca - Házszám - Emelet - Ajtó')}
          ref={register()}
          onChange={(e) =>
            setRegistrationAddress({
              ...registrationAddress,
              street: e.target.value,
            })
          }
        />

        <label className="block mt-8 font-semibold">
          {t('Kérlek, add meg azt a címet, ahol a tranzakciók történnek majd (ez is rákerül majd a fizetési bizonylatokra)')}
        </label>

        <ActionButton
          onClick={() => {
            setValue(
              'terminal.operationAddress.countryCode',
              registration?.person?.address?.countryCode
            )
            setValue(
              'terminal.operationAddress.zipCode',
              registration?.person?.address?.zipCode
            )
            setValue(
              'terminal.operationAddress.city',
              registration?.person?.address?.city
            )
            setValue(
              'terminal.operationAddress.street',
              registration?.person?.address?.street
            )
          }}
        >
          {t('Ugyanaz, mint a lakcímem')}
        </ActionButton>

        <ActionButton
          onClick={() => {
            setValue(
              'terminal.operationAddress.countryCode',
              registrationAddress?.countryCode
            )
            setValue(
              'terminal.operationAddress.zipCode',
              registrationAddress?.zipCode
            )
            setValue(
              'terminal.operationAddress.city',
              registrationAddress?.city
            )
            setValue(
              'terminal.operationAddress.street',
              registrationAddress?.street
            )
          }}
        >
          {t('Ugyanaz, mint a vállalkozás címe')}
        </ActionButton>

        <ListDropdown
          name="terminal.operationAddress.countryCode"
          list={countries}
          placeholder={t('Ország')}
          register={register}
        />

        <AddressSchemeDisplay
          context="terminal.operationAddress"
          register={register}
        />
         
        <ControlButtons />
      </form>
    </>
  )
}
