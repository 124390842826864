import { RootState } from 'app/store'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { CookieBanner } from './CookieBanner'
import { Footer } from './Footer'
import { Header } from './Header'
import { Sidebar } from './Sidebar'

export const Layout: FC = ({ children }) => {
  const { sidebarOpen } = useSelector((state: RootState) => state.menu)

  return (
    <div id="top" className="flex">
      <div className={`w-full ${sidebarOpen ? 'bg-black' : ''}`}>
        <div
          className={`w-full relative bg-white transition-opacity ${
            sidebarOpen ? 'opacity-75' : 'opacity-100'
          }`}
        >
          <div
            className="w-full h-full absolute z-40 hidden"
            style={{ background: 'rgba(8, 22, 43, 0.2)' }}
          ></div>

          <Header />

          <main>{children}</main>

          <Footer />
        </div>
      </div>

      <Sidebar />

      <CookieBanner />
    </div>
  )
}
