import { DropdownData } from 'models/DropdownData'

export const projectedMonthlySalesVolumeList: DropdownData[] = [
  { value: '250000', text: '0-500 eFt', i18nKey: 'merchantSurvey.monthlySales.volume1' },
  { value: '750000', text: '500 eFt-1 MFt', i18nKey: 'merchantSurvey.monthlySales.volume2' },
  { value: '3000000', text: '1-5 MFt', i18nKey: 'merchantSurvey.monthlySales.volume3' },
  { value: '7500000', text: '5-10 MFt', i18nKey: 'merchantSurvey.monthlySales.volume4' },
  { value: '10000000', text: '10 MFt felett', i18nKey: 'merchantSurvey.monthlySales.volume5' },
]

export const projectedDailyTransactionCountList: DropdownData[] = [
  { value: '2,5', text: 'Kevesebb, mint 5', i18nKey: 'merchantSurvey.dailyTransaction.volume1' },
  { value: '12,5', text: '5-20' },
  { value: '35', text: '20-50' },
  { value: '75', text: '50-100' },
  { value: '100', text: '100 felett', i18nKey: 'merchantSurvey.dailyTransaction.volume5' },
]

export const highestTransactionAmountList: DropdownData[] = [
  { value: '5000', text: '0-10 eFt', i18nKey: 'merchantSurvey.transactionAmount.volume1' },
  { value: '17500', text: '10-25 eFt', i18nKey: 'merchantSurvey.transactionAmount.volume2' },
  { value: '62500', text: '25-100 eFt', i18nKey: 'merchantSurvey.transactionAmount.volume3' },
  { value: '150000', text: '100-200 eFt', i18nKey: 'merchantSurvey.transactionAmount.volume4' },
  { value: '200000', text: '200 eFt felett', i18nKey: 'merchantSurvey.transactionAmount.volume5' },
]

export const averageTransactionAmountList: DropdownData[] = [
  { value: '5000', text: '0-10 eFt', i18nKey: 'merchantSurvey.transactionAmount.volume1' },
  { value: '17500', text: '10-25 eFt', i18nKey: 'merchantSurvey.transactionAmount.volume2' },
  { value: '62500', text: '25-100 eFt', i18nKey: 'merchantSurvey.transactionAmount.volume3' },
  { value: '150000', text: '100-200 eFt', i18nKey: 'merchantSurvey.transactionAmount.volume4' },
  { value: '200000', text: '200 eFt felett', i18nKey: 'merchantSurvey.transactionAmount.volume5' },
]

export const minimumTransactionAmountList: DropdownData[] = [
  { value: '5000', text: '0-10 eFt', i18nKey: 'merchantSurvey.transactionAmount.volume1' },
  { value: '17500', text: '10-25 eFt', i18nKey: 'merchantSurvey.transactionAmount.volume2' },
  { value: '62500', text: '25-100 eFt', i18nKey: 'merchantSurvey.transactionAmount.volume3' },
  { value: '150000', text: '100-200 eFt', i18nKey: 'merchantSurvey.transactionAmount.volume4' },
  { value: '200000', text: '200 eFt felett', i18nKey: 'merchantSurvey.transactionAmount.volume5' },
]
