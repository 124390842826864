import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Role } from 'api/generated/models'
import { Language } from 'app/i18n'
import { RootState } from 'app/store'
import { NavigationLink } from 'components/NavigationLink'
import { menuActions } from 'features/menu/menuSlice'
import { useTranslation } from 'react-i18next'
import { Logout } from './Logout'
import { PrivateOnlyDisplay } from './PrivateOnlyDisplay'
import { ProfilePicture } from './ProfilePicture'
import { PublicOnlyDisplay } from './PublicOnlyDisplay'
import { UserNameDisplay } from './UserNameDisplay'
import { localeActions } from 'features/locale/localeSlice'
import styles from './sidebar.module.css'

export const Sidebar: FC = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const { sidebarOpen, onPrivateRoute } = useSelector((state: RootState) => state.menu)
  const { merchantDetails } = useSelector((state: RootState) => state.merchant)
  const merchantId = useMemo(() => merchantDetails?.id, [merchantDetails])

  const handleMenuClose = () => {
    dispatch(menuActions.setSidebarOpen(false))
  }

  const handleLanguageChange = (lang: Language) => {
    dispatch(localeActions.setLocale(lang))
  }

  return (
    <div
      className={classNames(styles.sidebar, 'text-sm transition-all duration-300 relative', {
        'private-sidebar': onPrivateRoute,
        'bg-navy': onPrivateRoute,
        'bg-white': !onPrivateRoute,
        'w-1/4 border-secondary border-l-2': sidebarOpen,
        'w-0 invisible': !sidebarOpen
      })}
    >
      <div
        className={`${
          onPrivateRoute ? 'bg-navy text-secondary' : 'bg-white'
        } fixed top-0 right-0 bottom-0 left-0 z-50 md:sticky md:overflow-x-hidden`}
      >
        <div className="w-full h-full border-l-2 border-secondary fixed -z-10 invisible"></div>
        <div className="w-full max-h-full flex flex-col p-10 overflow-y-auto">
          <div className="ml-auto mb-4">
            <button onClick={handleMenuClose}>
              <svg
                className={`fill-current ${
                  onPrivateRoute ? 'text-secondary' : 'text-gray-darkest'
                }`}
                width="18"
                height="18"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="29.8789"
                  height="1.99192"
                  rx="0.995962"
                  transform="matrix(-0.709974 0.704228 0.709974 0.704228 21.2134 0.506348)"
                ></rect>
                <rect
                  width="29.8789"
                  height="1.99192"
                  rx="0.995962"
                  transform="matrix(0.709974 0.704228 0.709974 -0.704228 0 1.90915)"
                ></rect>
              </svg>
            </button>
          </div>
          <div className={classNames('flex flex-col', {
            'items-start': !merchantDetails,
            'items-end': merchantDetails
          })}>
            <PrivateOnlyDisplay>
              <div className="sm:hidden mb-6 flex items-center text-lg">
                <div className="mr-3">
                  <UserNameDisplay />
                </div>

                <ProfilePicture />
              </div>
            </PrivateOnlyDisplay>

            <PublicOnlyDisplay>
              <NavigationLink to="/#why-us" className="md:hidden">
                {t('sidebar.offer', 'Amit kínálunk')}
              </NavigationLink>

              <NavigationLink to="/#usecases" className="md:hidden">
                {t('sidebar.who', 'Kiknek ajánljuk?')}
              </NavigationLink>

              <NavigationLink to="/pricing" className="md:hidden">
                {t('sidebar.price', 'Áraink')}
              </NavigationLink>

              <NavigationLink to="/#about-us" className="md:hidden">
                {t('sidebar.aboutUs', 'Rólunk')}
              </NavigationLink>

              <NavigationLink to="/faq">
                {t('sidebar.faq', 'GYIK')}
              </NavigationLink>

              <NavigationLink to="/contact">
                {t('sidebar.contact', 'Kapcsolat')}
              </NavigationLink>

              <NavigationLink
                to="/login"
                className="btn-login mt-4 mb-6 md:hidden"
              >
                {t('common.login', 'Belépés')}
              </NavigationLink>

              <NavigationLink to="/signup" className="btn-secondary md:hidden">
                {t('common.register', 'Regisztráció')}
              </NavigationLink>
            </PublicOnlyDisplay>

            <PrivateOnlyDisplay roles={[Role.Admin]}>
              <NavigationLink to="/clients" className={styles.clientsLink}>
                {t('sidebar.clients', 'Kereskedők')}
              </NavigationLink>


              {merchantId && (
                <>
                  <div className="w-2/3 h-1 border-b border-secondary" />

                  <NavigationLink to={`/merchant/${merchantId}/details`}>
                    {t('sidebar.details', 'Adatok')}
                  </NavigationLink>
                </>
              )}
            </PrivateOnlyDisplay>

            <PrivateOnlyDisplay roles={[Role.Admin, Role.Basic]}>
              {merchantId && (
                <>
                  <NavigationLink to={`/merchant/${merchantId}/statistics`}>
                    {t('sidebar.statistics', 'Statisztika')}
                  </NavigationLink>

                  <NavigationLink to={`/merchant/${merchantId}/transactions`}>
                    {t('sidebar.transactions', 'Tranzakciók')}
                  </NavigationLink>

                  <NavigationLink to={`/merchant/${merchantId}/online-transactions`}>
                    {t('header.online-transactions', 'Online tranzakciók')}
                  </NavigationLink>

                  <NavigationLink to={`/merchant/${merchantId}/reports`}>
                    {t('sidebar.reports', 'Riportok')}
                  </NavigationLink>
                </>
              )}
            </PrivateOnlyDisplay>

            <PrivateOnlyDisplay roles={[Role.Basic]}>
              <NavigationLink to={`/merchant/${merchantId}/settings`}>
                {t('sidebar.settings', 'Beállítások')}
              </NavigationLink>
            </PrivateOnlyDisplay>

            <div className="mt-2 mb-6 md:hidden">
              <Logout />
            </div>

            <div className="w-20 flex items-center justify-between sm:hidden mt-5">
              <button
                onClick={() => handleLanguageChange(Language.Hu)}
                className={classNames(styles.langSelectLink, {
                  'text-gray-darkest': !onPrivateRoute,
                  'text-white': onPrivateRoute,
                  [styles.langSelectActive]: i18n.language === Language.Hu
                })}
              >
                HU
              </button>{' '}
              <button
                onClick={() => handleLanguageChange(Language.En)}
                className={classNames(styles.langSelectLink, {
                  'text-gray-darkest': !onPrivateRoute,
                  'text-white': onPrivateRoute,
                  [styles.langSelectActive]: i18n.language === Language.En
                })}
              >
                EN
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
