import { MerchantStatus, MerchantType, PaymentMethod, OrderStatus, TerminalType } from 'api/generated/models'
import i18n from 'app/i18n'

export const getEnumValues = (E: any, exclude: any[], first?: string): string[] => {
  return Object.keys(E)
    .filter((x) => !exclude.includes(x))
    .sort((a, b) => (first && b === first) ? 0 : -1)
}

export const translateEnum = (prefix: string, E?: any): string => {
  return E && i18n.t(`${prefix}.${E}`)
}

export const merchantTypes: string[] = getEnumValues(MerchantType, [
  MerchantType.Invalid,
])

export const paymentMethods: string[] = getEnumValues(PaymentMethod, [
  PaymentMethod.Invalid,
], PaymentMethod.BankCard)

export const merchantStatuses: string[] = getEnumValues(MerchantStatus, [
  MerchantStatus.Invalid,
])

export const orderStatuses: string[] = getEnumValues(OrderStatus, [
  OrderStatus.Invalid
])

export const terminalTypes: string[] = getEnumValues(TerminalType, [
  TerminalType.Invalid
])
