import { RootState } from 'app/store'
import { ErrorDisplay } from 'components/ErrorDisplay'
import { BooleanCheckBox } from 'components/form/BooleanCheckBox'
import { ListDropdown } from 'components/form/ListDropdown'
import { FeatureState } from 'models/FeatureState'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { gtagSendEvent } from 'services/analyticsService'
import {
  averageTransactionAmountList,
  highestTransactionAmountList,
  minimumTransactionAmountList,
  projectedDailyTransactionCountList,
  projectedMonthlySalesVolumeList,
} from 'services/merchantSurveyListService'
import { signupActions } from '../../signupSlice'
import { ControlButtons } from './ControlButtons'

export const MerchantSurveyForm: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { registration, error, featureState } = useSelector(
    (state: RootState) => state.signup
  )
  const { register, handleSubmit } = useForm({
    defaultValues: registration,
  })

  const onSubmit = (data: any) => {
    gtagSendEvent('DC-8549231/btnclick/hu_ma00+standard');

    if ((global as any).fbq) {
      (global as any).fbq("trackCustom", "Purchase");
    }

    dispatch(
      signupActions.finishRegistration({
        ...registration,
        ...data,
        person: { ...registration?.person, ...data.person },
      })
    )
  }

  return (
    <>
      <div className="mb-8">{t('Végül elérkeztünk a záró kérdésekhez!')}</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ListDropdown
          name="merchantSurvey.projectedMonthlySalesVolume"
          list={projectedMonthlySalesVolumeList}
          placeholder={t(
            'Havonta hozzávetőlegesen milyen összforgalomra számítasz?'
          )}
          register={register}
          className="h-16 whitespace-normal"
        />

        <ListDropdown
          name="merchantSurvey.projectedDailyTransactionCount"
          list={projectedDailyTransactionCountList}
          placeholder={t('Napi hány darab kártyás tranzakcióra számítasz?')}
          register={register}
          className="h-16 whitespace-normal"
        />

        <ListDropdown
          name="merchantSurvey.highestTransactionAmount"
          list={highestTransactionAmountList}
          placeholder={t('Mekkora lehet a legnagyobb várható vásárlás értéke?')}
          register={register}
          className="h-16 whitespace-normal"
        />

        <ListDropdown
          name="merchantSurvey.averageTransactionAmount"
          list={averageTransactionAmountList}
          placeholder={t('Mekkora egy tipikus vásárlás értéke?')}
          register={register}
          className="h-16 whitespace-normal"
        />

        <ListDropdown
          name="merchantSurvey.minimumTransactionAmount"
          list={minimumTransactionAmountList}
          placeholder={t('Mekkora lehet a legkisebb vásárlás értéke?')}
          register={register}
          className="h-16 whitespace-normal"
        />

        <textarea
          name="merchantSurvey.comment"
          maxLength={255}
          placeholder={t('Van még valami megjegyzésed számunkra?')}
          ref={register()}
          rows={6}
        />

        <div className="mt-8">
          <BooleanCheckBox
            name="person.isTacAccepted"
            required
            refToRegister={register()}
            trueText={
              <Trans i18nKey="person.tacAcceptance">
                Figyelmesen elolvastam és kifejezetten elfogadom az
                <a className="no-underline" href="/pdf/aszf.pdf" target="_blank">
                  Általános Szerződési Feltételeket
                </a>
                és az
                <a className="no-underline" href="/pdf/adatvedelmi_tajekoztato.pdf" target="_blank">
                  Adatvédelmi tájékoztatót.
                </a>
              </Trans>
            }
          />
        </div>

        <div className="mt-8">
          <BooleanCheckBox
            name="person.isMarketingAllowed"
            refToRegister={register()}
            trueText={
              <Trans i18nKey="person.marketingAcceptance">
                Hozzájárulok, hogy az Agora Pay kedvezményes ajánlataival és promócióival kapcsolatban megkeressen.
                <a className="font-normal no-underline" href="/pdf/adatvedelmi_tajekoztato.pdf" target="_blank">(Adatkezelési szabályzat)</a>
                <span className="text-sm">(Opcionális elem)</span>
              </Trans>
            }
          />
        </div>

        <ErrorDisplay
          error={error}
          show={featureState === FeatureState.Error}
        />

        { featureState === FeatureState.Error && (
          <p>{t('signup.merchantSurvey.stepBack')}</p>
        )}

        <ControlButtons />
      </form>
    </>
  )
}
