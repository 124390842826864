import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setDefaultLocale } from 'react-datepicker'
import dayjs from 'dayjs'
import i18n from 'i18next'

interface State {
  locale: string
}

const getInitialState = (): State => {
  const locale = localStorage.getItem('language') || 'hu'

  return {
    locale,
  }
}

const slice = createSlice({
  name: 'locale',
  initialState: getInitialState(),
  reducers: {
    setLocale(state, action: PayloadAction<string>) {
      dayjs.locale(action.payload)
      setDefaultLocale(action.payload)
      i18n.changeLanguage(action.payload)
      localStorage.setItem('language', action.payload)

      state.locale = action.payload
    }
  },
})

const {
  setLocale,
} = slice.actions

export const localeActions = {
  setLocale,
}

export const localeReducer = slice.reducer
