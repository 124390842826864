import { api } from 'api/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import { MerchantDetailsDto } from 'api/generated/models'
import { FeatureState } from 'models/FeatureState'

interface State {
  merchantState: FeatureState
  merchantDetails?: MerchantDetailsDto
}

const initialState: State = {
  merchantState: FeatureState.Init
}

const slice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    setMerchantState(state, action: PayloadAction<FeatureState>) {
      state.merchantState = action.payload
    },
    getMerchantDetailsSuccess(state, action: PayloadAction<MerchantDetailsDto>) {
      state.merchantDetails = action.payload
      state.merchantState = FeatureState.Success
    },
    clear(state) {
      state.merchantState = FeatureState.Init
      state.merchantDetails = undefined
    }
  }
})

const {
  setMerchantState,
  getMerchantDetailsSuccess,
  clear
} = slice.actions

const getMerchantDetails = (id: number): AppThunk => async dispatch => {
  dispatch(setMerchantState(FeatureState.Loading))

  try {
    const result = await api.merchant.merchantGet(id)
    dispatch(getMerchantDetailsSuccess(result.data))
  } catch (error) {
    dispatch(setMerchantState(FeatureState.Error))
  }
}

export const merchantActions = {
  getMerchantDetails,
  clear
}

export const merchantReducer = slice.reducer