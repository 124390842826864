import React from 'react'
import store from 'app/store'
import { App } from 'app/App'
import './app/i18n'
import './assets/css/global.css'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
