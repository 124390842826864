import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SocialIcons } from 'components/layout/SocialIcons'
import { NavigationLink } from 'components/NavigationLink'

const SignupFinished: FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <div
        className="w-full sm:hidden"
        style={{ height: '4px', backgroundColor: 'rgba(0, 231, 110, 0.2)' }}
      >
        <div className="h-full bg-secondary w-0"></div>
      </div>
    
      <div className="flex w-full mx-auto registration">
        <div className="hidden w-1/2 my-16 mx-8 sm:flex justify-end">
          <svg
            width="570"
            height="570"
            viewBox="0 0 570 570"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M285 570C127.854 570 0 442.146 0 285C0 127.854 127.854 0 285 0C442.146 0 570 127.854 570 285C570 442.146 442.146 570 285 570ZM285 43.3507C151.758 43.3507 43.3507 151.758 43.3507 285C43.3507 418.242 151.758 526.649 285 526.649C418.242 526.649 526.649 418.242 526.649 285C526.649 151.758 418.242 43.3507 285 43.3507Z"
              fill="#00E76E"
            />
            <path
              d="M247.558 399.756C242.016 399.756 236.473 397.65 232.231 393.408C223.777 384.955 223.777 371.206 232.231 362.753L418.422 176.562C426.875 168.108 440.624 168.108 449.077 176.562C457.531 185.015 457.531 198.763 449.077 207.217L262.886 393.408C258.644 397.65 253.101 399.756 247.558 399.756Z"
              fill="#00E76E"
            />
            <path
              d="M247.561 399.756C242.018 399.756 236.476 397.65 232.233 393.408L170.18 331.354C161.726 322.901 161.726 309.153 170.18 300.699C178.633 292.246 192.382 292.246 200.835 300.699L262.889 362.753C271.342 371.206 271.342 384.954 262.889 393.408C258.646 397.65 253.104 399.756 247.561 399.756Z"
              fill="#00E76E"
            />
          </svg>
        </div>

        <div className="w-full sm:w-1/2 px-6 py-8 sm:pb-32 mx-auto lg:ml-24">
          <div className="sm:max-w-md">
            <div className="text-secondary text-2xl sm:text-4xl font-medium">
              <div>{t('Kész is vagy!')}</div>
              <div>{t('Köszönjük, hogy az Agora Pay-t választottad!')}</div>
            </div>

            <p className="mt-8">
              {t(
                'A regisztrációról megerősítő email-t küldünk. Ha a megerősítő email pár percen belül nem érkezik meg hozzád, kérlek jelezd nekünk:'
              )}
              <br />
              <a
                className="text-secondary no-underline"
                href="mailto:info@agorapay.hu"
              >
                info@agorapay.hu
              </a>
            </p>

            <p className="mt-8 font-semibold">{t('Mi következik ezután?')}</p>

            <p>
              {t(
                'Hamarosan megkapod aláírásra a végső szerződéseket Paystra nevű partnerünktől, amiket online alá tudsz írni.'
              )}
            </p>

            <p>{t('Ezt követően postázzuk a készüléket.')}</p>

            <p>
              {t('Ha bármi kérdésed van, keress minket bátran:')}
              <NavigationLink
                to="/contact"
                className="block text-secondary no-underline"
              >
                agorapay.hu/contact
              </NavigationLink>
            </p>

            <div className="mt-8">
              <SocialIcons color="black" />
            </div>
          </div>
        </div>
      </div> 
    </>
  )
}

export default SignupFinished
