import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { api } from 'api/api'
import { AppThunk } from 'app/store'
import { TransactionStatsDto } from 'api/generated/models'
import { FeatureState } from 'models/FeatureState'

interface State {
  featureState: FeatureState
  statistics?: TransactionStatsDto
}

const initialState: State = {
  featureState: FeatureState.Init,
}

const slice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setFeatureState(state, action: PayloadAction<FeatureState>) {
      state.featureState = action.payload
    },
    getStatisticsSuccess(state, action: PayloadAction<TransactionStatsDto>) {
      state.statistics = action.payload
      state.featureState = FeatureState.Success
    },
  },
})

const { setFeatureState, getStatisticsSuccess } = slice.actions

const getStatistics = (
  merchantId: number,
  fromDate?: Date,
  toDate?: Date
): AppThunk => async (dispatch) => {
  dispatch(setFeatureState(FeatureState.Loading))

  try {
    const from = dayjs(fromDate).format('YYYY.MM.DD.')
    const to = dayjs(toDate).format('YYYY.MM.DD.')
    const result = await api.statistics.statisticsGet(merchantId, from, to)

    dispatch(getStatisticsSuccess(result.data))
  } catch (error) {
    dispatch(setFeatureState(FeatureState.Error))
  }
}


export const statisticsActions = { getStatistics }
export const statisticsReducer = slice.reducer
