import React from 'react'
import { RouterView, history } from 'router'
import { ConnectedRouter } from 'connected-react-router'

export const App = () => {
  return (
    <>
      <ConnectedRouter history={history}>
        <RouterView />
      </ConnectedRouter>
    </>
  )
}
