import { MerchantType } from 'api/generated/models'
import { RootState } from 'app/store'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

export const HiddenForMerchantTypePerson: FC = ({ children }) => {
  const { registration } = useSelector((state: RootState) => state.signup)

  return (
    <>
      {registration?.merchant?.type === MerchantType.Person ? (
        <></>
      ) : (
        <>{children}</>
      )}
    </>
  )
}
