import { Role } from 'api/generated/models'
import { RootState } from 'app/store'
import { Layout } from 'components/layout/Layout'
import { menuActions } from 'features/menu/menuSlice'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

interface PrivateRouteProps extends RouteProps {
  roles?: Role[]
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ roles, ...rest }) => {
  const dispatch = useDispatch()
  const { loggedIn, user } = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    dispatch(menuActions.setOnPrivateRoute(true))
  }, [dispatch])

  
  if (!loggedIn || (roles && (!user?.role || !roles.includes(user.role))))
    return <Redirect to={{ pathname: '/login' }} />

  return (
    <Layout>
      <Route {...rest} />
    </Layout>
  )
}
