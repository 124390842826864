import React, { FC, useMemo } from 'react'
import classNames from 'classnames'
import { RootState } from 'app/store'
import { NavigationLink } from 'components/NavigationLink'
import { menuActions } from 'features/menu/menuSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Logout } from './Logout'
import { PrivateOnlyDisplay } from './PrivateOnlyDisplay'
import { ProfilePicture } from './ProfilePicture'
import { PublicOnlyDisplay } from './PublicOnlyDisplay'
import { useTranslation } from 'react-i18next'
import { Language } from 'app/i18n'
import { Role } from 'api/generated/models'
import { UserNameDisplay } from './UserNameDisplay'
import { localeActions } from 'features/locale/localeSlice'
import { gtagSendEvent } from 'services/analyticsService'

export const Header: FC = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const { user } = useSelector((state: RootState) => state.auth)
  const { sidebarOpen, onPrivateRoute } = useSelector(
    (state: RootState) => state.menu
  )

  const handleMenuClick = () => {
    dispatch(menuActions.setSidebarOpen(!sidebarOpen))
  }

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(localeActions.setLocale(e.target.value))
  }

  const handlePricingClick = () => {
    gtagSendEvent('DC-8549231/btnclick/hu_ma001+standard')
  }

  const HeaderLogo = useMemo(() => {
    if (onPrivateRoute) {
      return (
        <img
          src="/img/a-pay-horizontal-long-green.svg"
          alt="Agora Pay logo"
          className="h-10"
          style={{ minWidth: '150px', height: '2.5rem' }}
        />
      )
    }

    return (
      <NavigationLink to="/#top">
        <img
          src="/img/a-pay-horizontal-long-navy.svg"
          alt="Agora Pay logo"
          className="h-10"
          style={{ minWidth: '150px', height: '2.5rem' }}
        />
      </NavigationLink>
    )
  }, [onPrivateRoute])

  return (
    <>
      <div
        className={`${
          onPrivateRoute ? 'bg-navy text-secondary' : 'bg-white'
        } top-0 z-20`}
      >
        <header
          className="flex justify-between items-center p-4 sm:p-8 text-sm"
          role="navigation"
        >

          {HeaderLogo}

          <nav>
            <div className="px-2 pt-2 pb-4 flex p-0 items-center nav-items">
              <PublicOnlyDisplay>
                <NavigationLink to="/#why-us" className="nav-text flex-shrink-0">
                  {t('header.offer')}
                </NavigationLink>
              </PublicOnlyDisplay>

              <PublicOnlyDisplay>
                <NavigationLink
                  to="/#usecases"
                  className="nav-text flex-shrink-0"
                >
                  {t('header.who', 'Kiknek ajánljuk?')}
                </NavigationLink>
              </PublicOnlyDisplay>

              <PublicOnlyDisplay>
                <NavigationLink to="/#pricing" className="nav-text" onClick={handlePricingClick}>
                  {t('header.price', 'Áraink')}
                </NavigationLink>
              </PublicOnlyDisplay>

              <PublicOnlyDisplay>
                <NavigationLink
                  to="/#about-us"
                  className="nav-text whitespace-no-wrap"
                >
                  {t('header.aboutUs', 'Rólunk')}
                </NavigationLink>
              </PublicOnlyDisplay>

              <PublicOnlyDisplay>
                <NavigationLink to="/login" className="btn-login">
                  {t('common.login', 'Belépés')}
                </NavigationLink>

                <NavigationLink to="/signup" className="btn-secondary">
                  {t('common.register', 'Regisztráció')}
                </NavigationLink>
              </PublicOnlyDisplay>

              <PrivateOnlyDisplay roles={[Role.Admin]}>
                <div className="hidden md:flex items-center sm:mr-3">
                  <NavigationLink to="/clients" className="no-underline">
                    {t('header.merchants', 'Kereskedők')}
                  </NavigationLink>

                  <span className="border-l w-px h-10 mx-4 border-gray-lightest" />
                </div>
              </PrivateOnlyDisplay>

              <PrivateOnlyDisplay>
                <div className="hidden sm:block sm:mr-3 text-secondary">
                  <UserNameDisplay />
                </div>

                <ProfilePicture />
              </PrivateOnlyDisplay>

              <Logout />

              <select
                value={i18n.language}
                onChange={handleLanguageChange}
                className={`language-select hidden sm:block ml-6 border-none px-0 cursor-pointer focus:outline-none ${
                  onPrivateRoute ? 'bg-navy text-white' : ''
                }`}
              >
                <option value={Language.Hu}>HU</option>
                <option value={Language.En}>EN</option>
              </select>

              <div
                onClick={handleMenuClick}
                className={classNames('ml-6 cursor-pointer',{
                  'hidden': sidebarOpen,
                  'md:hidden': user
                })}
              >
                <svg
                  className={`fill-current ${
                    onPrivateRoute ? 'text-secondary' : 'text-gray-darkest'
                  }`}
                  style={{ minWidth: '32px', maxWidth: '32px' }}
                  viewBox="0 0 36 34"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="36"
                    height="2"
                    rx="1"
                    transform="matrix(-1 0 0 1 36 0)"
                  />
                  <rect
                    width="30"
                    height="2"
                    rx="1"
                    transform="matrix(-1 0 0 1 30 8)"
                  />
                  <rect
                    width="30"
                    height="2"
                    rx="1"
                    transform="matrix(-1 0 0 1 30 24)"
                  />
                  <rect
                    width="24"
                    height="2"
                    rx="1"
                    transform="matrix(-1 0 0 1 24 16)"
                  />
                  <rect
                    width="36"
                    height="2"
                    rx="1"
                    transform="matrix(-1 0 0 1 36 32)"
                  />
                </svg>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </>
  )
}
