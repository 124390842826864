import { User } from 'models/User'

const userStoreName = 'user'

const parseToken = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')

  return JSON.parse(window.atob(base64))
}

const setUser = (user?: User): void => {
  localStorage.setItem(userStoreName, JSON.stringify(user))
}

const getUser = (): User | undefined => {
  const user = localStorage.getItem(userStoreName)
  return user ? JSON.parse(user) : undefined
}

const removeUser = (): void => localStorage.removeItem(userStoreName)

const setUserDataFromJwt = (user: User): User => {
  let userToSave: User = { ...user }
  if (userToSave?.accessToken) {
    // Correcting precision and parsing to number.
    userToSave.jwtExpiration = +`${parseToken(userToSave?.accessToken).exp}000`
  }

  return userToSave
}

export const userService = {
  setUser,
  getUser,
  removeUser,
  setUserDataFromJwt,
}
