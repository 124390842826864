import React from 'react'
import { FormEvent, forwardRef } from 'react'
import { chunkArray } from 'utils/helpers'

interface BankAccountInputProps {
  id?: string
  name?: string
  required?: boolean
  placeholder?: string
}

const BankAccountInput = forwardRef<HTMLInputElement, BankAccountInputProps>(({ ...props }, ref) => {
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    target.value = target.value.replace(/[^0-9-]/g, '')
  }

  const handleBlur = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    const numerics = target.value.replace(/[^0-9]/g, '').split('')
    const formatted = chunkArray<string>(numerics, 8).map(chunk => chunk.join(''))

    target.value = formatted.slice(0,3).join('-')
  }

  return (
    <input
      type="text"
      ref={ref}
      onChangeCapture={handleChange}
      onBlur={handleBlur}
      maxLength={50}
      pattern="^[0-9]{8}-[0-9]{8}(-[0-9]{8})?$"
      {...props}
    />
  )
})

export default BankAccountInput
