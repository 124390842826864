import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

interface BooleanCheckBoxProps {
  name: string
  title?: string
  refToRegister: any
  trueText?: JSX.Element
  required?: boolean
  disabled?: boolean
  onChange?: Function
}

export const BooleanCheckBox: FC<BooleanCheckBoxProps> = ({
  name,
  title,
  refToRegister,
  trueText,
  required,
  disabled,
  onChange
}) => {
  const { t } = useTranslation();
  return (
    <>
      {title && (
        <label htmlFor={name} className="block mt-8 font-semibold">
          {title}
        </label>
      )}
      <label htmlFor={name} className="flex">
        <span>
          <input
            name={name}
            id={name}
            type="checkbox"
            required={required}
            ref={refToRegister}
            disabled={disabled}
            onChange={e => onChange && onChange(e.target.checked)}
          />
        </span>
        <div className={classNames('ml-2', {
          'text-gray-light': disabled
        })}>
          {trueText ? trueText : t('common.yes', 'Igen')}
        </div>
      </label>
    </>
  )
}
