// tslint:disable
/**
 * AgoraPay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LoginCommand } from '../models';
// @ts-ignore
import { MobileLoginCommand } from '../models';
// @ts-ignore
import { MobileLoginUserDto } from '../models';
// @ts-ignore
import { MobileRefreshTokenCommand } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { RefreshTokenCommand } from '../models';
// @ts-ignore
import { UserLoginDto } from '../models';
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginCommand} loginCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogin: async (loginCommand: LoginCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginCommand' is not null or undefined
            if (loginCommand === null || loginCommand === undefined) {
                throw new RequiredError('loginCommand','Required parameter loginCommand was null or undefined when calling authenticationLogin.');
            }
            const localVarPath = `/api/v1/Authentication/Login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loginCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginCommand !== undefined ? loginCommand : {}) : (loginCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MobileLoginCommand} mobileLoginCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationMobileLogin: async (mobileLoginCommand: MobileLoginCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mobileLoginCommand' is not null or undefined
            if (mobileLoginCommand === null || mobileLoginCommand === undefined) {
                throw new RequiredError('mobileLoginCommand','Required parameter mobileLoginCommand was null or undefined when calling authenticationMobileLogin.');
            }
            const localVarPath = `/api/Mobile/v1/Authentication/Login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof mobileLoginCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(mobileLoginCommand !== undefined ? mobileLoginCommand : {}) : (mobileLoginCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MobileRefreshTokenCommand} mobileRefreshTokenCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationMobileRefreshToken: async (mobileRefreshTokenCommand: MobileRefreshTokenCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mobileRefreshTokenCommand' is not null or undefined
            if (mobileRefreshTokenCommand === null || mobileRefreshTokenCommand === undefined) {
                throw new RequiredError('mobileRefreshTokenCommand','Required parameter mobileRefreshTokenCommand was null or undefined when calling authenticationMobileRefreshToken.');
            }
            const localVarPath = `/api/Mobile/v1/Authentication/RefreshToken`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof mobileRefreshTokenCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(mobileRefreshTokenCommand !== undefined ? mobileRefreshTokenCommand : {}) : (mobileRefreshTokenCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenCommand} refreshTokenCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRefreshToken: async (refreshTokenCommand: RefreshTokenCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenCommand' is not null or undefined
            if (refreshTokenCommand === null || refreshTokenCommand === undefined) {
                throw new RequiredError('refreshTokenCommand','Required parameter refreshTokenCommand was null or undefined when calling authenticationRefreshToken.');
            }
            const localVarPath = `/api/v1/Authentication/RefreshToken`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof refreshTokenCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(refreshTokenCommand !== undefined ? refreshTokenCommand : {}) : (refreshTokenCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginCommand} loginCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLogin(loginCommand: LoginCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginDto>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationLogin(loginCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {MobileLoginCommand} mobileLoginCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationMobileLogin(mobileLoginCommand: MobileLoginCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileLoginUserDto>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationMobileLogin(mobileLoginCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {MobileRefreshTokenCommand} mobileRefreshTokenCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationMobileRefreshToken(mobileRefreshTokenCommand: MobileRefreshTokenCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileLoginUserDto>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationMobileRefreshToken(mobileRefreshTokenCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RefreshTokenCommand} refreshTokenCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationRefreshToken(refreshTokenCommand: RefreshTokenCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginDto>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationRefreshToken(refreshTokenCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {LoginCommand} loginCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogin(loginCommand: LoginCommand, options?: any): AxiosPromise<UserLoginDto> {
            return AuthenticationApiFp(configuration).authenticationLogin(loginCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileLoginCommand} mobileLoginCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationMobileLogin(mobileLoginCommand: MobileLoginCommand, options?: any): AxiosPromise<MobileLoginUserDto> {
            return AuthenticationApiFp(configuration).authenticationMobileLogin(mobileLoginCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileRefreshTokenCommand} mobileRefreshTokenCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationMobileRefreshToken(mobileRefreshTokenCommand: MobileRefreshTokenCommand, options?: any): AxiosPromise<MobileLoginUserDto> {
            return AuthenticationApiFp(configuration).authenticationMobileRefreshToken(mobileRefreshTokenCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshTokenCommand} refreshTokenCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRefreshToken(refreshTokenCommand: RefreshTokenCommand, options?: any): AxiosPromise<UserLoginDto> {
            return AuthenticationApiFp(configuration).authenticationRefreshToken(refreshTokenCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {LoginCommand} loginCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationLogin(loginCommand: LoginCommand, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationLogin(loginCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileLoginCommand} mobileLoginCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationMobileLogin(mobileLoginCommand: MobileLoginCommand, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationMobileLogin(mobileLoginCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileRefreshTokenCommand} mobileRefreshTokenCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationMobileRefreshToken(mobileRefreshTokenCommand: MobileRefreshTokenCommand, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationMobileRefreshToken(mobileRefreshTokenCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshTokenCommand} refreshTokenCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationRefreshToken(refreshTokenCommand: RefreshTokenCommand, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationRefreshToken(refreshTokenCommand, options).then((request) => request(this.axios, this.basePath));
    }

}
