import { RootState } from 'app/store'
import { Layout } from 'components/layout/Layout'
import { menuActions } from 'features/menu/menuSlice'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

interface PublicRouteProps extends RouteProps {
  onlyForUnauthenticated?: boolean
}

export const PublicRoute: FC<PublicRouteProps> = ({
  onlyForUnauthenticated,
  ...rest
}) => {
  const dispatch = useDispatch()
  const { loggedIn } = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    dispatch(menuActions.setOnPrivateRoute(false))
  }, [dispatch])

  if (onlyForUnauthenticated && loggedIn)
    return <Redirect to={{ pathname: '/' }} />

  return (
    <Layout>
      <Route {...rest} />
    </Layout>
  )
}
