import axiosLib, { AxiosRequestConfig, AxiosResponse } from 'axios'
import store from 'app/store'
import { userService } from 'services/userService'
import { authActions } from 'features/auth/authSlice'
import { api } from './api'

const axios = axiosLib.create()

axios.interceptors.response.use(
  (resp: AxiosResponse) => resp,
  async (error) => {
    if (error.request?.status === 401) {
      const isRefreshRequest = error.request.responseURL.endsWith('/Authentication/RefreshToken')
      const user = userService.getUser()
      const exp = user?.jwtExpiration

      if (isRefreshRequest || !user?.accessToken || !exp || +exp >= new Date().getTime()) {
        return Promise.reject(error.response?.data)
      }

      try {
        const request: AxiosRequestConfig = error.config
        const resp = await api.authentication.authenticationRefreshToken({ refreshToken: user?.refreshToken })
        const userToSave = userService.setUserDataFromJwt(resp.data)

        request.headers.Authorization = `Bearer ${userToSave.accessToken}`
        userService.setUser(userToSave)
        
        return Promise.resolve(axios(request))
      } catch (e) {
        store.dispatch(authActions.logout())
      }
    }

    return Promise.reject(error.response?.data)
  }
)

export default axios
