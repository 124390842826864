import React, { FC, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

interface SeoProps {
  title?: string;
  description?: string;
  relativePathname?: string;
  article?: boolean;
  imageUrl?: string;
  imageAlt?: string;
}

export const Seo: FC<SeoProps> = ({
  title,
  description,
  relativePathname,
  article,
  imageUrl,
  imageAlt,
}) => {
  const { i18n } = useTranslation();

  const metaTitle =
    title ||
    i18n.t("seo.default", "Agora Pay - Az okos bankkártya elfogadó megoldás");
  const metaDescription =
    description ||
    "Az Agora Pay egy innovatív, okostelefon alapú bankkártya elfogadási megoldás, amelynek segítségével bárki, szinte bármikor és bárhol el tud fogadni bankkártyás fizetéseket. Csak az okostelefonodra (kisebb adatcsomaggal) lesz szükséged, az innovatív terminált és mobil applikációt mi adjuk.";
  const metaPathName = relativePathname || "";
  const metaImage = imageUrl || "https://www.agorapay.hu/seo/landing.png";
  const twitterUsername = "@AgoraPay";

  useLayoutEffect(() => {
    (window as any).ga?.("send", {
      hitType: "pageview",
      title: metaTitle,
      location: document.location.href,
      page: document.location.pathname,
    });
    (window as any).fbq?.("trackCustom", "PageViewAgoraPay");
  }, [metaTitle]);

  return (
    <Helmet>
      <html lang={i18n.language} />
      <title>{metaTitle}</title>
      <link rel="icon" href="/favicon.svg" />
      <meta name="author" content={"info@agorapay.hu"} />
      <meta name="description" content={metaDescription} />
      <meta name="image" content={metaImage} />
      <meta name="og:site_name" content={"Agorapay"} />
      <meta name="og:title" content={metaTitle} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:url" content={`https://www.agorapay.hu${metaPathName}`} />
      <meta name="og:type" content={article ? "article" : "website"} />
      <meta name="og:image" content={metaImage} />

      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />
      <meta name="twitter:image:alt" content={imageAlt || "Agorapay logo"} />
      <meta name="twitter:card" content={"summary_large_image"} />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:site" content={twitterUsername} />
    </Helmet>
  );
};
