import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface AddressSchemeDisplayProps {
  context: string
  register: any,
  readOnly?: boolean
}

const AddressSchemeDisplay: FC<AddressSchemeDisplayProps> = ({ context, register, readOnly }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex">
        <div className="w-1/3">
          <input
            name={`${context}.zipCode`}
            type="text"
            required
            maxLength={20}
            placeholder={t('common.label.address.zipCode.placeholder')}
            ref={register()}
            readOnly={readOnly}
          />
        </div>

        <div className="w-2/3 ml-4">
          <input
            name={`${context}.city`}
            type="text"
            required
            maxLength={50}
            placeholder={t('common.label.address.city.placeholder')}
            ref={register()}
            className="w-2/3"
            readOnly={readOnly}
          />
        </div>
      </div>

      <input
        name={`${context}.street`}
        type="text"
        required
        maxLength={50}
        placeholder={t('common.label.address.street.placeholder')}
        ref={register()}
        className="mt-4"
        readOnly={readOnly}
      />
    </>
  )
}

export default AddressSchemeDisplay
