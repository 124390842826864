import { MerchantType } from 'api/generated/models'
import { RootState } from 'app/store'
import { EnumDropdown } from 'components/form/EnumDropdown'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { merchantTypes } from 'services/enumService'
import { signupActions } from '../../signupSlice'
import { ControlButtons } from './ControlButtons'

export const CompanyBasicsForm: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { registration } = useSelector((state: RootState) => state.signup)
  const { register, handleSubmit, watch } = useForm({
    defaultValues: registration,
  })

  const watchMerchantType = watch('merchant.type', registration?.merchant?.type)

  const onSubmit = (data: any) => {
    dispatch(
      signupActions.nextStep({
        ...registration,
        ...data,
        merchant: { ...registration?.merchant, ...data.merchant },
      })
    )
  }

  return (
    <>
      <div className="mb-8">
        {t('Térjünk át a vállalkozásodat érintő alap információkhoz:')}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="merchant.type" className="block mt-8 font-semibold">
          {t('Egyéni vállalkozóként vagy cégként regisztrálsz?')}
        </label>
        <EnumDropdown
          name="merchant.type"
          list={merchantTypes}
          enumName="MerchantType"
          register={register}
        />
        {watchMerchantType === MerchantType.Company && (
          <div className="hint">
            {t('Ha cégként: Szükség lesz a céget érintő néhány adatra (tulajdonosok, vezetőség, stb.). Ha szükséges, kérjük készíts elő egy friss cégkivonatot.')}
          </div>
        )}

        <textarea
          name="merchant.description"
          required
          maxLength={255}
          placeholder={t('Röviden írd le kérlek mivel foglalkozik a vállalkozásod?')}
          ref={register()}
          rows={6}
        />

        <ControlButtons />
      </form>
    </>
  )
}
