// tslint:disable
/**
 * AgoraPay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CheckoutDto } from '../models';
// @ts-ignore
import { PaymentResultDto } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} checkoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentGetResult: async (checkoutId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkoutId' is not null or undefined
            if (checkoutId === null || checkoutId === undefined) {
                throw new RequiredError('checkoutId','Required parameter checkoutId was null or undefined when calling paymentGetResult.');
            }
            const localVarPath = `/api/v1/Payment/{CheckoutId}/Result`
                .replace(`{${"CheckoutId"}}`, encodeURIComponent(String(checkoutId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentStartPayment: async (referenceCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceCode' is not null or undefined
            if (referenceCode === null || referenceCode === undefined) {
                throw new RequiredError('referenceCode','Required parameter referenceCode was null or undefined when calling paymentStartPayment.');
            }
            const localVarPath = `/api/v1/TerminalOrder/{ReferenceCode}/Payment/Start`
                .replace(`{${"ReferenceCode"}}`, encodeURIComponent(String(referenceCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} checkoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentGetResult(checkoutId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentResultDto>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).paymentGetResult(checkoutId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentStartPayment(referenceCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckoutDto>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).paymentStartPayment(referenceCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} checkoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentGetResult(checkoutId: string, options?: any): AxiosPromise<PaymentResultDto> {
            return PaymentApiFp(configuration).paymentGetResult(checkoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentStartPayment(referenceCode: string, options?: any): AxiosPromise<CheckoutDto> {
            return PaymentApiFp(configuration).paymentStartPayment(referenceCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @param {string} checkoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentGetResult(checkoutId: string, options?: any) {
        return PaymentApiFp(this.configuration).paymentGetResult(checkoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentStartPayment(referenceCode: string, options?: any) {
        return PaymentApiFp(this.configuration).paymentStartPayment(referenceCode, options).then((request) => request(this.axios, this.basePath));
    }

}
