// tslint:disable
/**
 * AgoraPay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PagedResponseOfTransactionDto } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { SubmitTransactionCommand } from '../models';
// @ts-ignore
import { TransactionDetailsDto } from '../models';
// @ts-ignore
import { TransactionDto } from '../models';
/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} merchantId 
         * @param {number} [terminalId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionExport: async (merchantId: number, terminalId?: number, fromDate?: string, toDate?: string, searchFor?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            if (merchantId === null || merchantId === undefined) {
                throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling transactionExport.');
            }
            const localVarPath = `/api/v1/Merchant/{merchantId}/Transaction/Export`
                .replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (terminalId !== undefined) {
                localVarQueryParameter['terminalId'] = terminalId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }

            if (searchFor !== undefined) {
                localVarQueryParameter['searchFor'] = searchFor;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionGet: async (merchantId: number, id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            if (merchantId === null || merchantId === undefined) {
                throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling transactionGet.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transactionGet.');
            }
            const localVarPath = `/api/v1/Merchant/{merchantId}/Transaction/{id}`
                .replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [terminalId] 
         * @param {string} [searchFor] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionGetAll: async (merchantId: number, fromDate?: string, toDate?: string, terminalId?: number, searchFor?: string, pageSize?: number, pageNumber?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            if (merchantId === null || merchantId === undefined) {
                throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling transactionGetAll.');
            }
            const localVarPath = `/api/v1/Merchant/{merchantId}/Transaction`
                .replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }

            if (terminalId !== undefined) {
                localVarQueryParameter['terminalId'] = terminalId;
            }

            if (searchFor !== undefined) {
                localVarQueryParameter['searchFor'] = searchFor;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} terminalId 
         * @param {string} [fromDateTime] 
         * @param {string} [toDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionGetByDateRange: async (terminalId: number, fromDateTime?: string, toDateTime?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'terminalId' is not null or undefined
            if (terminalId === null || terminalId === undefined) {
                throw new RequiredError('terminalId','Required parameter terminalId was null or undefined when calling transactionGetByDateRange.');
            }
            const localVarPath = `/api/Mobile/v1/Terminal/{terminalId}/Transaction`
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDateTime !== undefined) {
                localVarQueryParameter['FromDateTime'] = (fromDateTime as any instanceof Date) ?
                    (fromDateTime as any).toISOString() :
                    fromDateTime;
            }

            if (toDateTime !== undefined) {
                localVarQueryParameter['ToDateTime'] = (toDateTime as any instanceof Date) ?
                    (toDateTime as any).toISOString() :
                    toDateTime;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} terminalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionGetLast: async (terminalId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'terminalId' is not null or undefined
            if (terminalId === null || terminalId === undefined) {
                throw new RequiredError('terminalId','Required parameter terminalId was null or undefined when calling transactionGetLast.');
            }
            const localVarPath = `/api/Mobile/v1/Terminal/{terminalId}/Transaction/Last`
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubmitTransactionCommand} submitTransactionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionSubmit: async (submitTransactionCommand: SubmitTransactionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'submitTransactionCommand' is not null or undefined
            if (submitTransactionCommand === null || submitTransactionCommand === undefined) {
                throw new RequiredError('submitTransactionCommand','Required parameter submitTransactionCommand was null or undefined when calling transactionSubmit.');
            }
            const localVarPath = `/api/Mobile/v1/Transaction`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof submitTransactionCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(submitTransactionCommand !== undefined ? submitTransactionCommand : {}) : (submitTransactionCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eftTransactionId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionUploadSignature: async (eftTransactionId: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eftTransactionId' is not null or undefined
            if (eftTransactionId === null || eftTransactionId === undefined) {
                throw new RequiredError('eftTransactionId','Required parameter eftTransactionId was null or undefined when calling transactionUploadSignature.');
            }
            const localVarPath = `/api/Mobile/v1/Transaction/{eftTransactionId}/Signature`
                .replace(`{${"eftTransactionId"}}`, encodeURIComponent(String(eftTransactionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} merchantId 
         * @param {number} [terminalId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionExport(merchantId: number, terminalId?: number, fromDate?: string, toDate?: string, searchFor?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).transactionExport(merchantId, terminalId, fromDate, toDate, searchFor, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionGet(merchantId: number, id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionDetailsDto>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).transactionGet(merchantId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [terminalId] 
         * @param {string} [searchFor] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionGetAll(merchantId: number, fromDate?: string, toDate?: string, terminalId?: number, searchFor?: string, pageSize?: number, pageNumber?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseOfTransactionDto>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).transactionGetAll(merchantId, fromDate, toDate, terminalId, searchFor, pageSize, pageNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} terminalId 
         * @param {string} [fromDateTime] 
         * @param {string} [toDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionGetByDateRange(terminalId: number, fromDateTime?: string, toDateTime?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionDto>>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).transactionGetByDateRange(terminalId, fromDateTime, toDateTime, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} terminalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionGetLast(terminalId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionDetailsDto>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).transactionGetLast(terminalId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SubmitTransactionCommand} submitTransactionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionSubmit(submitTransactionCommand: SubmitTransactionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionDetailsDto>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).transactionSubmit(submitTransactionCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} eftTransactionId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionUploadSignature(eftTransactionId: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).transactionUploadSignature(eftTransactionId, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} merchantId 
         * @param {number} [terminalId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionExport(merchantId: number, terminalId?: number, fromDate?: string, toDate?: string, searchFor?: string, options?: any): AxiosPromise<any> {
            return TransactionApiFp(configuration).transactionExport(merchantId, terminalId, fromDate, toDate, searchFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionGet(merchantId: number, id: number, options?: any): AxiosPromise<TransactionDetailsDto> {
            return TransactionApiFp(configuration).transactionGet(merchantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [terminalId] 
         * @param {string} [searchFor] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionGetAll(merchantId: number, fromDate?: string, toDate?: string, terminalId?: number, searchFor?: string, pageSize?: number, pageNumber?: number, options?: any): AxiosPromise<PagedResponseOfTransactionDto> {
            return TransactionApiFp(configuration).transactionGetAll(merchantId, fromDate, toDate, terminalId, searchFor, pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} terminalId 
         * @param {string} [fromDateTime] 
         * @param {string} [toDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionGetByDateRange(terminalId: number, fromDateTime?: string, toDateTime?: string, options?: any): AxiosPromise<Array<TransactionDto>> {
            return TransactionApiFp(configuration).transactionGetByDateRange(terminalId, fromDateTime, toDateTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} terminalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionGetLast(terminalId: number, options?: any): AxiosPromise<TransactionDetailsDto> {
            return TransactionApiFp(configuration).transactionGetLast(terminalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubmitTransactionCommand} submitTransactionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionSubmit(submitTransactionCommand: SubmitTransactionCommand, options?: any): AxiosPromise<TransactionDetailsDto> {
            return TransactionApiFp(configuration).transactionSubmit(submitTransactionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eftTransactionId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionUploadSignature(eftTransactionId: string, file?: any, options?: any): AxiosPromise<void> {
            return TransactionApiFp(configuration).transactionUploadSignature(eftTransactionId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * 
     * @param {number} merchantId 
     * @param {number} [terminalId] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [searchFor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionExport(merchantId: number, terminalId?: number, fromDate?: string, toDate?: string, searchFor?: string, options?: any) {
        return TransactionApiFp(this.configuration).transactionExport(merchantId, terminalId, fromDate, toDate, searchFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} merchantId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionGet(merchantId: number, id: number, options?: any) {
        return TransactionApiFp(this.configuration).transactionGet(merchantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} merchantId 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {number} [terminalId] 
     * @param {string} [searchFor] 
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionGetAll(merchantId: number, fromDate?: string, toDate?: string, terminalId?: number, searchFor?: string, pageSize?: number, pageNumber?: number, options?: any) {
        return TransactionApiFp(this.configuration).transactionGetAll(merchantId, fromDate, toDate, terminalId, searchFor, pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} terminalId 
     * @param {string} [fromDateTime] 
     * @param {string} [toDateTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionGetByDateRange(terminalId: number, fromDateTime?: string, toDateTime?: string, options?: any) {
        return TransactionApiFp(this.configuration).transactionGetByDateRange(terminalId, fromDateTime, toDateTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} terminalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionGetLast(terminalId: number, options?: any) {
        return TransactionApiFp(this.configuration).transactionGetLast(terminalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubmitTransactionCommand} submitTransactionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionSubmit(submitTransactionCommand: SubmitTransactionCommand, options?: any) {
        return TransactionApiFp(this.configuration).transactionSubmit(submitTransactionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eftTransactionId 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionUploadSignature(eftTransactionId: string, file?: any, options?: any) {
        return TransactionApiFp(this.configuration).transactionUploadSignature(eftTransactionId, file, options).then((request) => request(this.axios, this.basePath));
    }

}
