import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { translateEnum } from 'services/enumService'

interface EnumDropdownProps {
  name: string
  id?: string
  list: string[]
  enumName: string
  register: any
}

export const EnumDropdown: FC<EnumDropdownProps> = ({
  name,
  id,
  list,
  enumName,
  register,
}) => {
  const { t } = useTranslation();
  return (
    <select id={id} name={name} required ref={register()} defaultValue="">
      <option value="" disabled hidden>
        {t('enumDropdown.choose', 'Válassz')}
      </option>
      {list.map((x) => (
        <option key={x} value={x}>
          {translateEnum(enumName, x)}
        </option>
      ))}
    </select>
  )
}
