import { Role } from 'api/generated/models'
import { RootState } from 'app/store'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

interface PrivateOnlyDisplayProps {
  roles?: Role[]
}

export const PrivateOnlyDisplay: FC<PrivateOnlyDisplayProps> = ({
  roles,
  children,
}) => {
  const { loggedIn, user } = useSelector((state: RootState) => state.auth)

  return (
    <>
      {loggedIn &&
        (roles ? user?.role && roles.includes(user.role) : true) &&
        children}
    </>
  )
}
