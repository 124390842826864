// tslint:disable
/**
 * AgoraPay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PagedResponseOfReportDto } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} merchantId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGetSettlementReport: async (merchantId: number, id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            if (merchantId === null || merchantId === undefined) {
                throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling reportGetSettlementReport.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportGetSettlementReport.');
            }
            const localVarPath = `/api/v1/Merchant/{merchantId}/Report/{id}`
                .replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGetSettlementReports: async (merchantId: number, from?: string, to?: string, pageNumber?: number, pageSize?: number, searchFor?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            if (merchantId === null || merchantId === undefined) {
                throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling reportGetSettlementReports.');
            }
            const localVarPath = `/api/v1/Merchant/{merchantId}/Report`
                .replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (searchFor !== undefined) {
                localVarQueryParameter['SearchFor'] = searchFor;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {string} [mid] 
         * @param {string} [type] 
         * @param {string} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportUploadMonthlyReport: async (body: string, mid?: string, type?: string, month?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling reportUploadMonthlyReport.');
            }
            const localVarPath = `/api/v1/Report/MonthlyReport`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (mid !== undefined) {
                localVarQueryParameter['mid'] = mid;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {string} [mid] 
         * @param {string} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportUploadSettlementReport: async (body: string, mid?: string, month?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling reportUploadSettlementReport.');
            }
            const localVarPath = `/api/v1/Report/Settlement`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (mid !== undefined) {
                localVarQueryParameter['mid'] = mid;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} merchantId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportGetSettlementReport(merchantId: number, id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ReportApiAxiosParamCreator(configuration).reportGetSettlementReport(merchantId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportGetSettlementReports(merchantId: number, from?: string, to?: string, pageNumber?: number, pageSize?: number, searchFor?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseOfReportDto>> {
            const localVarAxiosArgs = await ReportApiAxiosParamCreator(configuration).reportGetSettlementReports(merchantId, from, to, pageNumber, pageSize, searchFor, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {string} [mid] 
         * @param {string} [type] 
         * @param {string} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportUploadMonthlyReport(body: string, mid?: string, type?: string, month?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ReportApiAxiosParamCreator(configuration).reportUploadMonthlyReport(body, mid, type, month, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {string} [mid] 
         * @param {string} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportUploadSettlementReport(body: string, mid?: string, month?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ReportApiAxiosParamCreator(configuration).reportUploadSettlementReport(body, mid, month, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} merchantId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGetSettlementReport(merchantId: number, id: number, options?: any): AxiosPromise<any> {
            return ReportApiFp(configuration).reportGetSettlementReport(merchantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGetSettlementReports(merchantId: number, from?: string, to?: string, pageNumber?: number, pageSize?: number, searchFor?: string, options?: any): AxiosPromise<PagedResponseOfReportDto> {
            return ReportApiFp(configuration).reportGetSettlementReports(merchantId, from, to, pageNumber, pageSize, searchFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {string} [mid] 
         * @param {string} [type] 
         * @param {string} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportUploadMonthlyReport(body: string, mid?: string, type?: string, month?: string, options?: any): AxiosPromise<void> {
            return ReportApiFp(configuration).reportUploadMonthlyReport(body, mid, type, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {string} [mid] 
         * @param {string} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportUploadSettlementReport(body: string, mid?: string, month?: string, options?: any): AxiosPromise<void> {
            return ReportApiFp(configuration).reportUploadSettlementReport(body, mid, month, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @param {number} merchantId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportGetSettlementReport(merchantId: number, id: number, options?: any) {
        return ReportApiFp(this.configuration).reportGetSettlementReport(merchantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} merchantId 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [searchFor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportGetSettlementReports(merchantId: number, from?: string, to?: string, pageNumber?: number, pageSize?: number, searchFor?: string, options?: any) {
        return ReportApiFp(this.configuration).reportGetSettlementReports(merchantId, from, to, pageNumber, pageSize, searchFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {string} [mid] 
     * @param {string} [type] 
     * @param {string} [month] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportUploadMonthlyReport(body: string, mid?: string, type?: string, month?: string, options?: any) {
        return ReportApiFp(this.configuration).reportUploadMonthlyReport(body, mid, type, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {string} [mid] 
     * @param {string} [month] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportUploadSettlementReport(body: string, mid?: string, month?: string, options?: any) {
        return ReportApiFp(this.configuration).reportUploadSettlementReport(body, mid, month, options).then((request) => request(this.axios, this.basePath));
    }

}
