import { NavigationLink } from 'components/NavigationLink'
import React, { FC, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { cookieBannerService } from 'services/cookieBannerService'

export const CookieBanner: FC = () => {
  const { t } = useTranslation()

  const [visible, setVisible] = useState(
    cookieBannerService.getCookieBannerVisible()
  )

  const handleOk = (isOnlyNecessaryCookiePolicy: boolean) => {
    setVisible(false)
    cookieBannerService.setCookieBannerVisible(false)
    cookieBannerService.setIsOnlyNecessaryCookiePolicy(isOnlyNecessaryCookiePolicy)

    if (isOnlyNecessaryCookiePolicy) {
      document.cookie.split(";").forEach((c) => document.cookie = c.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/;domain=${window.location.hostname.replace(/^[^.]*/, '')}`));
    }

    window.location.reload()
  }

  return (
    <div className={`cookie-banner ${visible ? '' : 'hidden'}`}>
      <img
        src="/img/cookie.svg"
        alt="cookie"
        />

      <div className='flex flex-col sm:flex-row'>
        <div className='w-5/6'>
          <p className='font-semibold text-lg'>{t('cookieBanner.text1', 'A sütik segítenek nekünk, hogy jobbak legyünk. Elfogadod őket?')}</p>

          <p>
            <Trans i18nKey="cookieBanner.text2">
              Az optimális weboldal működéséhez és a személyre szabott élményhez sütiket használunk. Beállításaidat bármikor módosíthatod. A statisztikai sütik névtelenül gyűjtenek információkat, <strong>a személyes adataidat nem tároljuk</strong>.
            </Trans>
          </p>

          <p>
            <NavigationLink to="/privacyPolicy" className="w-full sm:w-auto">
              {t('cookieBanner.text3', 'Adatvédelmi tájékoztató')}
            </NavigationLink>
          </p>
        </div>

        <div className='flex flex-col justify-center'>
          <button onClick={() => handleOk(true)} className="btn-outlined whitespace-normal mb-4">
            {t('cookieBanner.acceptJustNecessary', 'Csak a szükségesek engedélyezése')}
          </button>

          <button onClick={() => handleOk(false)} className="btn-primary whitespace-normal">
            {t('cookieBanner.acceptAll', 'Összes engedélyezése')}
          </button>
        </div>
      </div>

    </div>
  )
}
