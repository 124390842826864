const cookieBannerVisibleName = 'cookieBannerVisible'
const isOnlyNecessaryCookiePolicyName = 'isOnlyNecessaryCookiePolicy'

const setCookieBannerVisible = (visible: boolean) => {
  localStorage.setItem(cookieBannerVisibleName, JSON.stringify(visible))
}

const getCookieBannerVisible = (): boolean => {
  return JSON.parse(localStorage.getItem(cookieBannerVisibleName) || 'true') || localStorage.getItem(isOnlyNecessaryCookiePolicyName) === null
}

const setIsOnlyNecessaryCookiePolicy = (isOnlyNecessaryCookiePolicy: boolean) => {
  localStorage.setItem(isOnlyNecessaryCookiePolicyName, JSON.stringify(isOnlyNecessaryCookiePolicy))
}

const getIsOnlyNecessaryCookiePolicy = (): boolean => {
  return JSON.parse(localStorage.getItem(isOnlyNecessaryCookiePolicyName) || 'true')
}

export const cookieBannerService = {
  setCookieBannerVisible,
  getCookieBannerVisible,
  setIsOnlyNecessaryCookiePolicy,
  getIsOnlyNecessaryCookiePolicy,
}
