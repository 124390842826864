import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from 'api/api'
import { AppThunk } from 'app/store'
import { FeatureState } from 'models/FeatureState'
import { Pagination } from 'models/Pagination'
import { Report } from 'models/Report'
import { downloadFile } from 'services/downloadService'

interface State {
  featureState: FeatureState
  pagination: Pagination
  reports?: Report[] | null
}

const initialState: State = {
  featureState: FeatureState.Init,
  pagination: {
    pageNumber: 1,
    pageSize: 10,
  },
}

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setFeatureState(state, action: PayloadAction<FeatureState>) {
      state.featureState = action.payload
    },
    setPagination(state, action: PayloadAction<Pagination>) {
      state.pagination = action.payload
    },
    getReportsSuccess(
      state,
      action: PayloadAction<{
        reports?: Report[] | null
        pagination: Pagination
      }>
    ) {
      state.pagination = action.payload.pagination
      state.reports = action.payload.reports
      state.featureState = FeatureState.Success
    },
  },
})

const { setFeatureState, setPagination, getReportsSuccess } = slice.actions

const getReports = (
  merchantId: number,
  pageNumber?: number,
  pageSize?: number,
  from?: string,
  to?: string
): AppThunk => async (dispatch) => {
  dispatch(setFeatureState(FeatureState.Loading))

  try {
    const result = await api.report.reportGetSettlementReports(merchantId, from, to, pageNumber, pageSize)
    const { data, ...pagination } = result.data

    dispatch(
      getReportsSuccess({
        reports: data,
        pagination,
      })
    )
  } catch (error) {
    dispatch(setFeatureState(FeatureState.Error))
  }
}

const downloadReport = (merchantId: number, id: number): AppThunk => async () => {
  const response = await api.report.reportGetSettlementReport(merchantId, id, { responseType: 'blob' })
  downloadFile(response)
}

export const reportsActions = { setPagination, getReports, downloadReport }

export const reportsReducer = slice.reducer
