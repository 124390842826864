import AddressSchemeDisplay from 'components/AddressSchemeDisplay'
import { ActionButton } from 'components/buttons/ActionButton'
import { BooleanCheckBox } from 'components/form/BooleanCheckBox'
import { ListDropdown } from 'components/form/ListDropdown'
import dayjs from 'dayjs'
import { Registration } from 'models/Registration'
import React, { FC } from 'react'
import DatePicker from 'react-datepicker'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { countries } from 'services/countryService'

interface CompanyPersonProps {
  number: number
  registration?: Registration
  register: any
  control: any
  setValue: any
  watch: any
  getValues: any
  errors?: any
}

export const CompanyPersonFields: FC<CompanyPersonProps> = ({
  number,
  registration,
  register,
  control,
  setValue,
  watch,
  getValues,
  errors
}) => {
  const { t, i18n } = useTranslation()
  const watchIsPep = watch(
    `persons[${number}].isPep`,
    getValues(`persons[${number}].isPep`)
  )
  const watchIsUltimateBeneficiaryOwner = watch(
    `persons[${number}].isUltimateBeneficiaryOwner`,
    getValues(`persons[${number}].isUltimateBeneficiaryOwner`)
  )

  return (
    <>
      <label className="block my-8 font-semibold">
        {t('signup.companyPerson.manager')} #{number + 1}
      </label>
      <input
        name={`persons[${number}].lastName`}
        type="text"
        required
        maxLength={100}
        placeholder={t('Vezetéknév')}
        ref={register()}
      />
      <input
        name={`persons[${number}].firstName`}
        type="text"
        required
        maxLength={100}
        placeholder={t('Keresztnév')}
        ref={register()}
      />
      <label
        htmlFor={`persons[${number}].dateOfBirth`}
        className="block mt-8 font-semibold"
      >
        {t('Születési dátum')}
      </label>
      <Controller
        control={control}
        name={`persons[${number}].dateOfBirth`}
        defaultValue=""
        rules={{
          validate: (date) => dayjs().subtract(18, 'year').isAfter(date),
        }}
        render={(props) => (
          <DatePicker
            {...props}
            locale={i18n.language}
            dateFormat="P"
            timeFormat="P"
            id={`persons[${number}].dateOfBirth`}
            required
            placeholderText={t('Dátum')}
            selected={props.value}
            showYearDropdown
          />
        )}
      />

      { errors.persons?.[number]?.dateOfBirth && (
        <p className="text-ml-4 pt-0 text-sm text-red">{t('validate.errors.18yearsatleast')}</p>
      )}

      <ListDropdown
        name={`persons[${number}].nationalityCode`}
        list={countries}
        placeholder={t('Állampolgárságod')}
        register={register}
      />

      <label className="block mt-8 font-semibold">{t('Lakcím')}</label>
      <ActionButton
        onClick={() => {
          setValue(
            `persons[${number}].address.countryCode`,
            registration?.person?.address?.countryCode
          )
          setValue(
            `persons[${number}].address.zipCode`,
            registration?.person?.address?.zipCode
          )
          setValue(
            `persons[${number}].address.city`,
            registration?.person?.address?.city
          )
          setValue(
            `persons[${number}].address.street`,
            registration?.person?.address?.street
          )
        }}
      >
        {t('Megegyezik a korábbi lakcímmel')}
      </ActionButton>

      <ActionButton
        onClick={() => {
          setValue(
            `persons[${number}].address.countryCode`,
            registration?.merchant?.registrationAddress?.countryCode
          )
          setValue(
            `persons[${number}].address.zipCode`,
            registration?.merchant?.registrationAddress?.zipCode
          )
          setValue(
            `persons[${number}].address.city`,
            registration?.merchant?.registrationAddress?.city
          )
          setValue(
            `persons[${number}].address.street`,
            registration?.merchant?.registrationAddress?.street
          )
        }}
      >
        {t('Megegyezik a vállalkozás címével')}
      </ActionButton>

      <ListDropdown
        name={`persons[${number}].address.countryCode`}
        list={countries}
        placeholder={t('Ország')}
        register={register}
      />

      <AddressSchemeDisplay
        context={`persons[${number}].address`}
        register={register}
      />

      <div className="my-4">
        <input
          name={`persons[${number}].email`}
          type="email"
          required
          maxLength={255}
          placeholder={t('E-mail cím')}
          ref={register()}
        />
      </div>

      <BooleanCheckBox
        name={`persons[${number}].isPep`}
        title={t('Kiemelt közszereplő?')}
        refToRegister={register()}
      />

      {watchIsPep && (
        <input
          name={`persons[${number}].pepDescription`}
          type="text"
          required
          maxLength={255}
          placeholder={t('Milyen közszerepet folytat?')}
          ref={register()}
        />
      )}

      <BooleanCheckBox
        name={`persons[${number}].isAuthorizedSignatory`}
        title={t('Aláírásra jogosult?')}
        refToRegister={register()}
      />

      <BooleanCheckBox
        name={`persons[${number}].isDirector`}
        title={t('signup.companyPerson.isManager')}
        refToRegister={register()}
      />

      <BooleanCheckBox
        name={`persons[${number}].isUltimateBeneficiaryOwner`}
        title={t('25%-ot meghaladó tulajdonos?')}
        refToRegister={register()}
      />

      {watchIsUltimateBeneficiaryOwner && (
        <input
          name={`persons[${number}].ownershipPercentage`}
          type="number"
          required
          min={25}
          max={100}
          placeholder={t('Tulajdonrész? (%)')}
          ref={register()}
        />
      )}
    </>
  )
}
