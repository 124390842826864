import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from 'api/api'
import { LoginCommand, Role } from 'api/generated/models'
import { AppThunk } from 'app/store'
import { push } from 'connected-react-router'
import { menuActions } from 'features/menu/menuSlice'
import { settingsActions } from 'features/merchant/components/settings/settingsSlice'
import { FeatureState } from 'models/FeatureState'
import { User } from 'models/User'
import { userService } from 'services/userService'

interface State {
  loggedIn: boolean
  featureState?: FeatureState
  error?: any
  user?: User
}

const getInitialState = (): State => {
  const user = userService.getUser()

  return {
    loggedIn: !!user,
    user,
  }
}

const slice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    reset: () => getInitialState(),
    setFeatureState(state, action: PayloadAction<FeatureState>) {
      state.featureState = action.payload
    },
    loginSuccess: (state, action: PayloadAction<User>) => {
      userService.setUser(action.payload)
      state.loggedIn = true
      state.user = action.payload
      state.featureState = FeatureState.Success
    },
    loginError: (state, action: PayloadAction<any>) => {
      state.error = action.payload
      state.featureState = FeatureState.Error
    },
    logoutSuccess: (state) => {
      userService.removeUser()
      state.loggedIn = false
      state.user = undefined
      state.featureState = FeatureState.Success
    },
  },
})

const {
  reset,
  setFeatureState,
  loginSuccess,
  loginError,
  logoutSuccess,
} = slice.actions

const login = (login: LoginCommand): AppThunk => async (dispatch) => {
  dispatch(setFeatureState(FeatureState.Loading))

  try {
    const resp = await api.authentication.authenticationLogin(login)

    dispatch(loginSuccess(userService.setUserDataFromJwt(resp.data)))
    dispatch(settingsActions.pictureGet())

    if (!resp.data.role) {
      dispatch(push('/'))
      return
    }

    if (resp.data.role === Role.Admin) {
      dispatch(push('/clients'))
    } else if ([Role.Basic].includes(resp.data.role)) {
      const merchantId = resp.data.merchantId
      dispatch(push(`/merchant/${merchantId}/statistics`))
    }
  } catch (error) {
    dispatch(loginError(error))
  }
}

const logout = (): AppThunk => async (dispatch) => {
  dispatch(setFeatureState(FeatureState.Loading))
  dispatch(logoutSuccess())
  dispatch(push('/'))
  dispatch(menuActions.setSidebarOpen(false))
  dispatch(reset())
  dispatch(settingsActions.reset())
}

export const authActions = {
  reset,
  login,
  logout,
}

export const authReducer = slice.reducer
