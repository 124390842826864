// tslint:disable
/**
 * AgoraPay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InitiateResetPasswordCommand } from '../models';
// @ts-ignore
import { ModifyPinCommand } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { RegistrationCommand } from '../models';
// @ts-ignore
import { ResendSalesforceFormCommand } from '../models';
// @ts-ignore
import { SetPasswordCommand } from '../models';
// @ts-ignore
import { TokenValidityDto } from '../models';
// @ts-ignore
import { UpdatePasswordCommand } from '../models';
// @ts-ignore
import { UpdatePreferencesCommand } from '../models';
// @ts-ignore
import { UpdateUserCommand } from '../models';
// @ts-ignore
import { UserDetailsDto } from '../models';
// @ts-ignore
import { UserDto } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeactivateUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/User/Deactivate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userGet.');
            }
            const localVarPath = `/api/v1/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/User`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [queryString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetBySearch: async (queryString?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/User/Search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (queryString !== undefined) {
                localVarQueryParameter['QueryString'] = queryString;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InitiateResetPasswordCommand} initiateResetPasswordCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInitiateResetPassword: async (initiateResetPasswordCommand: InitiateResetPasswordCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'initiateResetPasswordCommand' is not null or undefined
            if (initiateResetPasswordCommand === null || initiateResetPasswordCommand === undefined) {
                throw new RequiredError('initiateResetPasswordCommand','Required parameter initiateResetPasswordCommand was null or undefined when calling userInitiateResetPassword.');
            }
            const localVarPath = `/api/v1/User/InitiateResetPassword`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof initiateResetPasswordCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(initiateResetPasswordCommand !== undefined ? initiateResetPasswordCommand : {}) : (initiateResetPasswordCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsTokenValid: async (token?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/User/IsTokenValid`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyPinCommand} modifyPinCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userModifyPin: async (modifyPinCommand: ModifyPinCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'modifyPinCommand' is not null or undefined
            if (modifyPinCommand === null || modifyPinCommand === undefined) {
                throw new RequiredError('modifyPinCommand','Required parameter modifyPinCommand was null or undefined when calling userModifyPin.');
            }
            const localVarPath = `/api/v1/User/PinCode`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof modifyPinCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(modifyPinCommand !== undefined ? modifyPinCommand : {}) : (modifyPinCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegistrationCommand} registrationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegister: async (registrationCommand: RegistrationCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationCommand' is not null or undefined
            if (registrationCommand === null || registrationCommand === undefined) {
                throw new RequiredError('registrationCommand','Required parameter registrationCommand was null or undefined when calling userRegister.');
            }
            const localVarPath = `/api/v1/User`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof registrationCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(registrationCommand !== undefined ? registrationCommand : {}) : (registrationCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResendSalesforceFormCommand} resendSalesforceFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResendSalesforceForm: async (resendSalesforceFormCommand: ResendSalesforceFormCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendSalesforceFormCommand' is not null or undefined
            if (resendSalesforceFormCommand === null || resendSalesforceFormCommand === undefined) {
                throw new RequiredError('resendSalesforceFormCommand','Required parameter resendSalesforceFormCommand was null or undefined when calling userResendSalesforceForm.');
            }
            const localVarPath = `/api/v1/User/ResendSalesforceForm`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof resendSalesforceFormCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resendSalesforceFormCommand !== undefined ? resendSalesforceFormCommand : {}) : (resendSalesforceFormCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetPasswordCommand} setPasswordCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSetPassword: async (setPasswordCommand: SetPasswordCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'setPasswordCommand' is not null or undefined
            if (setPasswordCommand === null || setPasswordCommand === undefined) {
                throw new RequiredError('setPasswordCommand','Required parameter setPasswordCommand was null or undefined when calling userSetPassword.');
            }
            const localVarPath = `/api/v1/User/SetPassword`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof setPasswordCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(setPasswordCommand !== undefined ? setPasswordCommand : {}) : (setPasswordCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePasswordCommand} updatePasswordCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePassword: async (updatePasswordCommand: UpdatePasswordCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePasswordCommand' is not null or undefined
            if (updatePasswordCommand === null || updatePasswordCommand === undefined) {
                throw new RequiredError('updatePasswordCommand','Required parameter updatePasswordCommand was null or undefined when calling userUpdatePassword.');
            }
            const localVarPath = `/api/v1/User/Password`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updatePasswordCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updatePasswordCommand !== undefined ? updatePasswordCommand : {}) : (updatePasswordCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePreferencesCommand} updatePreferencesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePreferences: async (updatePreferencesCommand: UpdatePreferencesCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePreferencesCommand' is not null or undefined
            if (updatePreferencesCommand === null || updatePreferencesCommand === undefined) {
                throw new RequiredError('updatePreferencesCommand','Required parameter updatePreferencesCommand was null or undefined when calling userUpdatePreferences.');
            }
            const localVarPath = `/api/v1/User/Preferences`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updatePreferencesCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updatePreferencesCommand !== undefined ? updatePreferencesCommand : {}) : (updatePreferencesCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserCommand} updateUserCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateUserDetails: async (updateUserCommand: UpdateUserCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserCommand' is not null or undefined
            if (updateUserCommand === null || updateUserCommand === undefined) {
                throw new RequiredError('updateUserCommand','Required parameter updateUserCommand was null or undefined when calling userUpdateUserDetails.');
            }
            const localVarPath = `/api/v1/User`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateUserCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateUserCommand !== undefined ? updateUserCommand : {}) : (updateUserCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDeactivateUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userDeactivateUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsDto>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userGetAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [queryString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetBySearch(queryString?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsDto>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userGetBySearch(queryString, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InitiateResetPasswordCommand} initiateResetPasswordCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInitiateResetPassword(initiateResetPasswordCommand: InitiateResetPasswordCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userInitiateResetPassword(initiateResetPasswordCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userIsTokenValid(token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenValidityDto>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userIsTokenValid(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ModifyPinCommand} modifyPinCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userModifyPin(modifyPinCommand: ModifyPinCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userModifyPin(modifyPinCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RegistrationCommand} registrationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRegister(registrationCommand: RegistrationCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsDto>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userRegister(registrationCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ResendSalesforceFormCommand} resendSalesforceFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userResendSalesforceForm(resendSalesforceFormCommand: ResendSalesforceFormCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userResendSalesforceForm(resendSalesforceFormCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SetPasswordCommand} setPasswordCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSetPassword(setPasswordCommand: SetPasswordCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userSetPassword(setPasswordCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdatePasswordCommand} updatePasswordCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdatePassword(updatePasswordCommand: UpdatePasswordCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userUpdatePassword(updatePasswordCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdatePreferencesCommand} updatePreferencesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdatePreferences(updatePreferencesCommand: UpdatePreferencesCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userUpdatePreferences(updatePreferencesCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateUserCommand} updateUserCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdateUserDetails(updateUserCommand: UpdateUserCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userUpdateUserDetails(updateUserCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeactivateUser(options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).userDeactivateUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(id: string, options?: any): AxiosPromise<UserDetailsDto> {
            return UserApiFp(configuration).userGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetAll(options?: any): AxiosPromise<Array<UserDto>> {
            return UserApiFp(configuration).userGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [queryString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetBySearch(queryString?: string, options?: any): AxiosPromise<UserDetailsDto> {
            return UserApiFp(configuration).userGetBySearch(queryString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InitiateResetPasswordCommand} initiateResetPasswordCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInitiateResetPassword(initiateResetPasswordCommand: InitiateResetPasswordCommand, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).userInitiateResetPassword(initiateResetPasswordCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsTokenValid(token?: string, options?: any): AxiosPromise<TokenValidityDto> {
            return UserApiFp(configuration).userIsTokenValid(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyPinCommand} modifyPinCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userModifyPin(modifyPinCommand: ModifyPinCommand, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).userModifyPin(modifyPinCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegistrationCommand} registrationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegister(registrationCommand: RegistrationCommand, options?: any): AxiosPromise<UserDetailsDto> {
            return UserApiFp(configuration).userRegister(registrationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResendSalesforceFormCommand} resendSalesforceFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResendSalesforceForm(resendSalesforceFormCommand: ResendSalesforceFormCommand, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).userResendSalesforceForm(resendSalesforceFormCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetPasswordCommand} setPasswordCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSetPassword(setPasswordCommand: SetPasswordCommand, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).userSetPassword(setPasswordCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePasswordCommand} updatePasswordCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePassword(updatePasswordCommand: UpdatePasswordCommand, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).userUpdatePassword(updatePasswordCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePreferencesCommand} updatePreferencesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePreferences(updatePreferencesCommand: UpdatePreferencesCommand, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).userUpdatePreferences(updatePreferencesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserCommand} updateUserCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateUserDetails(updateUserCommand: UpdateUserCommand, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).userUpdateUserDetails(updateUserCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDeactivateUser(options?: any) {
        return UserApiFp(this.configuration).userDeactivateUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGet(id: string, options?: any) {
        return UserApiFp(this.configuration).userGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetAll(options?: any) {
        return UserApiFp(this.configuration).userGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [queryString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetBySearch(queryString?: string, options?: any) {
        return UserApiFp(this.configuration).userGetBySearch(queryString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InitiateResetPasswordCommand} initiateResetPasswordCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userInitiateResetPassword(initiateResetPasswordCommand: InitiateResetPasswordCommand, options?: any) {
        return UserApiFp(this.configuration).userInitiateResetPassword(initiateResetPasswordCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userIsTokenValid(token?: string, options?: any) {
        return UserApiFp(this.configuration).userIsTokenValid(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModifyPinCommand} modifyPinCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userModifyPin(modifyPinCommand: ModifyPinCommand, options?: any) {
        return UserApiFp(this.configuration).userModifyPin(modifyPinCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegistrationCommand} registrationCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userRegister(registrationCommand: RegistrationCommand, options?: any) {
        return UserApiFp(this.configuration).userRegister(registrationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResendSalesforceFormCommand} resendSalesforceFormCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userResendSalesforceForm(resendSalesforceFormCommand: ResendSalesforceFormCommand, options?: any) {
        return UserApiFp(this.configuration).userResendSalesforceForm(resendSalesforceFormCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetPasswordCommand} setPasswordCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userSetPassword(setPasswordCommand: SetPasswordCommand, options?: any) {
        return UserApiFp(this.configuration).userSetPassword(setPasswordCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePasswordCommand} updatePasswordCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdatePassword(updatePasswordCommand: UpdatePasswordCommand, options?: any) {
        return UserApiFp(this.configuration).userUpdatePassword(updatePasswordCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePreferencesCommand} updatePreferencesCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdatePreferences(updatePreferencesCommand: UpdatePreferencesCommand, options?: any) {
        return UserApiFp(this.configuration).userUpdatePreferences(updatePreferencesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserCommand} updateUserCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdateUserDetails(updateUserCommand: UpdateUserCommand, options?: any) {
        return UserApiFp(this.configuration).userUpdateUserDetails(updateUserCommand, options).then((request) => request(this.axios, this.basePath));
    }

}
