
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { api } from 'api/api'
import { AppThunk } from 'app/store'
import { FeatureState } from 'models/FeatureState'
import { UpdateUserDetails } from 'models/UpdateUserDetails'

interface State {
  featureState: FeatureState
  searchState: FeatureState
  error?: any,
  searchError?: any,
  queryString?: string
}

const initialState: State = {
  featureState: FeatureState.Init,
  searchState: FeatureState.Init
}

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setFeatureState(state, action: PayloadAction<FeatureState>) {
      state.featureState = action.payload
    },
    setSearchError(state, action: PayloadAction<string|null>) {
      state.searchError = action.payload
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    searchSuccess(
      state,
      action: PayloadAction<{
        updateUserDetails: UpdateUserDetails
        queryString: string
      }>
    ) {
      state.queryString = action.payload.queryString
      state.searchState = FeatureState.Success
    },
    clear(state) {
      state.featureState = FeatureState.Init
      state.searchState = FeatureState.Init
    }
  }
})

export const {
  setFeatureState,
  setError,
  setSearchError,
  searchSuccess,
  clear
} = clientsSlice.actions

const search = (queryString: string): AppThunk => async (dispatch) => {
  dispatch(setFeatureState(FeatureState.Loading))

  try {
    const result = await api.user.userGetBySearch(queryString)
    const merchantId = result?.data.user?.merchant?.id
    
    dispatch(setFeatureState(FeatureState.Success))
    dispatch(setSearchError(null))
    dispatch(push(`/merchant/${merchantId}/details`))
  } catch (error) {
    dispatch(setSearchError(error))
  }
}

export {
  search,
}

export const clientsReducer = clientsSlice.reducer
