import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  sidebarOpen?: boolean
  onPrivateRoute?: boolean
}

const initialState: State = {}

const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setSidebarOpen(state, action: PayloadAction<boolean>) {
      state.sidebarOpen = action.payload
    },
    setOnPrivateRoute(state, action: PayloadAction<boolean>) {
      state.onPrivateRoute = action.payload
    },
  },
})

const { setSidebarOpen, setOnPrivateRoute } = slice.actions

export const menuActions = {
  setSidebarOpen,
  setOnPrivateRoute,
}

export const menuReducer = slice.reducer
