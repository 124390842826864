import React, { FC, useCallback, useMemo } from 'react'
import { ActionButton } from 'components/buttons/ActionButton'
import { EnumDropdown } from 'components/form/EnumDropdown'
import { ListDropdown } from 'components/form/ListDropdown'
import { Trans, useTranslation } from 'react-i18next'
import { countries } from 'services/countryService'
import { paymentMethods } from 'services/enumService'
import { ControlButtons } from './ControlButtons'
import useCompanyTerminalForm from '../../hooks/useCompanyTerminalForm'
import { BooleanCheckBox } from 'components/form/BooleanCheckBox'
import classNames from 'classnames'
import AddressSchemeDisplay from 'components/AddressSchemeDisplay'
import { FeatureState } from 'models/FeatureState'

export const CompanyTerminalForm: FC = () => {
  const { t } = useTranslation()
 
  const {
    registration,
    terminalPrice,
    calculateState,
    onSubmit,
    register,
    watch,
    handleSubmit,
    handleCountChange,
    handleCopyPersonalAddress,
    handleCopyCompanyAddress,
    handleCopyDeliveryAddress
  } = useCompanyTerminalForm()

  const countWatch = watch('terminalOrder.count', registration?.terminalOrder?.count)
  const extraQuantityNeededClasses = useMemo(() => {
    return classNames('block my-5', {
      hidden: Number(countWatch) !== 20
    })
  }, [countWatch])

  const renderTerminalPrice = useCallback(() => {
    if (!terminalPrice.terminalCount) {
      return
    }

    if (calculateState === FeatureState.Loading) {
      return <p className="text-secondary">{t('common.loading')}</p>
    }

    return <p className="text-secondary">{t('signup.companyTerminalForm.terminalPrice', { price: terminalPrice.totalPrice })}</p>
  }, [t, calculateState, terminalPrice])

  return (
    <>
      <div className="mb-8">{t('Kártya terminál rendelése:')}</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          name="terminalOrder.count"
          type="number"
          min={1}
          max={20}
          required
          ref={register()}
          onChange={handleCountChange}
          placeholder={t('companyTerminalForm.terminalCountPlaceholder')}
        />

        <div className="hint">
          <Trans i18nKey="companyTerminalForm.terminalOrderHint" values={{ price: terminalPrice.terminalNetPrice }}>
            Maximum 20 db rendelhető a regisztráció során. <br/> (a terminál díja XXX Ft + ÁFA / darab)
          </Trans>
        </div>

        <div className={extraQuantityNeededClasses}>
          <BooleanCheckBox
            name="terminalOrder.extraQuantityNeeded"
            refToRegister={register()}
            trueText={t('companyTerminalForm.extraQuantityNeeded')}
          />
        </div>

        <div className="my-4">
          <BooleanCheckBox
            name="terminalOrder.multipleOperationAddresses"
            refToRegister={register()}
            trueText={t('companyTerminalForm.multipleOperationAddresses')}
          />
        </div>

        {renderTerminalPrice()}

        <EnumDropdown
          name="terminalOrder.paymentMethod"
          list={paymentMethods}
          enumName="PaymentMethod"
          register={register}
        />


        <label className="block mt-8 font-semibold">
          {t('Szállítási cím')}
        </label>
        
        <ActionButton onClick={() => handleCopyPersonalAddress('deliveryAddress')}>
          {t('Ugyanaz, mint a lakcímem')}
        </ActionButton>

        <ActionButton onClick={() => handleCopyCompanyAddress('deliveryAddress')}>
          {t('Ugyanaz, mint a vállalkozás címe')}
        </ActionButton>

        <ListDropdown
          name="terminalOrder.deliveryAddress.countryCode"
          list={countries}
          placeholder={t('Ország')}
          register={register}
        />

        <AddressSchemeDisplay
          context="terminalOrder.deliveryAddress"
          register={register}
        />
        
        <label className="block mt-8 font-semibold">
          {t('companyTerminalForm.billingAddress')}
        </label>
        
        <ActionButton onClick={() => handleCopyPersonalAddress('billingAddress')}>
          {t('Ugyanaz, mint a lakcímem')}
        </ActionButton>

        <ActionButton onClick={() => handleCopyCompanyAddress('billingAddress')}>
          {t('Ugyanaz, mint a vállalkozás címe')}
        </ActionButton>

        <ActionButton onClick={() => handleCopyDeliveryAddress('billingAddress')}>
          {t('Ugyanaz, mint a szállítási cím')}
        </ActionButton>

        <ListDropdown
          name="terminalOrder.billingAddress.countryCode"
          list={countries}
          placeholder={t('Ország')}
          register={register}
        />

        <AddressSchemeDisplay
          context="terminalOrder.billingAddress"
          register={register}
        />

        <ControlButtons />
      </form>
    </>
  )
}
