import { DropdownData } from 'models/DropdownData'
import React, { ChangeEventHandler, FC } from 'react'
import { useTranslation } from 'react-i18next'

interface ListDropdownProps {
  name: string
  list?: DropdownData[]
  placeholder: string
  register: any
  onChange?: ChangeEventHandler<HTMLSelectElement>
  className?: string
  id?: string
  disabled?: boolean
}

export const ListDropdown: FC<ListDropdownProps> = ({
  name,
  list,
  placeholder,
  register,
  onChange,
  className,
  id,
  disabled,
}) => {
  const { t } = useTranslation()

  return (
    <select
      id={id}
      name={name}
      required
      ref={register()}
      defaultValue=""
      onChange={onChange}
      className={className}
      disabled={disabled}
    >
      <option value="" disabled hidden>
        {placeholder}
      </option>
      {list?.map((x) => (
        <option value={x.value} key={x.value}>
          {t(x?.i18nKey || x.text)}
        </option>
      ))}
    </select>
  )
}
