import React, { FC, HTMLAttributes } from 'react'

interface ActionButtonProps extends HTMLAttributes<HTMLButtonElement> {}

export const ActionButton: FC<ActionButtonProps> = ({
  children,
  onClick,
  ...rest
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClick && onClick(e)
  }

  return (
    <button
      type="button"
      className={
        'my-2 py-1 px-4 focus:outline-none border-2 border-secondary rounded-full text-secondary active:bg-secondary active:text-white'
      }
      onClick={(e) => handleClick(e)}
      {...rest}
    >
      {children}
    </button>
  )
}
