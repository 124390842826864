import { RootState } from 'app/store'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { signupActions } from '../../signupSlice'
import { PaymentMethod } from 'api/generated/models'

interface ControlButtonsProps {
  disabled?: boolean
}

export const ControlButtons: FC<ControlButtonsProps> = ({ disabled }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { step, registration } = useSelector((state: RootState) => state.signup)
  const btnLabel = useMemo(() => {
    if (step === 10) {
      const { paymentMethod } = registration?.terminalOrder || {}
      return paymentMethod === PaymentMethod.BankCard
        ? t('signup.controlButtons.finishAndPay')
        : t('signup.controlButtons.finish')
    }

    return t('common.proceed')
  }, [t, registration, step])

  return (
    <div className="mt-8 flex items-center">
      <button
        onClick={() => dispatch(signupActions.prevStep())}
        className={`flex items-center justify-center w-10 h-10 mr-2 p-2 rounded-full bg-white border-secondary border-2 ${
          step === 0 ? 'hidden' : ''
        }`}
      >
        <img src="/img/prev.svg" alt="Prev" className="inline-block" />
      </button>

      <button className="flex items-center h-10 p-2 rounded-full border-secondary border-2 bg-secondary disabled:bg-gray-lightest disabled:border-gray-lightest" disabled={disabled}>
        <span className="text-white ml-2 mr-4">{btnLabel}</span>
        <img
          src="/img/next.svg"
          alt="Next"
          className="inline-block"
        />
      </button>
    </div>
  )
}
