import { Role } from 'api/generated/models'
import { RootState } from 'app/store'
import { settingsActions } from 'features/merchant/components/settings/settingsSlice'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const ProfilePicture: FC = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.auth)
  const { profilePicture } = useSelector((state: RootState) => state.settings)

  useEffect(() => {
    if (!profilePicture && user?.role === Role.Basic) {
      profilePicture === undefined && dispatch(settingsActions.pictureGet())
    }
  }, [dispatch, user, profilePicture])

  return (
    <div className="sm:mr-3">
      {profilePicture ? (
        <div className="w-10 h-10">
          <img
            className="rounded-full object-cover w-10 h-10"
            src={URL.createObjectURL(profilePicture)}
            alt="Profile"
          />
        </div>
      ) : (
        <svg
          className="rounded-full w-10 h-10"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="23" stroke="#00E76E" strokeWidth="2" />
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="5"
            y="5"
            width="38"
            height="38"
          >
            <circle cx="24" cy="24" r="19" fill="white" />
          </mask>
          <g mask="url(#mask0)">
            <circle
              cx="24"
              cy="53.3334"
              r="23"
              stroke="#00E76E"
              strokeWidth="2"
            />
            <circle
              cx="24"
              cy="18.6667"
              r="7"
              stroke="#00E76E"
              strokeWidth="2"
            />
          </g>
        </svg>
      )}
    </div>
  )
}
