import React, { FC, useEffect, useMemo } from 'react'
import classNames from 'classnames'

export enum ModalState {
  Hidden = 'Hidden',
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete'
}

interface ModalProps {
  visible?: ModalState,
  children: any
}

export const Modal: FC<ModalProps> = ({
  visible,
  children
}) => {
  const modalClasses = useMemo(() => {
    return classNames('fixed inset-0', {
      hidden: visible === ModalState.Hidden
    })
  }, [visible])

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', visible !== ModalState.Hidden)
  }, [visible])

  return (
    <div className={modalClasses}>
      <div className="absolute bg-gray-dark opacity-75 z-10 inset-0" />
      <div className="absolute w-full h-full md:w-1/3 md:h-auto max-h-full overflow-y-auto sm:p-5 md:p-10 p-4 bg-white z-10 top-1/2 left-1/2 transform-gpu -translate-x-1/2 -translate-y-1/2">
        {children}
      </div>
    </div>
  )
}

export default Modal