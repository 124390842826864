import { menuActions } from 'features/menu/menuSlice'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { LinkProps, NavLink } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'

export const NavigationLink: FC<LinkProps> = ({ children, to, ...rest }) => {
  const dispatch = useDispatch()

  const linkProps: LinkProps = {
    onClick: () => dispatch(menuActions.setSidebarOpen(false)),
    to,
    ...rest,
  }

  return (
    <>
      {to.toString().includes('#') ? (
        <NavHashLink {...linkProps}>{children}</NavHashLink>
      ) : (
        <NavLink {...linkProps}>{children}</NavLink>
      )}
    </>
  )
}
