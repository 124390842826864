import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MerchantType, PaymentMethod } from 'api/generated/models'
import { AppThunk, RootState } from 'app/store'
import { FeatureState } from 'models/FeatureState'
import { Registration } from 'models/Registration'
import { FC } from 'react'
import { CompanyAddressForm } from './components/forms/CompanyAddressForm'
import { CompanyBasicsForm } from './components/forms/CompanyBasicsForm'
import { CompanyNameForm } from './components/forms/CompanyNameForm'
import { CompanyPersonsForm } from './components/forms/CompanyPersonsForm'
import { CompanyTerminalForm } from './components/forms/CompanyTerminalForm'
import { BankAccountForm } from './components/forms/BankAccountForm'
import { MerchantSurveyForm } from './components/forms/MerchantSurveyForm'
import { PersonalAddressForm } from './components/forms/PersonalAddressForm'
import { PersonalBasicsForm } from './components/forms/PersonalBasicsForm'
import { PersonalEmailForm } from './components/forms/PersonalEmailForm'
import { PersonalPhoneForm } from './components/forms/PersonalPhoneForm'
import { api } from 'api/api'
import { Bank } from 'models/Bank'
import { CalculateResultDto } from 'api/generated/models'
import dayjs from 'dayjs'

const formsInOrder = [
  PersonalBasicsForm,
  PersonalEmailForm,
  PersonalPhoneForm,
  PersonalAddressForm,
  CompanyBasicsForm,
  CompanyNameForm,
  CompanyAddressForm,
  CompanyPersonsForm,
  CompanyTerminalForm,
  BankAccountForm,
  MerchantSurveyForm,
]

export enum RegistrationState {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Finished = 'Finished',
}

interface State {
  featureState: FeatureState
  calculateState: FeatureState
  error?: any
  registration?: Registration
  registrationState?: RegistrationState
  referenceCode?: string | null
  paymentMethod?: PaymentMethod
  terminalPrice: CalculateResultDto
  step: number
  banks?: Bank[]
}

const initialState: State = {
  featureState: FeatureState.Init,
  calculateState: FeatureState.Init,
  registrationState: RegistrationState.NotStarted,
  referenceCode: null,
  terminalPrice: {},
  step: 0,
}

const slice = createSlice({
  name: 'signup',
  initialState: initialState,
  reducers: {
    setFeatureState(state: State, action: PayloadAction<FeatureState>) {
      state.featureState = action.payload
    },
    setCalculateState(state: State, action: PayloadAction<FeatureState>) {
      state.calculateState = action.payload
    },
    setRegistrationState(state: State, action: PayloadAction<RegistrationState>) {
      state.registrationState = action.payload
    },
    nextStep(state: State, action: PayloadAction<Registration>) {
      let nextStep = state.step + 1

      if (nextStep >= formsInOrder.length) return

      // In case of MerchantType.Person skip CompanyPersonsForm.
      if (
        state.step === 6 &&
        state.registration?.merchant?.type === MerchantType.Person
      ) {
        nextStep++
      }

      state.step = nextStep
      state.registration = action.payload
    },
    prevStep(state: State) {
      let prevStep = state.step - 1

      if (prevStep < 0) return

      // In case of MerchantType.Person skip CompanyPersonsForm.
      if (
        state.step === 8 &&
        state.registration?.merchant?.type === MerchantType.Person
      ) {
        prevStep--
      }

      state.step = prevStep
    },
    calculateTerminalPriceSuccess(state: State, action: PayloadAction<CalculateResultDto>) {
      state.terminalPrice = action.payload
      state.calculateState = FeatureState.Success
    },
    finishRegistrationSuccess(state: State, action: PayloadAction<{paymentMethod?: PaymentMethod, referenceCode?: string | null}>) {
      state.step = 0
      state.registration = undefined
      state.featureState = FeatureState.Success
      state.registrationState = RegistrationState.Finished
      state.referenceCode = action.payload.referenceCode
      state.paymentMethod = action.payload.paymentMethod
    },
    finishRegistrationError(
      state: State,
      action: PayloadAction<{ registration: Registration; error: any }>
    ) {
      state.registration = action.payload.registration
      state.error = action.payload.error
      state.featureState = FeatureState.Error
    },
    getBanksSuccess(state: State, action: PayloadAction<Bank[]>) {
      state.banks = action.payload
      state.featureState = FeatureState.Success
    },
    clear(state) {
      state.step = 0
      state.registration = undefined
      state.featureState = FeatureState.Init
      state.registrationState = RegistrationState.NotStarted
      state.referenceCode = undefined
    }
  },
})

const {
  setFeatureState,
  setCalculateState,
  setRegistrationState,
  nextStep,
  prevStep,
  calculateTerminalPriceSuccess,
  finishRegistrationSuccess,
  finishRegistrationError,
  getBanksSuccess,
  clear
} = slice.actions

const getBanks = (): AppThunk => async (dispatch: Function) => {
  dispatch(setFeatureState(FeatureState.Loading))

  try {
    const result = await api.bank.bankGetAll()

    dispatch(getBanksSuccess(result.data))
  } catch (error) {
    dispatch(setFeatureState(FeatureState.Error))
  }
}

const calculateTerminalPrice = (terminalCount: number): AppThunk => async (dispatch: Function) => {
  dispatch(setCalculateState(FeatureState.Loading))

  try {
    const result = await api.terminalOrder.terminalOrderCalculateTotalPrice(terminalCount)
    dispatch(calculateTerminalPriceSuccess(result.data))
  } catch (error) {
    dispatch(setCalculateState(FeatureState.Error))
  }
}

const finishRegistration = (registration: Registration): AppThunk => async (
  dispatch: Function
) => {
  dispatch(setFeatureState(FeatureState.Loading))

  try {
    const payload = {
      ...registration,
      person: registration.person && {
        ...registration.person,
        dateOfBirth: registration?.person?.dateOfBirth && dayjs(registration?.person?.dateOfBirth).format('YYYY-MM-DD')
      },
      persons: registration.persons?.map(person => ({
        ...person,
        dateOfBirth: person?.dateOfBirth && dayjs(person?.dateOfBirth).format('YYYY-MM-DD')
      }))
    }

    const result = await api.user.userRegister(payload)
    const { paymentMethod, referenceCode } = result.data?.user?.merchant?.terminalOrders?.[0] || {}

    dispatch(finishRegistrationSuccess({ paymentMethod, referenceCode }))
  } catch (error) {
    dispatch(finishRegistrationError({ registration, error }))
  }
}

export const signupActions = {
  setRegistrationState,
  nextStep,
  prevStep,
  calculateTerminalPrice,
  finishRegistration,
  getBanks,
  clear
}

export const signupReducer = slice.reducer

export const currentFormSelector = createSelector<RootState, State, FC<{}>>(
  (state: any) => state.signup,
  ({ step }: { step: number }) =>
    step > -1 && step < formsInOrder.length
      ? formsInOrder[step]
      : formsInOrder[0]
)
