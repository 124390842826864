import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import throttle from 'lodash.throttle'
import { RootState } from 'app/store'
import { AddressDto, PaymentMethod } from 'api/generated/models'
import { signupActions } from '../signupSlice'

const useCompanyTerminal = () => {
  const dispatch = useDispatch()
  const [terminalCount, setTerminalCount] = useState(0)
  const { registration, terminalPrice, calculateState } = useSelector((state: RootState) => state.signup)
  const throttled = useRef(throttle(count => {
    dispatch(signupActions.calculateTerminalPrice(count))
  }, 1000))

  const { register, handleSubmit, getValues, setValue, watch } = useForm({
    defaultValues: {
      ...registration,
      terminalOrder: {
        paymentMethod: PaymentMethod.BankCard,
        ...registration?.terminalOrder,
      },
    },
  })

  const onSubmit = (data: any) => {
    dispatch(
      signupActions.nextStep({
        ...registration,
        ...data,
        terminalOrder: {
          ...registration?.terminalOrder,
          ...data.terminalOrder,
        },
      })
    )
  }

  const handleCountChange = () => {
    const [min, max]: number[] = [1, 20]
    const current: number = getValues('terminalOrder.count')
    const value = Math.max(Math.min(current, max), min)

    setValue('terminalOrder.count', value)
    setTerminalCount(value)

    if (getValues('terminalOrder.extraQuantityNeeded') && value !== max) {
      setValue('terminalOrder.extraQuantityNeeded', false)
    }
  }

  const copyFormAddressFields = (to: string, from?: AddressDto | null) => {
    const { countryCode, zipCode, city, street } = from || {}

    setValue(`${to}.countryCode`, countryCode)
    setValue(`${to}.zipCode`, zipCode)
    setValue(`${to}.city`, city)
    setValue(`${to}.street`, street)
  }

  const handleCopyPersonalAddress = (target: string) => {
    copyFormAddressFields(`terminalOrder.${target}`, registration?.person?.address)
  }

  const handleCopyCompanyAddress = (target: string) => {
    copyFormAddressFields(`terminalOrder.${target}`, registration?.merchant?.registrationAddress)
  }

  const handleCopyDeliveryAddress = (target: string) => {
    const { terminalOrder: { deliveryAddress } } = getValues([
      'terminalOrder.deliveryAddress.countryCode',
      'terminalOrder.deliveryAddress.zipCode',
      'terminalOrder.deliveryAddress.city',
      'terminalOrder.deliveryAddress.street'
    ] as any) || {}

    copyFormAddressFields(`terminalOrder.${target}`, deliveryAddress)
  }

  useEffect(() => throttled.current(terminalCount), [terminalCount])

  return {
    registration,
    terminalPrice,
    calculateState,
    onSubmit,
    register,
    watch,
    setValue,
    handleSubmit,
    handleCountChange,
    handleCopyPersonalAddress,
    handleCopyCompanyAddress,
    handleCopyDeliveryAddress
  }
}

export default useCompanyTerminal
