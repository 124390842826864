import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'app/store'
import { userActions } from 'features/merchant/userSlice'
import { Role } from 'api/generated/models'

export const UserNameDisplay: FC = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.auth)
  const { userDetails } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    if (!userDetails && user?.role === Role.Basic) {
      dispatch(userActions.getUserDetails())
    }
  }, [dispatch, user, userDetails])

  return (
    <span className="whitespace-nowrap">
      {user?.role === Role.Basic
        ? userDetails &&
          userDetails.user &&
          `${userDetails?.user?.lastName} ${userDetails?.user?.firstName}`
        : 'Administrator'}
    </span>
  )
}
