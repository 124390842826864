import { RootState } from 'app/store'
import { ActionButton } from 'components/buttons/ActionButton'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { signupActions } from '../../signupSlice'
import { ControlButtons } from './ControlButtons'

export const PersonalPhoneForm: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { registration } = useSelector((state: RootState) => state.signup)
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: registration,
  })

  const onSubmit = (data: any) => {
    dispatch(
      signupActions.nextStep({
        ...registration,
        ...data,
        person: { ...registration?.person, ...data.person },
        merchant: { ...registration?.merchant, ...data.merchant },
      })
    )
  }

  return (
    <>
      <div className="mb-8">
        {t('signup.personalPhoneForm.hint')}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          name="person.mobile"
          type="text"
          pattern="^(?:[+]{1})?\d+$"
          required
          maxLength={40}
          placeholder={t('Telefonszámod')}
          ref={register()}
        />

        <div className="hint">
          {t('common.hint.phone')}
        </div>

        <label
          htmlFor="merchant.settlementReportsEmail"
          className="block mt-8 font-semibold"
        >
          {t('signup.personalPhoneForm.email')}
        </label>

        <ActionButton
          onClick={() =>
            setValue('merchant.settlementReportsEmail', registration?.email)
          }
        >
          {t('signup.personalPhoneForm.sameAsContactEmail')}
        </ActionButton>

        <input
          name="merchant.settlementReportsEmail"
          type="email"
          required
          maxLength={255}
          placeholder={t('Másik E-mail cím')}
          ref={register()}
        />

        <ControlButtons />
      </form>
    </>
  )
}
