import download from 'downloadjs'
import { AxiosResponse } from 'axios'

export const downloadFile = async (response: AxiosResponse) => {
  const headers = response.headers
  const disposition = headers['content-disposition']
  const filename = getFileName(disposition) || 'agorapay_unnamed_file'
  const blob = await response.data
  download(blob, filename)
}

const getFileName = (disposition: string) => {
  if (disposition) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    var matches = filenameRegex.exec(disposition)
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '')
    }
  }
  return null
}
