// tslint:disable
/**
 * AgoraPay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddTerminalCommand } from '../models';
// @ts-ignore
import { MobileLoginTerminalDto } from '../models';
// @ts-ignore
import { PagedResponseOfTerminalDto } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { TerminalDto } from '../models';
// @ts-ignore
import { TerminalListItemDto } from '../models';
// @ts-ignore
import { UpdateTerminalCommand } from '../models';
/**
 * TerminalApi - axios parameter creator
 * @export
 */
export const TerminalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddTerminalCommand} addTerminalCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalAddTerminal: async (addTerminalCommand: AddTerminalCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addTerminalCommand' is not null or undefined
            if (addTerminalCommand === null || addTerminalCommand === undefined) {
                throw new RequiredError('addTerminalCommand','Required parameter addTerminalCommand was null or undefined when calling terminalAddTerminal.');
            }
            const localVarPath = `/api/v1/Terminal`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof addTerminalCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(addTerminalCommand !== undefined ? addTerminalCommand : {}) : (addTerminalCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalGetAllTerminals: async (merchantId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            if (merchantId === null || merchantId === undefined) {
                throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling terminalGetAllTerminals.');
            }
            const localVarPath = `/api/v1/Merchant/{merchantId}/Terminal`
                .replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalGetTerminal: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling terminalGetTerminal.');
            }
            const localVarPath = `/api/v1/Terminal/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalGetTerminals: async (merchantId?: number, pageNumber?: number, pageSize?: number, searchFor?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Terminal`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (merchantId !== undefined) {
                localVarQueryParameter['MerchantId'] = merchantId;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (searchFor !== undefined) {
                localVarQueryParameter['SearchFor'] = searchFor;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalGetTerminalsMobile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Mobile/v1/Terminal`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTerminalCommand} updateTerminalCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalUpdateTerminal: async (id: number, updateTerminalCommand: UpdateTerminalCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling terminalUpdateTerminal.');
            }
            // verify required parameter 'updateTerminalCommand' is not null or undefined
            if (updateTerminalCommand === null || updateTerminalCommand === undefined) {
                throw new RequiredError('updateTerminalCommand','Required parameter updateTerminalCommand was null or undefined when calling terminalUpdateTerminal.');
            }
            const localVarPath = `/api/v1/Terminal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateTerminalCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateTerminalCommand !== undefined ? updateTerminalCommand : {}) : (updateTerminalCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TerminalApi - functional programming interface
 * @export
 */
export const TerminalApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddTerminalCommand} addTerminalCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalAddTerminal(addTerminalCommand: AddTerminalCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalDto>> {
            const localVarAxiosArgs = await TerminalApiAxiosParamCreator(configuration).terminalAddTerminal(addTerminalCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalGetAllTerminals(merchantId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TerminalListItemDto>>> {
            const localVarAxiosArgs = await TerminalApiAxiosParamCreator(configuration).terminalGetAllTerminals(merchantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalGetTerminal(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalDto>> {
            const localVarAxiosArgs = await TerminalApiAxiosParamCreator(configuration).terminalGetTerminal(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalGetTerminals(merchantId?: number, pageNumber?: number, pageSize?: number, searchFor?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseOfTerminalDto>> {
            const localVarAxiosArgs = await TerminalApiAxiosParamCreator(configuration).terminalGetTerminals(merchantId, pageNumber, pageSize, searchFor, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalGetTerminalsMobile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MobileLoginTerminalDto>>> {
            const localVarAxiosArgs = await TerminalApiAxiosParamCreator(configuration).terminalGetTerminalsMobile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTerminalCommand} updateTerminalCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminalUpdateTerminal(id: number, updateTerminalCommand: UpdateTerminalCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TerminalApiAxiosParamCreator(configuration).terminalUpdateTerminal(id, updateTerminalCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TerminalApi - factory interface
 * @export
 */
export const TerminalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {AddTerminalCommand} addTerminalCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalAddTerminal(addTerminalCommand: AddTerminalCommand, options?: any): AxiosPromise<TerminalDto> {
            return TerminalApiFp(configuration).terminalAddTerminal(addTerminalCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalGetAllTerminals(merchantId: number, options?: any): AxiosPromise<Array<TerminalListItemDto>> {
            return TerminalApiFp(configuration).terminalGetAllTerminals(merchantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalGetTerminal(id: number, options?: any): AxiosPromise<TerminalDto> {
            return TerminalApiFp(configuration).terminalGetTerminal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalGetTerminals(merchantId?: number, pageNumber?: number, pageSize?: number, searchFor?: string, options?: any): AxiosPromise<PagedResponseOfTerminalDto> {
            return TerminalApiFp(configuration).terminalGetTerminals(merchantId, pageNumber, pageSize, searchFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalGetTerminalsMobile(options?: any): AxiosPromise<Array<MobileLoginTerminalDto>> {
            return TerminalApiFp(configuration).terminalGetTerminalsMobile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTerminalCommand} updateTerminalCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminalUpdateTerminal(id: number, updateTerminalCommand: UpdateTerminalCommand, options?: any): AxiosPromise<void> {
            return TerminalApiFp(configuration).terminalUpdateTerminal(id, updateTerminalCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TerminalApi - object-oriented interface
 * @export
 * @class TerminalApi
 * @extends {BaseAPI}
 */
export class TerminalApi extends BaseAPI {
    /**
     * 
     * @param {AddTerminalCommand} addTerminalCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public terminalAddTerminal(addTerminalCommand: AddTerminalCommand, options?: any) {
        return TerminalApiFp(this.configuration).terminalAddTerminal(addTerminalCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} merchantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public terminalGetAllTerminals(merchantId: number, options?: any) {
        return TerminalApiFp(this.configuration).terminalGetAllTerminals(merchantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public terminalGetTerminal(id: number, options?: any) {
        return TerminalApiFp(this.configuration).terminalGetTerminal(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [searchFor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public terminalGetTerminals(merchantId?: number, pageNumber?: number, pageSize?: number, searchFor?: string, options?: any) {
        return TerminalApiFp(this.configuration).terminalGetTerminals(merchantId, pageNumber, pageSize, searchFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public terminalGetTerminalsMobile(options?: any) {
        return TerminalApiFp(this.configuration).terminalGetTerminalsMobile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateTerminalCommand} updateTerminalCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public terminalUpdateTerminal(id: number, updateTerminalCommand: UpdateTerminalCommand, options?: any) {
        return TerminalApiFp(this.configuration).terminalUpdateTerminal(id, updateTerminalCommand, options).then((request) => request(this.axios, this.basePath));
    }

}
