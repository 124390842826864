import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { SocialIcons } from "components/layout/SocialIcons";
import { NavigationLink } from "components/NavigationLink";
import { ReactComponent as ArrowRight } from "assets/img/arrow_right.svg";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { Redirect } from "react-router-dom";

const SignupSuccessAndPay: FC = () => {
  const { t } = useTranslation();
  const { referenceCode } = useSelector((state: RootState) => state.signup);

  if (!referenceCode) {
    return <Redirect to="/"></Redirect>;
  }

  return (
    <>
      <div
        className="w-full sm:hidden"
        style={{ height: "4px", backgroundColor: "rgba(0, 231, 110, 0.2)" }}
      >
        <div className="h-full bg-secondary w-0"></div>
      </div>

      <div className="flex w-full flex-col-reverse sm:flex-row mx-auto">
        <div className="w-full px-8 sm:w-1/2 sm:mx-8 sm:my-16 sm:pb-32">
          <div className="lg:w-4/6">
            <h3 className="text-secondary text-2xl font-medium">
              {t("signup.successAndPay.title")}
            </h3>

            <p className="mt-8">
              {t(
                "A regisztrációról megerősítő email-t küldünk. Ha a megerősítő email pár percen belül nem érkezik meg hozzád, kérlek jelezd nekünk:"
              )}
              <br />
              <a
                className="text-secondary no-underline"
                href="mailto:info@agorapay.hu"
              >
                info@agorapay.hu
              </a>
            </p>

            <p className="mt-8 font-semibold">{t("Mi következik ezután?")}</p>

            <p>
              {t(
                "Hamarosan megkapod aláírásra a végső szerződéseket Paystra nevű partnerünktől, amiket online alá tudsz írni."
              )}
            </p>

            <p>
              {t("Ha bármi kérdésed van, keress minket bátran:")}
              <NavigationLink
                to="/contact"
                className="block text-secondary no-underline"
              >
                agorapay.hu/contact
              </NavigationLink>
            </p>

            <div className="my-8">
              <SocialIcons color="black" />
            </div>
          </div>
        </div>

        <div className="w-full my-16 px-8 sm:w-1/2 sm:mx-8 sm:pb-32">
          <h1 className="text-secondary text-3xl sm:text-4xl font-medium mb-4">
            {t("signup.successAndPay.payWithCreditCard")}
          </h1>

          <p className="mb-4">{t("signup.successAndPay.payDescription")}</p>

          <NavigationLink
            to={`/checkout/${referenceCode}`}
            className="btn-secondary"
          >
            {t("signup.successAndPay.forwardToPay")}{" "}
            <ArrowRight className="inline-block" />
          </NavigationLink>
        </div>
      </div>
    </>
  );
};

export default SignupSuccessAndPay;
