export const gtagSendEvent = (send_to: string) => {
  if (!(window as any)?.gtag) {
    return
  }

  (window as any).gtag('event', 'conversion', {
    'allow_custom_scripts': true,
    'send_to': send_to
  })
}

const sentEvents: string[] = []

export const gtagSendEventOnce = (send_to: string) => {
  if (!sentEvents.includes(send_to)) {
    sentEvents.push(send_to)
    gtagSendEvent(send_to)
  }
}