import { NavigationLink } from 'components/NavigationLink'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SocialIcons } from './SocialIcons'

export const Footer: FC = () => {
  const { t } = useTranslation()

  return (
    <footer className="bg-navy text-white text-sm">
      <nav className="flex flex-wrap px-6 py-10 sm:px-12 justify-between items-center">
        <div>
          <NavigationLink to="/#top">
            <img
              src="/img/a-pay-horizontal-long-green.svg"
              alt="Agora Pay logo"
              className="h-10 my-6"
            />
          </NavigationLink>
        </div>

        <div className="flex-grow">
          <div className="flex flex-wrap justify-around max-w-xl mx-auto pl-16">
            <NavigationLink to="/pricing" className="w-full sm:w-auto">
              {t('footer.priceLink', 'Áraink')}
            </NavigationLink>

            <NavigationLink to="/faq" className="w-full sm:w-auto">
              {t('footer.faqLink', 'GYIK')}
            </NavigationLink>

            <NavigationLink to="/contact" className="w-full sm:w-auto">
              {t('footer.contactLink', 'Kapcsolat')}
            </NavigationLink>

            <a
              className="w-full sm:w-auto"
              href="/pdf/aszf.pdf"
              target="_blank"
            >
              {t('footer.termsLink', 'ÁSZF')}
            </a>

            <NavigationLink to="/privacyPolicy" className="w-full sm:w-auto">
              {t('footer.privacyLink', 'Adatvédelmi tájékoztató')}
            </NavigationLink>
          </div>
        </div>

        <div className="w-full sm:w-auto mt-16 sm:mt-0">
          <SocialIcons color="white" />
        </div>

        <div className="w-full text-gray mt-8 sm:text-center">
          {t('footer.copyright', '© 2021, Agora Pay')}
          <p>developed by <a href="https://grape.solutions" target="_blank" rel="noreferrer" className="px-0">Grape Solutions</a></p>
        </div>
      </nav>
    </footer>
  )
}
