import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from 'api/api'
import { AppThunk } from 'app/store'
import { FeatureState } from 'models/FeatureState'

interface State {
  featureState: FeatureState
  tokenValidityState: FeatureState
  error?: any
  confirmationTokenValid?: boolean
}

const initialState: State = {
  featureState: FeatureState.Init,
  tokenValidityState: FeatureState.Loading,
}

const slice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    setFeatureState(state, action: PayloadAction<FeatureState>) {
      state.featureState = action.payload
    },
    setTokenValidityState(state, action: PayloadAction<FeatureState>) {
      state.tokenValidityState = action.payload
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload
      state.featureState = FeatureState.Error
    },
    checkTokenValiditySuccess(
      state,
      action: PayloadAction<boolean | undefined>
    ) {
      state.confirmationTokenValid = action.payload
      state.tokenValidityState = FeatureState.Success
    },
    checkTokenValidityError(state) {
      state.tokenValidityState = FeatureState.Error
    },
  },
})

const {
  setFeatureState,
  setTokenValidityState,
  setError,
  checkTokenValiditySuccess,
  checkTokenValidityError,
} = slice.actions

const initiateResetPassword = (email: string): AppThunk => async (dispatch) => {
  dispatch(setFeatureState(FeatureState.Loading))

  try {
    await api.user.userInitiateResetPassword({ email })

    dispatch(setFeatureState(FeatureState.Success))
  } catch (error) {
    dispatch(setError(error))
  }
}

const setPassword = (password: string, token: string): AppThunk => async (
  dispatch
) => {
  dispatch(setFeatureState(FeatureState.Loading))

  try {
    await api.user.userSetPassword({ password, token })

    dispatch(setFeatureState(FeatureState.Success))
  } catch (error) {
    dispatch(setError(error))
  }
}

const checkTokenValidity = (token: string): AppThunk => async (dispatch) => {
  dispatch(setTokenValidityState(FeatureState.Loading))

  try {
    const result = await api.user.userIsTokenValid(token)

    dispatch(checkTokenValiditySuccess(result.data.isValid))
  } catch (error) {
    dispatch(checkTokenValidityError())
  }
}

export const forgotPasswordActions = {
  initiateResetPassword,
  setPassword,
  checkTokenValidity,
}

export const forgotPasswordReducer = slice.reducer
