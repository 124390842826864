import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import store from '../store'
import translationEn from './locales/en.json'
import translationHu from './locales/hu.json'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import hu from 'date-fns/locale/hu'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isoWeek from 'dayjs/plugin/isoWeek'
import 'dayjs/locale/hu'
import formatter from 'utils/formatter'

export enum Language {
  En = 'en',
  Hu = 'hu',
}

const resources = {
  [Language.En]: {
    translation: translationEn,
  },
  [Language.Hu]: {
    translation: translationHu,
  },
}

const lng = (store && store.getState().locale.locale) || localStorage.getItem('language') || 'hu'

// Datepicker config
registerLocale('hu', hu)
setDefaultLocale(lng)

// Dayjs config
dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(isoWeek)
dayjs.locale(lng)

i18n.use(initReactI18next).init({
  resources,
  lng,
  fallbackLng: Language.Hu,
  interpolation: {
    format: (value, format, lng?: string) => { 
      if (format === 'currency') {
        return formatter.currency(value)
      }

      return value
    },
    escapeValue: false,
  },
  keySeparator: false,
  nsSeparator: false,
  react: {
    transKeepBasicHtmlNodesFor: ['h3', 'div', 'strong', 'br', 'p']
  }
})

export default i18n
