// tslint:disable
/**
 * AgoraPay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OnlineTransactionDetailsDto } from '../models';
// @ts-ignore
import { PagedResponseOfOnlineTransactionDto } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * OnlineTransactionApi - axios parameter creator
 * @export
 */
export const OnlineTransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} merchantId 
         * @param {number} [onlineEntityId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineTransactionExport: async (merchantId: number, onlineEntityId?: number, fromDate?: string, toDate?: string, searchFor?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            if (merchantId === null || merchantId === undefined) {
                throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling onlineTransactionExport.');
            }
            const localVarPath = `/api/v1/Merchant/{merchantId}/OnlineTransaction/Export`
                .replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (onlineEntityId !== undefined) {
                localVarQueryParameter['onlineEntityId'] = onlineEntityId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }

            if (searchFor !== undefined) {
                localVarQueryParameter['searchFor'] = searchFor;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineTransactionGet: async (merchantId: number, id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            if (merchantId === null || merchantId === undefined) {
                throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling onlineTransactionGet.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling onlineTransactionGet.');
            }
            const localVarPath = `/api/v1/Merchant/{merchantId}/OnlineTransaction/{id}`
                .replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [onlineEntityId] 
         * @param {string} [searchFor] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineTransactionGetAll: async (merchantId: number, fromDate?: string, toDate?: string, onlineEntityId?: number, searchFor?: string, pageSize?: number, pageNumber?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            if (merchantId === null || merchantId === undefined) {
                throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling onlineTransactionGetAll.');
            }
            const localVarPath = `/api/v1/Merchant/{merchantId}/OnlineTransaction`
                .replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }

            if (onlineEntityId !== undefined) {
                localVarQueryParameter['onlineEntityId'] = onlineEntityId;
            }

            if (searchFor !== undefined) {
                localVarQueryParameter['searchFor'] = searchFor;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnlineTransactionApi - functional programming interface
 * @export
 */
export const OnlineTransactionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} merchantId 
         * @param {number} [onlineEntityId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onlineTransactionExport(merchantId: number, onlineEntityId?: number, fromDate?: string, toDate?: string, searchFor?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await OnlineTransactionApiAxiosParamCreator(configuration).onlineTransactionExport(merchantId, onlineEntityId, fromDate, toDate, searchFor, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onlineTransactionGet(merchantId: number, id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnlineTransactionDetailsDto>> {
            const localVarAxiosArgs = await OnlineTransactionApiAxiosParamCreator(configuration).onlineTransactionGet(merchantId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [onlineEntityId] 
         * @param {string} [searchFor] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onlineTransactionGetAll(merchantId: number, fromDate?: string, toDate?: string, onlineEntityId?: number, searchFor?: string, pageSize?: number, pageNumber?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseOfOnlineTransactionDto>> {
            const localVarAxiosArgs = await OnlineTransactionApiAxiosParamCreator(configuration).onlineTransactionGetAll(merchantId, fromDate, toDate, onlineEntityId, searchFor, pageSize, pageNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OnlineTransactionApi - factory interface
 * @export
 */
export const OnlineTransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} merchantId 
         * @param {number} [onlineEntityId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineTransactionExport(merchantId: number, onlineEntityId?: number, fromDate?: string, toDate?: string, searchFor?: string, options?: any): AxiosPromise<any> {
            return OnlineTransactionApiFp(configuration).onlineTransactionExport(merchantId, onlineEntityId, fromDate, toDate, searchFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineTransactionGet(merchantId: number, id: number, options?: any): AxiosPromise<OnlineTransactionDetailsDto> {
            return OnlineTransactionApiFp(configuration).onlineTransactionGet(merchantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [onlineEntityId] 
         * @param {string} [searchFor] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineTransactionGetAll(merchantId: number, fromDate?: string, toDate?: string, onlineEntityId?: number, searchFor?: string, pageSize?: number, pageNumber?: number, options?: any): AxiosPromise<PagedResponseOfOnlineTransactionDto> {
            return OnlineTransactionApiFp(configuration).onlineTransactionGetAll(merchantId, fromDate, toDate, onlineEntityId, searchFor, pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OnlineTransactionApi - object-oriented interface
 * @export
 * @class OnlineTransactionApi
 * @extends {BaseAPI}
 */
export class OnlineTransactionApi extends BaseAPI {
    /**
     * 
     * @param {number} merchantId 
     * @param {number} [onlineEntityId] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [searchFor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineTransactionApi
     */
    public onlineTransactionExport(merchantId: number, onlineEntityId?: number, fromDate?: string, toDate?: string, searchFor?: string, options?: any) {
        return OnlineTransactionApiFp(this.configuration).onlineTransactionExport(merchantId, onlineEntityId, fromDate, toDate, searchFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} merchantId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineTransactionApi
     */
    public onlineTransactionGet(merchantId: number, id: number, options?: any) {
        return OnlineTransactionApiFp(this.configuration).onlineTransactionGet(merchantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} merchantId 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {number} [onlineEntityId] 
     * @param {string} [searchFor] 
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineTransactionApi
     */
    public onlineTransactionGetAll(merchantId: number, fromDate?: string, toDate?: string, onlineEntityId?: number, searchFor?: string, pageSize?: number, pageNumber?: number, options?: any) {
        return OnlineTransactionApiFp(this.configuration).onlineTransactionGetAll(merchantId, fromDate, toDate, onlineEntityId, searchFor, pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

}
