import { MerchantType } from "api/generated/models";
import { RootState } from "app/store";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { signupActions } from "../../signupSlice";
import { ControlButtons } from "./ControlButtons";
import { HiddenForMerchantTypePerson } from "./HiddenForMerchantTypePerson";

export const CompanyNameForm: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { registration } = useSelector((state: RootState) => state.signup);
  const { register, handleSubmit } = useForm({
    defaultValues: registration,
  });

  const onSubmit = (data: any) => {
    dispatch(
      signupActions.nextStep({
        ...registration,
        ...data,
        merchant: { ...registration?.merchant, ...data.merchant },
        company:
          registration?.merchant?.type === MerchantType.Company
            ? { ...registration?.company, ...data.company }
            : null,
      })
    );
  };

  return (
    <>
      <div className="mb-8">
        {t("Szükségünk lenne a vállalkozást érintő hivatalos adatokra is:")}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <HiddenForMerchantTypePerson>
          <input
            name="company.name"
            type="text"
            required
            maxLength={255}
            placeholder={t("A gazdasági társaság neve")}
            ref={register()}
          />
        </HiddenForMerchantTypePerson>

        <input
          name="merchant.doingBusinessAs"
          type="text"
          maxLength={22}
          placeholder={t("Van külön elnevezése?")}
          ref={register()}
        />
        <div className="hint">{t("signup.companyName.doingBusinessHint")}</div>

        <input
          name="merchant.taxNumber"
          type="text"
          required
          maxLength={50}
          pattern="^[0-9]{8}-[1-5]{1}-[0-9]{2}$"
          placeholder={t("Adószám")}
          ref={register()}
        />

        <div className="hint">{t("common.hint.taxNumber")}</div>

        <HiddenForMerchantTypePerson>
          <>
            <input
              name="company.registrationId"
              type="text"
              required
              maxLength={12}
              pattern="^[0-9]{2}-[0-9]{2}-[0-9]{6}$"
              placeholder={t("Cégjegyzékszám")}
              ref={register()}
            />

            <div className="hint">{t("common.hint.registrationId")}</div>
          </>
        </HiddenForMerchantTypePerson>

        <input
          name="merchant.authorizedSignatory"
          type="text"
          required
          placeholder={t("Aláírásra jogosult személy neve")}
          ref={register()}
        />

        <input
          name="merchant.website"
          type="text"
          maxLength={255}
          placeholder={t("signup.merchant.website.placeholder")}
          ref={register()}
        />
        <div className="hint">{t("signup.companyName.websiteHint")}</div>

        <ControlButtons />
      </form>
    </>
  );
};
