import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api/api";
import { OnlineEntityDto, OnlineTransactionDto } from "api/generated/models";
import { AppThunk } from "app/store";
import dayjs from "dayjs";
import { FeatureState } from "models/FeatureState";
import { Pagination } from "models/Pagination";
import { downloadFile } from "services/downloadService";

interface State {
  featureState: FeatureState;
  pagination: Pagination;
  exportState: FeatureState;
  onlineTransactions?: OnlineTransactionDto[] | null;
  onlineEntities?: OnlineEntityDto[] | null;
}

const initialState: State = {
  featureState: FeatureState.Init,
  exportState: FeatureState.Init,
  onlineEntities: [],
  pagination: {
    pageNumber: 1,
    pageSize: 10,
  },
};

const slice = createSlice({
  name: "onlineTransactions",
  initialState,
  reducers: {
    setFeatureState(state, action: PayloadAction<FeatureState>) {
      state.featureState = action.payload;
    },
    setPagination(state, action: PayloadAction<Pagination>) {
      state.pagination = action.payload;
    },
    setExportState(state, action: PayloadAction<FeatureState>) {
      state.exportState = action.payload;
    },
    setOnlineEntities(
      state,
      action: PayloadAction<{
        onlineEntities?: OnlineEntityDto[] | null;
      }>
    ) {
      state.onlineEntities = action.payload.onlineEntities;
    },
    setOnlineTransactionsData(
      state,
      action: PayloadAction<{
        onlineTransactions?: OnlineTransactionDto[] | null;
        pagination: Pagination;
      }>
    ) {
      state.pagination = action.payload.pagination;
      state.onlineTransactions = action.payload.onlineTransactions;
      state.featureState = FeatureState.Success;
    },
  },
});

const {
  setFeatureState,
  setPagination,
  setExportState,
  setOnlineEntities,
  setOnlineTransactionsData,
} = slice.actions;

const getTransactions =
  (
    merchantId: number,
    searchFor?: string,
    onlineEntityId?: number,
    from?: Date,
    to?: Date,
    pageNumber?: number,
    pageSize?: number
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setFeatureState(FeatureState.Loading));
    const { pagination } = getState().onlineTransactions;
    const size = pageSize || pagination.pageSize;
    const number = pageNumber || pagination.pageNumber;

    try {
      const fromDate = dayjs(from).format("YYYY.MM.DD.");
      const toDate = dayjs(to).format("YYYY.MM.DD.");
      const result = await api.onlineTransaction.onlineTransactionGetAll(
        merchantId,
        fromDate,
        toDate,
        onlineEntityId,
        searchFor,
        size,
        number
      );
      const { data, ...pagination } = result.data;

      dispatch(
        setOnlineTransactionsData({
          onlineTransactions: data,
          pagination,
        })
      );
    } catch (error) {
      dispatch(setFeatureState(FeatureState.Error));
    }
  };

const getOnlineEntities =
  (merchantId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setFeatureState(FeatureState.Loading));

    try {
      const result = await api.onlineEntity.onlineEntityGetAllOnlineEntitiess(merchantId);
      const data = result.data;

      dispatch(
        setOnlineEntities({
          onlineEntities: data
        })
      );
    } catch (error) {
      dispatch(setFeatureState(FeatureState.Error));
    }
  };

const exportTransactions =
  (
    merchantId: number,
    searchFor: string = "",
    from: Date,
    to: Date,
    onlineEntityId?: number
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setExportState(FeatureState.Loading));

    try {
      const fromDate = dayjs(from).format("YYYY-MM-DD");
      const toDate = dayjs(to).format("YYYY-MM-DD");
      const response = await api.onlineTransaction.onlineTransactionExport(
        merchantId,
        onlineEntityId,
        fromDate,
        toDate,
        searchFor,
        { responseType: "blob" }
      );

      downloadFile(response);
      dispatch(setExportState(FeatureState.Success));
    } catch (error) {
      dispatch(setExportState(FeatureState.Error));
    }
  };

export const onlineTransactionsActions = {
  setPagination,
  getOnlineEntities,
  getTransactions,
  exportTransactions,
};

export const onlineTransactionsReducer = slice.reducer;
