import axios from 'api/axios'
import { userService } from 'services/userService'
import {
  AuthenticationApi,
  BankApi,
  Configuration,
  MerchantApi,
  PaymentApi,
  PictureApi,
  StatisticsApi,
  TerminalApi,
  TerminalOrderApi,
  ReportApi,
  TransactionApi,
  UserApi,
  OnlineEntityApi,
  OnlineTransactionApi,
} from './generated'

export const getUrl = (): string => {
  const location = window.location
  return location.protocol + '//' + location.host
}

const basePath = process.env.REACT_APP_API_URL || getUrl()
export const config = new Configuration({
  basePath,
  apiKey: () => `Bearer ${userService.getUser()?.accessToken}`,
  baseOptions: {
    withCredentials: false,
  }
})

export const api = {
  authentication: new AuthenticationApi(config, basePath, axios),
  user: new UserApi(config, basePath, axios),
  bank: new BankApi(config, basePath, axios),
  merchant: new MerchantApi(config, basePath, axios),
  payment: new PaymentApi(config, basePath, axios),
  picture: new PictureApi(config, basePath, axios),
  statistics: new StatisticsApi(config, basePath, axios),
  terminal: new TerminalApi(config, basePath, axios),
  terminalOrder: new TerminalOrderApi(config, basePath, axios),
  transaction: new TransactionApi(config, basePath, axios),
  onlineTransaction: new OnlineTransactionApi(config, basePath, axios),
  report: new ReportApi(config, basePath, axios),
  onlineEntity: new OnlineEntityApi(config, basePath, axios),
}

