import { RootState } from 'app/store'
import BankAccountInput from 'components/BankAccountInput'
import { BooleanCheckBox } from 'components/form/BooleanCheckBox'
import { ListDropdown } from 'components/form/ListDropdown'
import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { signupActions } from '../../signupSlice'
import { ControlButtons } from './ControlButtons'

export const BankAccountForm: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { registration, banks } = useSelector(
    (state: RootState) => state.signup
  )
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: registration,
  })
  const watchBankId = watch(
    'bankAccount.bankId',
    registration?.bankAccount?.bankId
  )

  const onSubmit = (data: any) => {
    dispatch(
      signupActions.nextStep({
        ...registration,
        ...data,
        bankAccount: { ...registration?.bankAccount, ...data.bankAccount },
      })
    )
  }

  useEffect(() => {
    dispatch(signupActions.getBanks())
  }, [dispatch])

  return (
    <>
      <div className="mb-8">
        {t('Még néhány pénzügyekkel kapcsolatos kérdés és végzünk is! A bankoddal kapcsolatban az alábbi információkra lenne szükségünk:')}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ListDropdown
          name="bankAccount.bankId"
          list={banks?.map((x) => ({
            value: x.id?.toString()!,
            text: x.name!,
          }))}
          placeholder={t('Bank neve')}
          register={register}
        />

        <BankAccountInput
          name="bankAccount.localNumber"
          placeholder={t('signup.bankAccount.localNumber.placeholder')}
          required
          ref={register()}
        />

        <div className="hint">
          {t('common.hint.localNumber')}
        </div>

        { (errors.bankAccount as any)?.localNumber && (
          <p className="ml-4 pt-0 text-sm text-red">{t('validate.errors.accountGiroMatch')}</p>
        ) }

        <ListDropdown
          name="bankAccount.currencyCode"
          list={[{ value: 'HUF', text: 'HUF' }]}
          placeholder={t('Bankszámla pénzneme')}
          register={register}
        />

        <input
          type="text"
          disabled
          placeholder={t('SWIFT kód')}
          value={
            watchBankId
              ? banks?.find((x) => x.id === +watchBankId)?.bic || ''
              : ''
          }
        />

        <input
          name="bankAccount.iban"
          type="text"
          required
          maxLength={35}
          placeholder={t('IBAN szám')}
          ref={register()}
        />

        <div className="hint">
          {t('common.hint.iban')}
        </div>

        <div className="mt-8">
          <BooleanCheckBox
            name="bankAccount.isConfirmed"
            required
            refToRegister={register()}
            trueText={
              <>
                {t('Kérlek erősítsd meg, hogy Te vagy a bankszámla tulajdonosa / a megadott bankszámla a vállalkozásodhoz tartozik.')}
              </>
            }
          />
        </div>

        <ControlButtons />
      </form>
    </>
  )
}
