import dayjs from 'dayjs'
import store from 'app/store'

const formatter = {
  date: (value: any = new Date(), pattern = 'L') => dayjs(value).format(pattern),
  unit: (value: any, unit: string) => `${value}${unit}`,
  currency: (value: number = 0) => {
    const locale = store.getState().locale.locale || 'hu'
    const currency = locale === 'hu' ? 'Ft' : 'HUF'
    const formatted = new Intl.NumberFormat('hu').format(value)

    return `${formatted} ${currency}`
  },
}

export default formatter
