import React, { FC } from "react";
import { useIsMobile } from "hooks/useIsMobile";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface QuoteCardProps {
  img: string;
}

const QuoteCard: FC<QuoteCardProps> = ({ img, children }) => {
  const isMobile = useIsMobile();

  return (
    <div className="w-60 flex flex-col mx-auto rounded-3xl shadow-2xl sm:flex-row sm:max-w-xl sm:w-full sm:ml-10">
      <LazyLoadImage
        src={img}
        className="object-cover rounded-tl-3xl rounded-tr-3xl sm:rounded-tr-none sm:rounded-bl-3xl"
        alt=""
        style={{ height: isMobile ? "190px" : "270px" }}
      />
      <div className="p-4 text-sm">{children}</div>
    </div>
  );
};

export default QuoteCard;
