import { RootState } from 'app/store'
import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { signupActions } from '../../signupSlice'
import { ControlButtons } from './ControlButtons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { countries } from 'services/countryService'
import { ListDropdown } from 'components/form/ListDropdown'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

export const PersonalBasicsForm: FC = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const { registration } = useSelector((state: RootState) => state.signup)
  const { register, handleSubmit, control, errors } = useForm({
    defaultValues: registration,
  })

  const onSubmit = (data: any) => {
    dispatch(
      signupActions.nextStep({
        ...registration,
        ...data,
        person: { ...registration?.person, ...data.person },
      })
    )
  }

  return (
    <>
      <div className="mb-8">
        {t('Kezdésként kérjük, add meg néhány személyes adatodat:')}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          name="person.lastName"
          type="text"
          required
          maxLength={100}
          placeholder={t('Vezetékneved')}
          ref={register()}
        />

        <input
          name="person.firstName"
          type="text"
          required
          maxLength={100}
          placeholder={t('Keresztneved')}
          ref={register()}
        />

        <label
          htmlFor="person.dateOfBirth"
          className="block mt-8 font-semibold"
        >
          {t('Születési dátumod')}
        </label>

        <Controller
          control={control}
          name="person.dateOfBirth"
          defaultValue=""
          rules={{
            validate: (date) => dayjs().subtract(18, 'year').isAfter(date),
          }}
          render={(props) => (
            <DatePicker
              {...props}
              autoComplete="new-password"
              locale={i18n.language}
              dateFormat="P"
              timeFormat="P"
              id="person.dateOfBirth"
              required
              placeholderText={t('Dátum')}
              selected={props.value || dayjs().subtract(18, 'year').toDate()}
              showYearDropdown
            />
          )}
        />

        { (errors.person as any)?.dateOfBirth && (
          <p className="ml-4 pt-0 text-sm text-red">{t('validate.errors.18yearsatleast')}</p>
        )}
          
        <ListDropdown
          name="person.nationalityCode"
          list={countries}
          placeholder={t('Állampolgárságod')}
          register={register}
        />

        <ControlButtons />
      </form>
    </>
  )
}
