// tslint:disable
/**
 * AgoraPay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddOnlineEntityCommand } from '../models';
// @ts-ignore
import { OnlineEntityDto } from '../models';
// @ts-ignore
import { OnlineEntityListItemDto } from '../models';
// @ts-ignore
import { PagedResponseOfOnlineEntityDto } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { UpdateOnlineEntityCommand } from '../models';
/**
 * OnlineEntityApi - axios parameter creator
 * @export
 */
export const OnlineEntityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddOnlineEntityCommand} addOnlineEntityCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineEntityAddOnlineEntity: async (addOnlineEntityCommand: AddOnlineEntityCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addOnlineEntityCommand' is not null or undefined
            if (addOnlineEntityCommand === null || addOnlineEntityCommand === undefined) {
                throw new RequiredError('addOnlineEntityCommand','Required parameter addOnlineEntityCommand was null or undefined when calling onlineEntityAddOnlineEntity.');
            }
            const localVarPath = `/api/v1/OnlineEntity`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof addOnlineEntityCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(addOnlineEntityCommand !== undefined ? addOnlineEntityCommand : {}) : (addOnlineEntityCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineEntityGetAllOnlineEntitiess: async (merchantId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            if (merchantId === null || merchantId === undefined) {
                throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling onlineEntityGetAllOnlineEntitiess.');
            }
            const localVarPath = `/api/v1/Merchant/{merchantId}/OnlineEntity`
                .replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineEntityGetOnlineEntities: async (merchantId?: number, pageNumber?: number, pageSize?: number, searchFor?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/OnlineEntity`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (merchantId !== undefined) {
                localVarQueryParameter['MerchantId'] = merchantId;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (searchFor !== undefined) {
                localVarQueryParameter['SearchFor'] = searchFor;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineEntityGetOnlineEntity: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling onlineEntityGetOnlineEntity.');
            }
            const localVarPath = `/api/v1/OnlineEntity/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateOnlineEntityCommand} updateOnlineEntityCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineEntityUpdateOnlineEntity: async (id: number, updateOnlineEntityCommand: UpdateOnlineEntityCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling onlineEntityUpdateOnlineEntity.');
            }
            // verify required parameter 'updateOnlineEntityCommand' is not null or undefined
            if (updateOnlineEntityCommand === null || updateOnlineEntityCommand === undefined) {
                throw new RequiredError('updateOnlineEntityCommand','Required parameter updateOnlineEntityCommand was null or undefined when calling onlineEntityUpdateOnlineEntity.');
            }
            const localVarPath = `/api/v1/OnlineEntity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateOnlineEntityCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateOnlineEntityCommand !== undefined ? updateOnlineEntityCommand : {}) : (updateOnlineEntityCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnlineEntityApi - functional programming interface
 * @export
 */
export const OnlineEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddOnlineEntityCommand} addOnlineEntityCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onlineEntityAddOnlineEntity(addOnlineEntityCommand: AddOnlineEntityCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnlineEntityDto>> {
            const localVarAxiosArgs = await OnlineEntityApiAxiosParamCreator(configuration).onlineEntityAddOnlineEntity(addOnlineEntityCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onlineEntityGetAllOnlineEntitiess(merchantId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OnlineEntityListItemDto>>> {
            const localVarAxiosArgs = await OnlineEntityApiAxiosParamCreator(configuration).onlineEntityGetAllOnlineEntitiess(merchantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onlineEntityGetOnlineEntities(merchantId?: number, pageNumber?: number, pageSize?: number, searchFor?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseOfOnlineEntityDto>> {
            const localVarAxiosArgs = await OnlineEntityApiAxiosParamCreator(configuration).onlineEntityGetOnlineEntities(merchantId, pageNumber, pageSize, searchFor, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onlineEntityGetOnlineEntity(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnlineEntityDto>> {
            const localVarAxiosArgs = await OnlineEntityApiAxiosParamCreator(configuration).onlineEntityGetOnlineEntity(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateOnlineEntityCommand} updateOnlineEntityCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onlineEntityUpdateOnlineEntity(id: number, updateOnlineEntityCommand: UpdateOnlineEntityCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OnlineEntityApiAxiosParamCreator(configuration).onlineEntityUpdateOnlineEntity(id, updateOnlineEntityCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OnlineEntityApi - factory interface
 * @export
 */
export const OnlineEntityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {AddOnlineEntityCommand} addOnlineEntityCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineEntityAddOnlineEntity(addOnlineEntityCommand: AddOnlineEntityCommand, options?: any): AxiosPromise<OnlineEntityDto> {
            return OnlineEntityApiFp(configuration).onlineEntityAddOnlineEntity(addOnlineEntityCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} merchantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineEntityGetAllOnlineEntitiess(merchantId: number, options?: any): AxiosPromise<Array<OnlineEntityListItemDto>> {
            return OnlineEntityApiFp(configuration).onlineEntityGetAllOnlineEntitiess(merchantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineEntityGetOnlineEntities(merchantId?: number, pageNumber?: number, pageSize?: number, searchFor?: string, options?: any): AxiosPromise<PagedResponseOfOnlineEntityDto> {
            return OnlineEntityApiFp(configuration).onlineEntityGetOnlineEntities(merchantId, pageNumber, pageSize, searchFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineEntityGetOnlineEntity(id: number, options?: any): AxiosPromise<OnlineEntityDto> {
            return OnlineEntityApiFp(configuration).onlineEntityGetOnlineEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateOnlineEntityCommand} updateOnlineEntityCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineEntityUpdateOnlineEntity(id: number, updateOnlineEntityCommand: UpdateOnlineEntityCommand, options?: any): AxiosPromise<void> {
            return OnlineEntityApiFp(configuration).onlineEntityUpdateOnlineEntity(id, updateOnlineEntityCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OnlineEntityApi - object-oriented interface
 * @export
 * @class OnlineEntityApi
 * @extends {BaseAPI}
 */
export class OnlineEntityApi extends BaseAPI {
    /**
     * 
     * @param {AddOnlineEntityCommand} addOnlineEntityCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineEntityApi
     */
    public onlineEntityAddOnlineEntity(addOnlineEntityCommand: AddOnlineEntityCommand, options?: any) {
        return OnlineEntityApiFp(this.configuration).onlineEntityAddOnlineEntity(addOnlineEntityCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} merchantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineEntityApi
     */
    public onlineEntityGetAllOnlineEntitiess(merchantId: number, options?: any) {
        return OnlineEntityApiFp(this.configuration).onlineEntityGetAllOnlineEntitiess(merchantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [searchFor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineEntityApi
     */
    public onlineEntityGetOnlineEntities(merchantId?: number, pageNumber?: number, pageSize?: number, searchFor?: string, options?: any) {
        return OnlineEntityApiFp(this.configuration).onlineEntityGetOnlineEntities(merchantId, pageNumber, pageSize, searchFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineEntityApi
     */
    public onlineEntityGetOnlineEntity(id: number, options?: any) {
        return OnlineEntityApiFp(this.configuration).onlineEntityGetOnlineEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateOnlineEntityCommand} updateOnlineEntityCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineEntityApi
     */
    public onlineEntityUpdateOnlineEntity(id: number, updateOnlineEntityCommand: UpdateOnlineEntityCommand, options?: any) {
        return OnlineEntityApiFp(this.configuration).onlineEntityUpdateOnlineEntity(id, updateOnlineEntityCommand, options).then((request) => request(this.axios, this.basePath));
    }

}
